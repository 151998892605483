import moment from "moment";

function format(value) {
  const timestamp = moment(value);

  if(!timestamp.isValid()) {
    return value;
  }

  return timestamp.format('DD.MM.YYYY HH:mm');
}

export default {
  install: (Vue) => Vue.filter('timestamp', format),
}
