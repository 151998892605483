<template>
  <v-container fluid>
      <v-toolbar>
        <h2 class="headline ml-5"> Historie</h2>
      </v-toolbar>
      <div class="mt-4 d-flex justify-end">
        <v-btn-toggle v-model="epicFilterType" mandatory>
          <v-btn text value="Alle">
            Alle
          </v-btn>
          <v-btn text value="Projekte">
            Projekte
          </v-btn>
          <v-btn text value="Aufgaben">
            Aufgaben
          </v-btn>
          <v-btn text value="Daueraufgaben">
            Daueraufgaben
          </v-btn>
        </v-btn-toggle>
      </div>
      <v-data-table
        class="mt-4"
        :headers="headers"
        :items="data"
        :server-items-length="totalItems"
        :options.sync="pagination"
        :items-per-page="10"
        :loading="loading"
        item-key="id"
      >
        <template v-slot:header="{ props }">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              class="px-2"
            >
              <filter-box
                :placeholder="header.filterPlaceholder"
                :initialValue="getFilterInitialValue(header.value)"
                :type="header.filterType"
                :selectItems="header.selectItems"
                @filter="applyFilter(header.value, ...arguments)"
                v-if="header.filterable"
              />
            </th>
          </tr>
        </template>
        <template v-slot:item="{ item }">
          <tr @click="selectItem(item)">
            <td class="text-xs-left">{{ item.name }}</td>
            <td class="text-xs-left">{{ item.department }}</td>
            <td class="text-xs-left">{{ formatDate(item.createdAt) }}</td>
            <td class="text-xs-left">{{ formatDate(item.plannedStart) }}</td>
            <td class="text-xs-left">{{ convertStatus(item.status) }}</td>
          </tr>
        </template>
      </v-data-table>
      <v-dialog v-model="showTaskDetails" max-width="1200px" scrollable>
        <TaskDetails
          v-if="showTaskDetails"
          :url="selectedTask._links.details.href"
          @update="entryChanged"
          @cancel="selectedTask = null"
        />
      </v-dialog>
    </v-container>
</template>

<script>

import _ from 'lodash';
import moment from 'moment';
import aufgabenparkApi from '../aufgabenparkApi';
import FilterBox from './common/FilterBox';
import TaskDetails from "@/components/aufgaben/TaskDetails";

const StatusMap = {
  Completed: 'Abgeschlossen',
  WillNotBeDone: 'Wird nicht erledigt',
};
const StatusRevertMap = {
  Abgeschlossen: 'Completed',
  'Wird nicht erledigt': 'WillNotBeDone',
};

export default {
  name: 'Historie',
  components: {
    TaskDetails,
    FilterBox,
  },
  watch: {
    pagination: {
      handler() {
        this.reloadData();
      },
      deep: true,
    },
    epicFilterType: 'reloadData',
  },
  mounted() {
    this.reloadData();
    // Otherwise the selectItems array will be empty
    this.headers[1].selectItems = this.$store.state.departments.map(d => d.name);
  },
  computed: {
    showTaskDetails: {
      get() {
        return !!this.selectedTask;
      },
      set(value) {
        if (!value) {
          this.selectedTask = null;
        }
      },
    },
  },
  methods: {
    entryChanged() {
      this.selectedTask = null;
      this.reloadData();
    },
    getFilterInitialValue(header) {
      return this.filters[header];
    },
    applyFilter(header, filter) {
      if (header === 'status') {
        this.filters[header] = StatusRevertMap[filter];
      } else {
        this.filters[header] = filter;
      }
      this.reloadData();
    },
    changeSort(column) {
      if (this.pagination.sortBy[0] === column[0]) {
        this.pagination.sortDesc = [!this.pagination.sortDesc[0]];
      } else {
        this.pagination.sortBy = column;
        this.pagination.sortDesc = [false];
      }
    },
    convertStatus(status) {
      return StatusMap[status];
    },
    formatDate(date) {
      if (!date) {
        return '';
      }

      return moment(date).format('MMMM Do YYYY');
    },
    reloadData() {
      this.loading = true;
      const paginationQuery = {
        sortBy: this.pagination.sortBy[0] || 'name',
        descending: this.pagination.sortDesc[0] || false,
        page: this.pagination.page,
        rowsPerPage: this.pagination.itemsPerPage
      };
      aufgabenparkApi
        .loadHistorie(paginationQuery, {
          ...this.filters,
          epicType: this.epicFilterType,
        })
        .then((r) => {
          this.data = r.data.data;
          this.totalItems = r.data.count;
          this.loading = false;
        },
        response => window.console.error(response));
    },
    selectItem(item) {
      if (item.type === 'Projekt') {
        this.$router.push(item._links.details.href);
      } else {
        this.selectedTask = item;
      }
    },
  },
  data() {
    return {
      selectedTask: null,
      epicFilterType: 'Alle',
      loading: false,
      data: [],
      totalItems: 0,
      pagination: {
        sortBy: ['name'],
        sortDesc: [false],
        page: 1,
        itemsPerPage: 10,
        multiSort: false,
      },
      filters: {
      },
      headers: [
        {
          text: 'Name',
          align: 'left',
          value: 'name',
          filterable: true,
          sortable: true,
          filterPlaceholder: 'Name',
          filterType: 'text',
        },
        {
          text: 'Abteilung',
          align: 'left',
          value: 'department',
          sortable: true,
          filterable: true,
          filterPlaceholder: 'Abteilung',
          filterType: 'select',
          selectItems: this.$store.state.departments.map(d => d.name),
        },
        {
          text: 'Datum',
          align: 'left',
          value: 'createdAt',
          filterable: true,
          filterPlaceholder: 'Datum',
          filterType: 'date',
        },
        {
          text: 'Fälligkeitsdatum',
          align: 'left',
          value: 'plannedStart',
          filterable: true,
          filterPlaceholder: 'Fälligkeitsdatum',
          filterType: 'date',
        },
        {
          text: 'Status',
          align: 'left',
          value: 'status',
          sortable: true,
          filterable: true,
          filterPlaceholder: 'Status',
          filterType: 'select',
          selectItems: _.values(StatusMap),
        },
      ],
    };
  },
};
</script>
