<template>
  <v-container fluid v-if="epicFound">
    <v-toolbar color="blue darken-3" class="mb-4">
      <v-toolbar-title class="white--text">{{epic.name}}</v-toolbar-title>
      <v-spacer />
      <div class="white--text">
        {{epic.boardName}}
      </div>
    </v-toolbar>
    <v-btn
      v-if="hasLink('createTask')"
      @click="addEpicTask"
      color="primary"
      rounded
      large
    >
      <v-icon>description</v-icon>
      Neue Aufgabe
    </v-btn>
    <v-row class="mt-4">
      <v-col>
        <v-card>
          <v-card-title primary-title class="cardLeftTitle">
            <v-icon color="white" large>{{statusIcon}}</v-icon>
            <div class="headline">Projektdaten</div>
          </v-card-title>
          <v-card-text>
            <div class="mt-2">Erstellt: {{epic.createdAt | timestamp}}</div>
            <div class="mt-2">Erstellt von: {{epic.createdBy}}</div>
            <div class="font-weight-bold">
              <div class="mt-2">
                <span>Abteilung: {{epic.department}}</span>
                <span v-if="hasLink('assignDepartment')">
                  <v-tooltip bottom>
                    <template v-slot:activator="{on, attrs}">
                      <v-btn icon tile small v-on="on" v-bind="attrs" class="ml-2" @click="startDepartmentAssignment">
                        <v-icon>swap_horiz</v-icon>
                      </v-btn>
                    </template>
                    <span>Anderer Abteilung zuweisen</span>
                  </v-tooltip>
                </span>
                <v-dialog v-model="assignDepartmentDialog" persistent max-width="750">
                  <v-card>
                    <v-card-title>Abteilung zuweisen</v-card-title>
                    <v-card-text>
                      <v-alert type="warning">
                        <div>
                          <strong>VORSICHT:</strong>
                          Bei einer Zuweisung zu einer anderen Abteilung, können nur noch Benutzer mit der entsprechenden Berechtigung in der gewählten Abteilung Zeiten auf alle Aufgaben dieses Projekts verbuchen.
                        </div>
                        <div class="mt-2">
                          Bereits bestehende Zeitbuchungen bleiben weiterhin vorhanden.
                        </div>
                      </v-alert>
                      <v-select
                        :items="allDepartments"
                        v-model="newDepartmentAssignment"
                        placeholder="Abteilung"
                        hint="Abteilung"
                        required
                      />
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer/>
                      <v-btn @click="closeDepartmentAssignment" text>Abbrechen</v-btn>
                      <v-btn @click="confirmAssignDepartment" text :loading="saving">Zuweisen</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </div>
            <v-form v-model="valid" v-if="hasLink('edit')">
              <v-text-field
                v-model="epic.name"
                label="Titel"
                :rules="nameRules"
                required
              />

              <v-radio-group v-model="epicType" row>
                <v-radio label="Normales Projekt" value="normal"></v-radio>
                <v-radio label="Timepark Projekt" value="timepark"></v-radio>
              </v-radio-group>

              <project-select
                v-if="epic.isTimeparkEpic"
                v-model="epic.project"
              />

              <v-textarea
                v-model="epic.description"
                label="Beschreibung"
              />
            </v-form>
            <div v-else>
              <div class="pa-1">
                <v-icon color="blue darken-3">play_for_work</v-icon>
                <span v-if="epic.isTimeparkEpic" class="font-weight-bold">Timepark projekt</span>
                <span v-else>Normales projekt</span>

                <timepark-project-display v-if="epic.isTimeparkEpic" :project="epic.project" />
              </div>
              <div class="pa-2">
                <div class="font-weight-bold">Beschreibung:</div>
                <div>{{epic.description}}</div>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="hasLink('edit')"
              color="info"
              class="white--text"
              @click="saveEpic"
              :loading="saving"
              key="save-epic"
            >
              <v-icon left dark>save</v-icon>
              Speichern
            </v-btn>
            <confirm-button
              v-if="hasLink('delete')"
              color="error"
              class="white--text"
              @confirm="deleteEpic"
              :loading="saving"
              key="delete-epic"
              max-dialog-width="400"
              dialogTitle="Projekt löschen?"
            >
              <v-icon left dark>close</v-icon>
              Löschen
              <div slot="dialog-content" v-if="epic.tasks.length > 0">
                <div>Wenn Sie dieses Projekt löschen werden alle darunter liegenden Aufgaben ebenfalls gelöscht!</div>
                <div class="mt-2">Möchten Sie das Projekt dennoch löschen?</div>
              </div>
              <span slot="dialog-content" v-else>
                Möchten Sie dieses Projekt wirklich löschen?
              </span>
            </confirm-button>
            <confirm-button
              v-if="hasLink('approve')"
              color="warning"
              class="white--text"
              @confirm="confirmAuthorizeEpic"
              :loading="saving"
              key="approve-epic"
            >
              <v-icon left dark>thumb_up</v-icon>Freigeben
              <span slot="dialog-content">Wollen Sie dieses Projekt freigeben?</span>
            </confirm-button>
            <ablehnen-button
              v-if="hasLink('reject')"
              @confirm="confirmUnauthorizeEpic"
              :loading="saving"
              key="reject-epic"
            >
              <template #dialog-title>
                Project ablehnen
              </template>
              <template #dialog-subtitle>
                Bitte geben Sie ein Kommentar zur Ablehnung des Projekts an.
              </template>
            </ablehnen-button>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col >
        <v-card>
          <v-card-title primary-title class="cardRightTitle">
            <v-icon color="white" large>description</v-icon>
            <div class="headline">Aufgaben</div>
          </v-card-title>
          <v-card-text>
            <v-expansion-panels class="mt-3">
              <v-expansion-panel v-for="(task) in epic.tasks" :key="task.id">
                <v-expansion-panel-header>
                  <div>
                    <v-icon color="blue darken-3">{{getTaskStatusIcon(task)}}</v-icon>
                    {{task.name}}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <task-details
                      hide-authorization
                      hide-header
                      :url="task._links.details.href"
                      flat
                      @update="loadData"
                      @statusChange="loadData"
                      @delete="loadData"
                    />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div class="container-align-right">
      <v-btn color="blue-grey" class="white--text" @click="goBack">
        <v-icon left dark>keyboard_arrow_left</v-icon>
        Zurück
      </v-btn>
    </div>
    <v-dialog v-model="showAddEpicTaskModal" :persistent="true" max-width="850px">
      <add-epic-task
        v-if="showAddEpicTaskModal"
        :epicId="epic.id"
        :userOptions="departmentUsers"
        :department="epic.department"
        :isTimeparkEpic="epic.isTimeparkEpic"
        :epicProject="epic.project"
        @confirm="onAddEpicTaskConfirm"
        @cancel="onAddEpicTaskCancel"
      />
    </v-dialog>
  </v-container>
  <v-container v-else class="h-100">
    <div class="d-flex justify-center align-center h-100">
      <v-progress-circular
        v-if="loading"
        :size="70"
        :width="7"
        indeterminate
      ></v-progress-circular>
      <div v-else-if="errorCode === 403">
        <div class="text-h4 mb-4">Nicht Berechtigt!</div>
        <div class="mt-4 text--secondary">Sie verfügen nicht über die notwendigen Berechtigungen um das gesuchte Projekt anzeigen zu können.</div>
        <div class="mt-4 d-flex justify-center">
          <v-btn @click="goBack"><v-icon>arrow_back</v-icon> Zurück</v-btn>
        </div>
      </div>
      <div v-else-if="errorCode === 404">
        <div class="text-h4 mb-4">Projekt nicht gefunden!</div>
        <div class="mt-4 text--secondary">Das gesuchte Projekt konnte nicht gefunden werden.</div>
        <div class="mt-4 d-flex justify-center">
          <v-btn @click="goBack"><v-icon>arrow_back</v-icon> Zurück</v-btn>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>

import moment from 'moment';
import apiClient from "@/aufgabenparkApi/apiClient";
import AddEpicTask from './AddEpicTask';
import ProjectSelect from '../common/ProjectSelect';
import util from '../../util';
import TaskDetails from "@/components/aufgaben/TaskDetails";
import TimeparkProjectDisplay from "@/components/common/TimeparkProjectDisplay";
import AblehnenButton from "@/components/common/AblehnenButton";
import aufgabenparkApi from "@/aufgabenparkApi";

export default {
  name: 'EpicDetails',
  components: {
    AblehnenButton,
    TimeparkProjectDisplay,
    TaskDetails,
    AddEpicTask,
    ProjectSelect,
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    hasLink(ref) {
      return this.epic?._links && this.epic._links[ref];
    },
    async loadData() {
      this.errorCode = null;
      try {
        const loadEpic = this.$route.name === 'HistorieEpicDetails' ? aufgabenparkApi.getHistorieEpicDetails : aufgabenparkApi.getEpicDetails;
        const {data} = await loadEpic(this.$route.params.id);
        this.epicFound = !!data;
        if(!this.epicFound) {
          return;
        }
        this.epic = data;
        this.departmentUsers = this.getDepartmentUsers(data.department);
      } catch (e) {
        this.epicFound = false;
        this.errorCode = e.response.status;
      } finally {
        this.loading = false;
      }
    },
    async confirmAuthorizeEpic() {
      this.saving = true;
      await apiClient.put(this.epic._links.approve.href);
      await this.loadData();
      this.saving = false;
    },
    async confirmUnauthorizeEpic() {
      this.saving = true;
      await apiClient.put(this.epic._links.reject.href);
      await this.loadData();
      this.saving = false;
    },
    goBack() {
      this.$router.go(-1);
    },
    formatIsoDateToVue(isoDate) {
      if (!isoDate) {
        return null;
      }
      return moment(isoDate).format('YYYY-MM-DD');
    },
    formatDate(date) {
      if (!date) {
        return null;
      }

      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    async saveEpic() {
      this.saving = true;

      const updateEpic = {
        id: this.epic.id,
        name: this.epic.name,
        description: this.epic.description,
        plannedStart: this.epic.plannedStart,
        plannedEnd: this.epic.plannedEnd,
        project: this.epic.project,
        isTimeparkEpic: this.epic.isTimeparkEpic,
      };

      await apiClient.put(this.epic._links.edit.href, updateEpic);
      this.saving = false;
    },
    async deleteEpic() {
      this.saving = true;
      await apiClient.delete(this.epic._links.delete.href);
      this.saving = false;
      this.goBack();
    },
    addEpicTask() {
      this.showAddEpicTaskModal = true;
    },
    async onAddEpicTaskConfirm(epicTask) {
      this.showAddEpicTaskModal = false;

      await apiClient.post(this.epic._links.createTask.href, epicTask);
      await this.loadData();
    },
    onAddEpicTaskCancel() {
      this.showAddEpicTaskModal = false;
    },
    getDepartmentUsers(epicDepartment) {
      if (!epicDepartment) {
        const allUsers = [];
        this.$store.state.departments.forEach(d => allUsers.push(...d.users));

        return allUsers;
      }

      const department =
        this.$store.state.departments
          .find(x => x.name === epicDepartment);

      if (department) {
        return department.users;
      }

      return [];
    },
    getTaskStatusIcon(task) {
      return util.getStatusIcon(task.status);
    },
    closeDepartmentAssignment() {
      this.newDepartmentAssignment = null;
      this.assignDepartmentDialog = false;
    },
    startDepartmentAssignment() {
      this.newDepartmentAssignment = this.epic.department;
      this.assignDepartmentDialog = true;
    },
    async confirmAssignDepartment() {
      this.saving = true;
      await apiClient.put(this.epic._links.assignDepartment.href, JSON.stringify(this.newDepartmentAssignment), {headers: {"Content-Type": "application/json"}});
      this.saving = false;
      this.epic.department = this.newDepartmentAssignment;
      this.closeDepartmentAssignment();
    },
  },
  computed: {
    allDepartments() {
      return this.$store.state.departments.map(d => d.name);
    },
    statusIcon() {
      return util.getStatusIcon(this.epic.status);
    },
    epicType: {
      get() {
        return this.epic.isTimeparkEpic ? 'timepark' : 'normal';
      },
      set(value) {
        this.epic.isTimeparkEpic = value === 'timepark';
        if (!this.epic.isTimeparkEpic) {
          this.epic.project = null;
        }
      },
    },
    plannedStart: {
      get() {
        const date = this.epic ? this.epic.plannedStart : null;
        return this.formatIsoDateToVue(date);
      },
      set(v) {
        this.epic.plannedStart = moment(v).utc().format();
      },
    },
    plannedEnd: {
      get() {
        const date = this.epic ? this.epic.plannedEnd : null;
        return this.formatIsoDateToVue(date);
      },
      set(v) {
        this.epic.plannedEnd = moment(v).utc().format();
      },
    },
    plannedStartFormatted() {
      return this.formatDate(this.plannedStart);
    },
    plannedEndFormatted() {
      return this.formatDate(this.plannedEnd);
    },
  },
  data: () => ({
    epic: null,
    plannedStartMenu: false,
    plannedEndMenu: false,
    epicFound: false,
    loading: true,
    errorCode: null,
    valid: false,
    nameRules: [
      v => !!v || 'Bitte geben Sie einen Titel ein',
    ],
    saving: false,
    showAddEpicTaskModal: false,
    departmentUsers: [],
    assignDepartmentDialog: false,
    newDepartmentAssignment: null,
  }),
};
</script>

<style>
  .container-align-right {
    float:right
  }
  .header-with-padding {
    padding-left: 12px
  }
  .cardLeftTitle {
    background-color: #485a63;
    color: white;
  }
  .cardRightTitle {
    background-color: #018786;
    color: white;
  }
</style>
