<template>
  <v-dialog v-model="dialog" persistent max-width="800" scrollable class="action-button-dialog">
    <template #activator="{ on, attrs }">
        <v-btn
          color="blue-grey"
          class="white--text"
          :class="buttonClass"
          :loading="buttonLoading"
          v-on="on"
          v-bind="attrs"
        >
          <v-icon left dark>cloud_upload</v-icon>
          Upload
        </v-btn>
    </template>
    <v-card>
      <v-card-title>File Upload</v-card-title>
      <v-card-text class="d-flex flex-column">
        <input
          v-if="!uploading"
          ref="fileUpload"
          type="file"
          accept=".pdf, image/*"
          style="display: none"
          multiple
          @change="onFileInputChange"/>
        <v-sheet
          :class="{ 'dropzone-highlight': hoveringDropzone }"
          class="cursor-pointer dropzone"
          outlined
          rounded
          height="150"
          @click="openFileChooser"
          @drop.prevent="dropFiles"
          @dragover.prevent
          @dragenter.prevent="addDropHighlight"
          @dragleave.prevent="removeDropHighlight"
        >
          <div class="fill-height d-flex flex-column justify-center align-center">
            <v-icon x-large>cloud_upload</v-icon>
            <span v-if="hoveringDropzone">Lassen Sie die Dateien los um sie hinzuzufügen.</span>
            <span v-else>Laden Sie hier Ihre Dateien hoch.</span>
          </div>
        </v-sheet>
        <v-subheader class="mt-2">{{ preparedFiles.length }} {{preparedFiles.length === 1 ? 'File' : 'Files'}}</v-subheader>
        <v-divider v-if="hasPreparedFiles"/>
        <v-list v-if="hasPreparedFiles" class="flex-grow-1 overflow-auto">
          <v-list-item v-for="(file, fileIdx) in $v.preparedFiles.$each.$iter" :key="file.$model.file.name">
            <v-list-item-avatar tile height="120" width="120">
              <v-img :src="file.$model.preview" :aspect-ratio="1"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-text-field v-model="file.$model.uploadName" label="Name"></v-text-field>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="removePreparedFile(fileIdx)" tabindex="-1">
                <v-icon>remove_circle</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-divider v-if="hasPreparedFiles"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               :disabled="uploading"
               @click="closeDialog">Abbrechen</v-btn>
        <v-btn
          :disabled="!isValid"
          :loading="uploading"
          color="primary"
          class="white--text"
          @click="handleFilesUpload">
          <v-icon left dark>cloud_upload</v-icon>
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import aufgabenparkApi from '../../aufgabenparkApi';
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from "vuelidate";

export default {
  name: 'FileUploadButton',
  mixins: [validationMixin],
  props: {
    uploadUrl: {
      type: String,
      required: true,
    },
    buttonClass: String,
    buttonLoading: Boolean,
  },
  validations: {
    preparedFiles: {
      required,
      $each: {
        uploadName: {
          required,
        },
      },
    },
  },
  data: () => ({
    dialog: false,
    preparedFiles: [],
    dropHighlightCounter: 0,
    uploading: false,
  }),
  computed: {
    isValid() {
      return !this.$v.preparedFiles.$invalid;
    },
    hasPreparedFiles() {
      return this.preparedFiles && this.preparedFiles.length > 0;
    },
    hasPreparedImageFile() {
      if (!this.hasPreparedFiles) {
        return false;
      }

      const preparedType = this.preparedFiles[0].type ? this.preparedFiles[0].type.toLowerCase() : '';
      return preparedType.includes('image');
    },
    hoveringDropzone() {
      return this.dropHighlightCounter > 0;
    },
  },
  methods: {
    openFileChooser() {
      if(this.$refs.fileUpload) {
        this.$refs.fileUpload.click();
      }
    },
    addDropHighlight(){
      this.dropHighlightCounter++;
    },
    removeDropHighlight(){
      this.dropHighlightCounter--;
    },
    dropFiles(dropEvent) {
      this.dropHighlightCounter = 0;
      const files = dropEvent.dataTransfer.files;
      if(!files) {
        return;
      }

      this.prepareFilesForUpload(files);
    },
    onFileInputChange() {
      this.prepareFilesForUpload(this.$refs.fileUpload.files);
    },
    prepareFilesForUpload(files) {
      for (let f of files) {
        const isImage = f.type.toLowerCase().includes('image');
        if(!isImage && !f.type.toLowerCase().includes('pdf')) {
          continue;
        }
        const file = {
          preview: `static/img/${isImage ? 'blank' : 'pdf'}.png`,
          uploadName: f.name.substring(0, f.name.lastIndexOf(".")),
          file: f
        };
        if(isImage && FileReader) {
          const fr = new FileReader();
          fr.onload = function () {
            file.preview = fr.result;
          }
          fr.readAsDataURL(f);
        }
        this.preparedFiles.push(file);
      }
    },
    removePreparedFile(fileIdx) {
      this.preparedFiles.splice(fileIdx, 1);
    },
    async handleFilesUpload() {
      this.uploading = true;
      const { data } = await aufgabenparkApi.uploadFiles(this.uploadUrl, this.preparedFiles);
      this.uploading = false;
      this.closeDialog();
      this.$emit('uploaded', data);
    },
    closeDialog() {
      this.preparedFiles = [];
      this.dialog = false;
    },
  },
};
</script>
<style lang="scss">
@import "src/styles/variables";

.dropzone-highlight, .dropzone:hover {
  border-color: $color-primary !important;
  border-width: 5px !important;
  span, .v-icon {
    color: $color-primary !important;
  }
}
</style>
