import Vue from 'vue';
import Router from 'vue-router';
import MainScreen from '@/components/MainScreen';
import Dashboard from '@/components/Dashboard';
import Historie from '@/components/Historie';
import YearPlanning from '@/components/YearPlanning';
import EpicDetails from '@/components/epics/EpicDetails';
import DepartmentDetails from '@/components/departments/DepartmentDetails';
import CurrentUserTasks from '@/components/CurrentUserTasks';
import store from '@/store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: MainScreen,
      children: [
        {
          path: '/',
          component: Dashboard,
        },
        {
          path: '/Historie',
          component: Historie,
          meta: {
            requiresPermission: 'History.Read',
          },
        },
        {
          name: 'EpicDetails',
          path: '/Epic/:id',
          component: EpicDetails,
        },
        {
          name: 'HistorieEpicDetails',
          path: '/Historie/Epic/:id',
          component: EpicDetails,
        },
        {
          path: '/DepartmentDetails/:department',
          component: DepartmentDetails,
          meta: {
            requiresPermission: 'Tasks.Read'
          },
        },
        {
          path: '/MeineAufgaben/',
          component: CurrentUserTasks,
        },
        {
          path: '/Jahresplanung/',
          component: YearPlanning,
          meta: {
            requiresPermission: 'YearplanningTasks.Read',
          },
        },
      ],
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresPermission) {
    if(!store.state.user.isInitialized) {
      store.commit('navigateAfterInitialize', to);
      next(from);
    }
    const department = to.params.department;
    if(store.getters['user/canVisit'](to.meta.requiresPermission, department)) {
      next();
    } else {
      next(from);
    }
  } else {
    next();
  }
});

export default router;
