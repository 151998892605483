import Vuex from 'vuex';
import Vue from "vue";
import userModule from './user';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    departments: [],
    tasksNeedToBeAuthorized: false,
    allowManualTimesSyncToTimepark: false,
    timeparkProducts: [],
    standbyAssignmentMode: 'Daily',
    navigationRouteAfterInitialize: null,
  },
  mutations: {
    initialize(state, data) {
      state.standbyAssignmentMode = data.standbyAssignmentMode;
      state.departments = data.departments;
      state.tasksNeedToBeAuthorized = data.tasksNeedToBeAuthorized;
      state.allowManualTimesSyncToTimepark = data.allowManualTimesSyncToTimepark;
    },
    setTimeparkProducts(state, products) {
        state.timeparkProducts = products ? products : [];
    },
    navigateAfterInitialize(state, route) {
      state.navigationRouteAfterInitialize = route;
    },
    clearNavigateAfterInitialize(state) {
      state.navigationRouteAfterInitialize = null;
    }
  },
  modules: {
    user: userModule,
  },
});

export default store;
