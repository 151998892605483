<template>
  <v-text-field
    :label="placeholder"
    @input="inputChanged"
    v-if="isTextType"
    :value="initialValue"
  />
  <v-select
    v-else-if="isSelectType"
    :items="selectItems"
    :value="initialValue"
    :label="placeholder"
    @change="selectChanged"
  />
  <v-dialog
    v-else
    ref="dialog"
    persistent
    width="290px"
    :return-value.sync="date"
    >
    <template v-slot:activator="{ on }">
      <v-text-field
        :label="placeholder"
        v-on="on"
        v-model="date"
        prepend-icon="event"
        readonly
      />
    </template>

      <v-date-picker
        v-model="date"
        scrollable
        locale="de"
      >
        <v-spacer />
        <v-btn text color="primary" @click="dateSelected(null)">Abbrechen</v-btn>
        <v-btn text color="primary" @click="dateSelected(date)">Bestätigen</v-btn>
      </v-date-picker>
    </v-dialog>
</template>

<script>

import moment from 'moment';

export default {
  name: 'Filterbox',
  props: ['placeholder', 'type', 'onFilterSeleced', 'initialValue', 'selectItems'],
  computed: {
    isTextType() {
      return this.type === 'text';
    },
    isSelectType() {
      return this.type === 'select';
    },
  },
  methods: {
    inputChanged(val) {
      this.$emit('filter', val);
    },
    selectChanged(val) {
      this.$emit('filter', val);
    },
    dateSelected(date) {
      this.$refs.dialog.save(date);

      if (!date) {
        this.$emit('filter', null);
      } else {
        // Make sure to parse date as utc by adding 'Z' sufix
        const utcDate = `${date}Z`;
        const filter = moment(utcDate).utc().format();
        this.$emit('filter', filter);
      }
    },
    getInitialDateValue() {
      if (this.type === 'text' || !this.initialValue) {
        return null;
      }

      return moment(this.initialValue).format('YYYY-MM-DD');
    },
  },
  data() {
    return {
      date: this.getInitialDateValue(),
      menu: false,
      modal: false,
    };
  },
};
</script>
