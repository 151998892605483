<template>
  <v-toolbar-items>
    <v-btn
      v-for="item in departments"
      :key="item.name"
      text
      :color="getColor(item)"
      @click="select(item)"
    >
      <span :style="getDepartmentStyle(item)">
        <v-icon large>{{item.icon}}</v-icon>
      </span>
      <span>{{item.name}}</span>
    </v-btn>
  </v-toolbar-items>
</template>

<script>

import ColorHash from 'color-hash';

const colorHash = new ColorHash();

export default {
  props: {
    value: String,
  },
  methods: {
    getColor(item) {
      return item.selected ? 'blue darken-3' : 'grey lighten-1';
    },
    getDepartmentStyle(item) {
      return `color: ${colorHash.hex(item.name)}`;
    },
    select(item) {
      this.selectedDepartment = item.value;

      this.$emit('selected', item.value);
    },
  },
  computed: {
    selectedDepartment: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
    departments() {

      const result = []
      const departments = this.$store.getters['user/visibleDepartments']('Tasks.Read');
      departments.forEach((d, i) => {
        result.push({
          index: i + 1,
          name: d,
          value: d !== 'Alle Abteilungen' ? d : null,
          icon: d !== 'Alle Abteilungen' ? 'person' : null,
          selected: this.selectedDepartment === d || (d === 'Alle Abteilungen' && this.selectedDepartment === null),
        });
      });

      return result;
    },
  },
};
</script>
