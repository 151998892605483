<template>
  <v-card flat>
    <v-card-title class="d-flex justify-space-between" v-if="!hideHeader">
      <div v-if="aufgabe">
        <v-icon color="blue darken-3">{{taskStatusIcon}}</v-icon>
        {{aufgabe.name}}
      </div>
      <div>
        <v-btn text icon small @click="convertToEpic" color="indigo" v-if="!readonly">
          <v-icon>style</v-icon>
        </v-btn>
        <v-icon v-else color="indigo">style</v-icon>
        <v-btn text icon small @click="cancel">
          <v-icon>cancel</v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text v-if="aufgabe">
      <v-tabs centered v-model="tabs">
        <v-tab>Details</v-tab>
        <v-tab>Arbeitszeiten</v-tab>
        <v-tabs-items v-model="tabs">
          <v-tab-item class="pa-1">
            <v-row>
              <v-col cols="8">
                <div class="font-weight-bold" v-if="aufgabe.department">
                  <span>Abteilung: {{aufgabe.department}}</span>
                  <span v-if="departmentIsAssignable">
                    <v-tooltip bottom>
                      <template v-slot:activator="{on, attrs}">
                        <v-btn icon tile small v-on="on" v-bind="attrs" class="ml-2" @click="startDepartmentAssignment">
                          <v-icon>swap_horiz</v-icon>
                        </v-btn>
                      </template>
                      <span>Anderer Abteilung zuweisen</span>
                    </v-tooltip>
                  </span>
                </div>
                <edit-task-form
                  v-if="canEdit"
                  v-model="aufgabe"
                  :userAssignment.sync="userAssignment"
                  :department="aufgabe.department"
                />
                <aufgabe-details v-else :task="aufgabe" class="mt-2"/>
                <v-dialog v-model="assignPooledTaskDialog" persistent max-width="500">
                  <v-card>
                    <v-card-title>Bearbeiter zuweisen</v-card-title>
                    <v-card-text>
                      <bearbeiter-selection :department="aufgabe.department" v-model="pooledTaskAssignment"/>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer/>
                      <v-btn @click="assignPooledTaskDialog = false" text>Abbrechen</v-btn>
                      <v-btn @click="confirmAssignPooledTask" text>Zuweisen</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="assignDepartmentDialog" persistent max-width="750">
                  <v-card>
                    <v-card-title>Abteilung zuweisen</v-card-title>
                    <v-card-text>
                      <v-alert type="warning">
                        <div>
                          <strong>VORSICHT:</strong>
                          Bei einer Zuweisung zu einer anderen Abteilung, können nur noch Benutzer mit der entsprechenden Berechtigung in der gewählten Abteilung Zeiten auf diese Aufgabe verbuchen.
                        </div>
                        <div class="mt-2">
                          Bereits bestehende Zeitbuchungen bleiben weiterhin vorhanden.
                        </div>
                      </v-alert>
                      <v-select
                        :items="assignableDepartments"
                        v-model="newDepartmentAssignment"
                        placeholder="Abteilung"
                        hint="Abteilung"
                        required
                      />
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer/>
                      <v-btn @click="closeDepartmentAssignment" text>Abbrechen</v-btn>
                      <v-btn @click="confirmAssignDepartment" text :loading="saving">Zuweisen</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col></v-col>
              <v-col cols="3">
                <div class="mt-2" v-if="aufgabe.startedAt">Gestartet: {{aufgabe.startedAt | timestamp}}</div>
                <div class="mt-2" v-if="aufgabe.completedAt">Abgeschlossen: {{aufgabe.completedAt | timestamp}}</div>
                <div class="mt-2">Erstellt: {{aufgabe.createdAt | timestamp}}</div>
                <div class="mt-2">Erstellt von: {{aufgabe.createdBy}}</div>
              </v-col>
            </v-row>
            <v-divider v-if="hasAttachments"/>
            <attachments v-if="hasAttachments" class="mt-4" :documents="documents" :images="photos" @photoDeleted="photoDeleted" @documentDeleted="documentDeleted"/>
            <v-divider v-if="hasStatusHistorie" class="mt-4"/>
            <status-historie v-if="hasStatusHistorie" class="mt-4" :historie="aufgabe.statusHistorie" />
            <v-divider v-if="hasComments" class="mt-4"/>
            <comments v-if="hasComments" class="mt-4" :comments="comments" @changed="dataChanged"/>
          </v-tab-item>
          <v-tab-item class="pa-1">
            <task-working-times :workingTimesByUser="aufgabe.workingTimes" :contextId="aufgabe.id" @changed="reloadData"/>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card-text>
    <v-card-text v-else-if="loading">
      <v-skeleton-loader type="card-heading, list-item-three-line, list-item-three-line, list-item-three-line, divider, attachments, divider, list-item-avatar-two-line@2, divider, actions" :types="{ attachments: 'avatar@4' }"/>
    </v-card-text>
    <v-divider v-if="aufgabe"/>
    <v-card-actions>
      <v-btn
        v-if="containsLink('edit')"
        color="info"
        class="white--text"
        @click="save"
        :loading="saving"
        :disabled="!valid"
        key="save-task"
      >
        <v-icon left dark>save</v-icon>
        Speichern
      </v-btn>
      <confirm-button
        v-if="containsLink('delete')"
        color="error"
        class="white--text"
        @confirm="confirmDeleteEpicTask"
        :loading="saving"
        key="delete-task"
      >
        <v-icon left dark>close</v-icon>
        Löschen
        <span slot="dialog-content">Wollen Sie diese Aufgabe wirklich löschen?</span>
      </confirm-button>
      <file-upload-button v-if="containsLink('upload')" :uploadUrl="aufgabe._links.upload.href" :buttonLoading="saving" @uploaded="filesUploaded" key="upload-task-document"/>
      <v-btn
        v-if="containsLink('assignPooled')"
        color="info"
        class="white--text"
        @click="assignPooledTaskDialog = true"
        :loading="saving"
        key="assign-task"
      >
        <v-icon left dark>person_add</v-icon>
        Zuweisen
      </v-btn>
      <v-btn
        color="warning"
        v-if="containsLink('start')"
        class="white--text"
        @click="markAsStarted"
        :loading="saving"
        key="start-task"
      >
        <v-icon left dark>build</v-icon>
        Start
      </v-btn>
      <v-btn
        color="orange accent-2"
        v-if="containsLink('pause')"
        class="white--text"
        @click="markAsPaused"
        :loading="saving"
        key="pause-task"
      >
        <v-icon left dark>pause</v-icon>
        Pause
      </v-btn>
      <v-btn
        color="error"
        v-if="containsLink('close')"
        class="white--text"
        @click="markAsCompleted"
        :loading="saving"
        key="close-task"
      >
        <v-icon left dark>check</v-icon>
        Abschließen
      </v-btn>
      <confirm-button
        color="success"
        v-if="containsLink('reopen')"
        class="white--text"
        @confirm="confirmReopenEpicTask"
        :loading="saving"
        key="reopen-task"
      >
        <v-icon left dark>history</v-icon>
        Aufgabe erneut öffnen
        <span slot="dialog-content">Sind Sie sicher, dass Sie die Aufgabe neu öffnen möchten?</span>
      </confirm-button>
      <confirm-button
        v-if="canBeApproved"
        color="warning"
        class="white--text"
        @confirm="approveTask"
        :loading="saving"
        key="approve-task"
      >
        <v-icon left dark>thumb_up</v-icon>Freigeben
        <span slot="dialog-content">Wollen Sie diese Aufgabe freigeben?</span>
      </confirm-button>
      <ablehnen-button v-if="canBeRejected" @confirm="rejectTask" :loading="saving" key="reject-task"/>
      <v-btn
        v-if="containsLink('report')"
        color="info"
        class="white--text"
        @click="downloadReport"
        :loading="loadingReport"
        key="print-task"
      >
        <v-icon left dark>print</v-icon>
        Drucken
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import EditTaskForm from "@/components/epics/EditTaskForm";
import constants from "@/constants";
import util from "@/util";
import apiClient from "@/aufgabenparkApi/apiClient";
import aufgabenparkApi from "@/aufgabenparkApi";
import AblehnenButton from "@/components/common/AblehnenButton";
import StatusHistorie from "@/components/common/StatusHistorie";
import Attachments from "@/components/common/Attachments";

export default {
  name: "TaskDetails",
  props: {
    url: String,
    readonly: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Attachments,
    StatusHistorie,
    AblehnenButton,
    EditTaskForm,
  },
  watch: {
    url: 'reloadData',
  },
  data: () => ({
    aufgabe: null,
    loading: false,
    userAssignment: null,
    tabs: null,
    saving: false,
    assignPooledTaskDialog: false,
    pooledTaskAssignment: null,
    assignDepartmentDialog: false,
    newDepartmentAssignment: null,
    loadingReport: false,
  }),
  computed: {
    userStatus() {
      if (!this.aufgabe) {
        return null;
      }
      const scheduledStatus = [constants.status.scheduled, constants.status.inProgress, constants.status.paused];
      const fallbackStatus = scheduledStatus.includes(this.aufgabe.status) ? constants.status.scheduled : this.aufgabe.status;
      const userAssignment = this.aufgabe.assignedUsers.find(x => x.id === this.$store.state.user.userId);
      return userAssignment?.status ?? fallbackStatus;
    },
    valid: () => true,
    canEdit() {
      return !this.readonly && this.aufgabe._links && this.aufgabe._links.edit;
    },
    taskStatusIcon() {
      return util.getStatusIcon(this.aufgabe.status);
    },
    departmentIsAssignable() {
      return !this.readonly && this.aufgabe._links && this.aufgabe._links.assignDepartment;
    },
    canBeApproved() {
      return !this.hideAuthorization && this.aufgabe &&
        this.aufgabe._links && this.aufgabe._links.approve;
    },
    canBeRejected() {
      return !this.hideAuthorization && this.aufgabe &&
        this.aufgabe._links && this.aufgabe._links.reject;
    },
    photos() {
      return this.aufgabe?._embedded?.photos || [];
    },
    documents() {
      return this.aufgabe?._embedded?.documents || [];
    },
    hasAttachments() {
      return this.photos.length > 0 || this.documents.length > 0;
    },
    comments() {
      return this.aufgabe?._embedded?.comments || [];
    },
    hasComments() {
      return this.comments.length > 0;
    },
    hasStatusHistorie() {
      return this.aufgabe &&
        this.aufgabe.statusHistorie &&
        this.aufgabe.statusHistorie.length > 0;
    },
    assignableDepartments() {
      return this.$store.getters['user/visibleDepartments']('Tasks.AssignDepartment');
    },
  },
  methods: {
    containsLink(ref) {
      return !!this.aufgabe?._links && !!this.aufgabe._links[ref];
    },
    async reloadData() {
      this.loading = true;
      const { data } = await apiClient.get(this.url);
      this.aufgabe = data;
      this.userAssignment = {
        allUsers: this.aufgabe.isAssignedToAllUsers,
        users: this.aufgabe.assignedUsers.map(u => u.id),
      };
      this.loading = false;
    },
    convertToEpic() {
      if(this.aufgabe._links && this.aufgabe._links.epic) {
        this.$router.push(this.aufgabe._links.epic.href);
      }
    },
    cancel() {
      this.$emit('cancel');
    },
    async confirmDeleteEpicTask() {
      await apiClient.delete(this.aufgabe._links.delete.href);
      this.$emit('delete');
    },
    async dataChanged() {
      this.$emit('update');
    },
    photoDeleted(photo) {
      this.aufgabe._embedded.photos = this.aufgabe._embedded.photos.filter(x => x.externalId !== photo.externalId);
    },
    documentDeleted(document) {
      this.aufgabe._embedded.documents = this.aufgabe._embedded.documents.filter(x => x.id !== document.id);
    },
    async deleteComment(comment) {
      await apiClient.delete(comment._links.delete.href);
      this.aufgabe._embedded.comments = this.aufgabe._embedded.comments.filter(c => c.id !== comment.id);
    },

    async confirmAssignPooledTask() {
      await this.put(this.aufgabe._links.assignPooled.href, this.pooledTaskAssignment);
    },
    async save() {
      const command = {
        name: this.aufgabe.name,
        number: this.aufgabe.number,
        location: this.aufgabe.location,
        description: this.aufgabe.description,
        plannedStart: this.aufgabe.plannedStart,
        plannedEnd: this.aufgabe.plannedStart,
        userAssignment: this.userAssignment,
        isTimeparkTask: this.aufgabe.isTimeparkTask,
        project: this.aufgabe.project
      };

      await this.put(this.aufgabe._links.edit.href, command);
    },
    async confirmReopenEpicTask() {
      await this.put(this.aufgabe._links.reopen.href);
    },
    async approveTask() {
      await this.put(this.aufgabe._links.approve.href);
    },
    async rejectTask(comment) {
      await this.put(this.aufgabe._links.reject.href, JSON.stringify(comment), {headers: {"Content-Type": "application/json"}});
    },
    async put(url, body, config) {
      this.saving = true;
      await apiClient.put(url, body, config);
      this.saving = false;
      await this.dataChanged();
    },
    async markAsPaused() {
      await this.put(this.aufgabe._links.pause.href);
    },
    async markAsStarted() {
      await this.put(this.aufgabe._links.start.href, {project: this.aufgabe.project});
    },
    async markAsCompleted() {
      await this.put(this.aufgabe._links.close.href);
    },
    async downloadReport() {
      this.loadingReport = true;
      const file = await aufgabenparkApi.download(this.aufgabe._links.report.href);
      this.loadingReport = false;
      util.openFileInNewTab(file);
    },
    filesUploaded(uploadedFiles) {
      if (!this.aufgabe._embedded) {
        this.aufgabe._embedded = {};
      }
      if (!this.aufgabe._embedded.photos) {
        this.aufgabe._embedded.photos = [];
      }
      if (!this.aufgabe._embedded.documents) {
        this.aufgabe._embedded.documents = [];
      }
      this.aufgabe._embedded.photos.push(...uploadedFiles.photos);
      this.aufgabe._embedded.documents.push(...uploadedFiles.documents);

      this.$emit('update');
    },
    closeDepartmentAssignment() {
      this.newDepartmentAssignment = null;
      this.assignDepartmentDialog = false;
    },
    startDepartmentAssignment() {
      this.newDepartmentAssignment = this.aufgabe.department;
      this.assignDepartmentDialog = true;
    },
    async confirmAssignDepartment() {
      await this.put(this.aufgabe._links.assignDepartment.href, JSON.stringify(this.newDepartmentAssignment), { headers: {'Content-Type': 'application/json'} });
      this.closeDepartmentAssignment();
    },
  },
  async mounted() {
    await this.reloadData();
  },
}
</script>
<style lang="scss">
.v-skeleton-loader__attachments {
  display: flex;
  padding: 0 12px;
  margin: 0 auto;
  flex-wrap: wrap;
  .v-skeleton-loader__avatar {
    border-radius: 4px;
    margin: 4px;
    height: 80px;
    width: 80px;
  }
}
</style>
