<template>
<div>
  <div class="d-flex justify-space-between">
    <h3>Historie</h3>
    <v-btn outlined small @click="toggleSort"><v-icon>sort</v-icon> {{sortingText}}</v-btn>
  </div>
  <v-list two-line>
    <v-list-item v-for="entry in sortedHistorie" :key="entry.changedAt">
      <v-list-item-content>
        <v-list-item-title><span class="font-weight-medium">{{entry.changedBy}}</span> hat den Status geändert</v-list-item-title>
        <div>
          <StatusLabel :status="entry.from" small/><v-icon>arrow_right_alt</v-icon><StatusLabel :status="entry.to" small/>
        </div>
        <div v-if="entry.comment" class="d-flex mt-2">
          <v-badge overlap class="mr-4">
            <v-icon large>account_circle</v-icon>
            <v-icon slot="badge">chat_bubble</v-icon>
          </v-badge>
          <v-card min-width="250">
            <v-card-text class="text--primary pa-2 text-pre-wrap">
              {{entry.comment}}
            </v-card-text>
          </v-card>
        </div>
      </v-list-item-content>
      <v-list-item-action>
        <v-list-item-action-text>{{entry.changedAt | timestamp}}</v-list-item-action-text>
        <v-spacer/>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</div>
</template>

<script>
import moment from "moment";
import StatusLabel from "@/components/common/StatusLabel";

function entryDateDesc({changedAt: a}, {changedAt: b}) {
  if(moment(a).isSame(moment(b))) {
    return 0;
  }
  return moment(a).isBefore(b) ? 1 : -1;
}

function entryDateAsc({changedAt: a}, {changedAt: b}) {
  if(moment(a).isSame(moment(b))) {
    return 0;
  }
  return moment(a).isBefore(b) ? -1 : 1;
}
export default {
  name: "StatusHistorie",
  components: {StatusLabel},
  props: {
    historie: Array,
  },
  data: () => ({
    newestFirst: true,
  }),
  computed: {
    sortedHistorie() {
      const compareFnc = this.newestFirst ? entryDateDesc : entryDateAsc;
      return [...this.historie].sort(compareFnc);
    },
    sortingText() {
      return this.newestFirst ? "Neueste zuerst" : "Älteste zuerst";
    },
  },
  methods: {
    toggleSort() {
      this.newestFirst = !this.newestFirst;
    },
  }
}
</script>

<style scoped>

</style>
