<template>
  <v-form v-model="valid">
    <v-card>
      <v-card-title>
        <h2><v-icon>dashboard</v-icon> Neues Projekt: {{name}}</h2>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="name"
          label="Titel"
          :rules="nameRules"
          required
        />

        <v-select
          :items="departments"
          v-model="selectedDepartment"
          placeholder="Abteilung"
          hint="Abteilung"
        />

        <v-radio-group v-model="epicType" row>
          <v-radio label="Normales Projekt" value="normal"></v-radio>
          <v-radio label="Timepark Projekt" value="timepark"></v-radio>
        </v-radio-group>

        <project-select
          v-if="isTimeparkEpic"
          v-model="project"
        />

        <v-textarea
          v-model="description"
          label="Beschreibung"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn color="default" text @click="doCancel">Abbrechen</v-btn>
        <v-btn color="primary" @click="doConfirm" :disabled="valid == false">Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>

import ProjectSelect from '../common/ProjectSelect';

export default {
  name: 'AddEpic',
  components: {
    ProjectSelect,
  },
  props: {
    department: String,
  },
  watch: {
    department(val) {
      this.selectedDepartment = val;
    },
  },
  mounted() {
    this.selectedDepartment = this.department || 'Alle Abteilungen';
  },
  methods: {
    doConfirm() {
      const epic = {
        name: this.name,
        department: this.selectedDepartment,
        description: this.description,
        plannedStart: this.plannedStart,
        plannedEnd: this.plannedEnd,
        project: this.project,
        isTimeparkEpic: this.isTimeparkEpic,
      };
      this.$emit('confirm', epic);
    },
    doCancel() {
      this.$emit('cancel');
    },
    formatDate(date) {
      if (!date) {
        return null;
      }

      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
  },
  computed: {
    epicType: {
      get() {
        return this.isTimeparkEpic ? 'timepark' : 'normal';
      },
      set(value) {
        this.isTimeparkEpic = value === 'timepark';
        if (!this.isTimeparkEpic) {
          this.project = null;
        }
      },
    },
    plannedStartFormatted() {
      return this.formatDate(this.plannedStart);
    },
    plannedEndFormatted() {
      return this.formatDate(this.plannedEnd);
    },
    departments() {
      return this.$store.getters['user/visibleDepartments']('Epics.Create');
    },
  },
  data() {
    return {
      valid: false,
      name: null,
      description: null,
      selectedDepartment: null,
      nameRules: [
        v => !!v || 'Bitte geben Sie einen Titel ein',
      ],
      plannedStart: null,
      plannedStartMenu: false,
      plannedEnd: null,
      plannedEndMenu: false,
      project: null,
      isTimeparkEpic: false,
    };
  },
};
</script>
