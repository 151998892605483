var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-toolbar',[_c('h2',{staticClass:"headline ml-5"},[_vm._v(" Historie")])]),_c('div',{staticClass:"mt-4 d-flex justify-end"},[_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.epicFilterType),callback:function ($$v) {_vm.epicFilterType=$$v},expression:"epicFilterType"}},[_c('v-btn',{attrs:{"text":"","value":"Alle"}},[_vm._v(" Alle ")]),_c('v-btn',{attrs:{"text":"","value":"Projekte"}},[_vm._v(" Projekte ")]),_c('v-btn',{attrs:{"text":"","value":"Aufgaben"}},[_vm._v(" Aufgaben ")]),_c('v-btn',{attrs:{"text":"","value":"Daueraufgaben"}},[_vm._v(" Daueraufgaben ")])],1)],1),_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.data,"server-items-length":_vm.totalItems,"options":_vm.pagination,"items-per-page":10,"loading":_vm.loading,"item-key":"id"},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text,staticClass:"px-2"},[(header.filterable)?_c('filter-box',{attrs:{"placeholder":header.filterPlaceholder,"initialValue":_vm.getFilterInitialValue(header.value),"type":header.filterType,"selectItems":header.selectItems},on:{"filter":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.applyFilter.apply(void 0, [ header.value ].concat( argsArray ))}}}):_vm._e()],1)}),0)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{on:{"click":function($event){return _vm.selectItem(item)}}},[_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.department))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(_vm.formatDate(item.createdAt)))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(_vm.formatDate(item.plannedStart)))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(_vm.convertStatus(item.status)))])])]}}])}),_c('v-dialog',{attrs:{"max-width":"1200px","scrollable":""},model:{value:(_vm.showTaskDetails),callback:function ($$v) {_vm.showTaskDetails=$$v},expression:"showTaskDetails"}},[(_vm.showTaskDetails)?_c('TaskDetails',{attrs:{"url":_vm.selectedTask._links.details.href},on:{"update":_vm.entryChanged,"cancel":function($event){_vm.selectedTask = null}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }