<template>
<div>
  <v-progress-linear absolute top color="primary" indeterminate :active="loading"/>
  <v-alert v-if="!loading && hasAnySyncError" color="warning" text prominent icon="sync_problem">
    <v-row no-gutters align="center">
      <v-col class="grow">
        <span>Bei der letzten Synchronisierung mit Timepark ist ein Fehler aufgetreten. </span>
      </v-col>
      <v-col cols="auto">
        <v-btn color="warning" outlined @click="retryTimeparkSync" :loading="timeparkSyncRunning">Erneut versuchen</v-btn>
      </v-col>
    </v-row>
    <v-spacer/>
  </v-alert>
  <div v-if="!loading && hasData">
    <div v-for="userTimes in workingTimesByUser" :key="userTimes.user">
      <user-working-times-table :userWorkingTimes="userTimes" />
      <v-divider />
    </div>
    <span v-if="workingTimesByUser.length > 1">
      <strong>Total:</strong> {{totalHoursString}}
    </span>
    <v-switch v-model="showChart" label="Visualisierung einblenden" />
    <user-working-times-chart v-show="showChart" :chartData="workingTimesByUser" :height="150"/>
  </div>
  <span v-else-if="!loading" class="mt-2">Keine Arbeitszeiten</span>
</div>
</template>

<script>
import UserWorkingTimesTable from "@/components/common/UserWorkingTimesTable";
import UserWorkingTimesChart from "@/components/common/UserWorkingTimesChart";
import moment from "moment";
import aufgabenparkApi from '@/aufgabenparkApi';

export default {
  name: "TaskWorkingTimes",
  components: {UserWorkingTimesChart, UserWorkingTimesTable},
  props: {
    loading: Boolean,
    workingTimesByUser: Array,
    contextId: String,
  },
  computed: {
    totalHoursString() {
      if(this.workingTimesByUser.length === 0) {
        return '';
      }

      return this.workingTimesByUser
        .reduce((a, x) => a.add(moment.duration(x.totalDuration)), moment.duration(0))
        .format('hh:mm', {trim: false});
    },
    hasData() {
      return this.workingTimesByUser.length > 0;
    },
    hasAnySyncError() {
      return this.workingTimesByUser.some(w => w.workingTimes.some(t => t.syncStatus === 'Error'));
    }
  },
  data: () => ({
    showChart: false,
    timeparkSyncRunning: false,
  }),
  methods: {
    async retryTimeparkSync() {
      this.timeparkSyncRunning = true;
      await aufgabenparkApi.retryTimeparkSync(this.contextId);
      this.$emit('changed');
      this.timeparkSyncRunning = false;
    },
  }
}
</script>

<style scoped>

</style>
