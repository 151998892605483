<template>
  <div>
      <div class="mb-2">
        <strong>Nummer: </strong>{{task.number}}
      </div>
      <div class="mb-2">
        <strong>Bearbeiter: </strong>{{assignedUserList}}
      </div>
      <div class="mb-2">
        <strong>Standort: </strong>{{task.location}}
      </div>
      <div v-if="!!task.plannedStart">
        <strong>Fälligkeitsdatum: </strong>{{ task.plannedStart | timestamp }}
      </div>
      <v-icon color="blue darken-3">play_for_work</v-icon>
      <span v-if="task.isTimeparkTask" class="font-weight-bold">Timepark projekt</span>
      <span v-else>Normales projekt</span>
      <timepark-project-display  v-if="task.isTimeparkTask" :project="task.project"/>
      <div class="font-weight-bold">Beschreibung:</div>
      <div>{{task.description}}</div>
  </div>
</template>

<script>
import TimeparkProjectDisplay from "@/components/common/TimeparkProjectDisplay";
export default {
  name: "AufgabeDetails",
  components: {TimeparkProjectDisplay},
  props: {
    task: Object,
  },
  computed: {
    assignedUserList() {
      return this.task.isAssignedToAllUsers ? 'Alle' : this.task.assignedUsers.map(u => u.name).join(', ');
    },
  }
}
</script>
