<template>
  <v-container fluid>
    <v-toolbar class="mb-2">
      <department-selection v-model="departmentFilter" @selected="reloadData"/>
      <v-spacer/>
      <v-btn
        v-if="departmentFilter"
        color="primary"
        large
        :to="{path: `/DepartmentDetails/${departmentFilter}`}"
      >
        <v-icon>dashboard</v-icon>
        Details
      </v-btn>
      <v-btn @click="exportTasks()" v-is-allowed="'Tasks.Export'">Aufgaben exportieren</v-btn>
    </v-toolbar>
    <v-row>
      <v-col>
        <v-btn
          v-is-allowed="{ permission: 'Epics.Create', ignoreDepartmentRestriction: true }"
          @click="addEpic"
          color="success"
          rounded
          large
          key="create-epic"
        >
          <v-icon>style</v-icon>
          Neues Projekt
        </v-btn>
        <v-btn
          v-is-allowed="{ permission: 'Tasks.Create', ignoreDepartmentRestriction: true }"
          @click="addTask"
          color="primary"
          rounded
          large
          key="create-task"
        >
          <v-icon>description</v-icon>
          Neue Aufgabe
        </v-btn>
        <!-- <v-btn
          v-if="allowManualTimesSyncToTimepark"
          @click="syncTimes()"
          :loading="syncingTimes"
          color="orange darken-1 white--text"
          round
          large
        >
          <v-icon>sync</v-icon>
          Zeiten Synchronisieren
        </v-btn> -->
        <v-row>
          <v-col cols="2">
            <v-select
              :items="userOptions"
              v-model="filterUsers"
              item-value="email"
              item-text="name"
              placeholder="Bearbeiter"
              hint="Bearbeiter"
              multiple
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto">
        <v-btn-toggle v-model="epicFilterType" mandatory>
          <v-btn text value="Alle">
            Alle
          </v-btn>
          <v-btn text value="Projekte">
            Projekte
          </v-btn>
          <v-btn text value="Aufgaben">
            Aufgaben
          </v-btn>
          <v-btn text value="Daueraufgaben">
            Daueraufgaben
          </v-btn>
        </v-btn-toggle>
        <v-text-field
          label="Suche..."
          append-icon="search"
          v-model="nameFilter"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="tasksNeedToBeAuthorized"
      >
        <v-card>
          <v-card-title primary-title class="cardAuthTitle">
            <v-icon color="white" large>directions_run</v-icon>
            <div class="headline">Zur Freigabe</div>
          </v-card-title>
          <v-card-text class="card-content">
            <template v-for="(entry, i) in awaitingAuthorization">
              <task-preview
                v-if="entry.isTask"
                :key="'scheduled-task-' + i"
                :task="entry"
                @update="reloadData"
                @delete="reloadData"
                hide-status
              />
              <epic-preview
                v-else
                :key="'scheduled-epic-' + i"
                :epic="entry"
                @select="onEpicSelected"
              />
            </template>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title primary-title class="cardLeftTitle">
            <v-icon color="white" large>list_alt</v-icon>
            <div class="headline">Todo</div>
          </v-card-title>
          <v-card-text class="card-content">
            <template v-for="(entry, i) in scheduled">
              <task-preview
                v-if="entry.isTask"
                :key="'scheduled-task-' + i"
                :task="entry"
                @update="reloadData"
                @delete="reloadData"
                hide-status
              />
              <epic-preview
                v-else
                :key="'scheduled-epic-' + i"
                :epic="entry"
                @select="onEpicSelected"
              />
            </template>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title primary-title class="cardMiddleTitle">
            <v-icon color="white" large>build</v-icon>
            <div class="headline">In Bearbeitung</div>
          </v-card-title>
          <v-card-text class="card-content">
            <template v-for="(entry, i) in inProgress">
              <task-preview
                v-if="entry.isTask"
                :key="'in-progress-' + i"
                :task="entry"
                @update="reloadData"
                @delete="reloadData"
                :hide-status="isInProgress(entry)"
              />
              <epic-preview
                v-else
                :key="'in-progress-' + i"
                :epic="entry"
                @select="onEpicSelected"
              />
            </template>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title primary-title class="cardRightTitle">
            <v-icon color="white" large>check</v-icon>
            <div class="headline">Erledigt</div>
          </v-card-title>
          <v-card-text class="card-content">
            <template v-for="(entry, i) in completed">
              <task-preview
                v-if="entry.isTask"
                :key="'completed-' + i"
                :task="entry"
                @update="reloadData"
                @delete="reloadData"
                hide-status
              />
              <epic-preview
                v-else
                :key="'completed-' + i"
                :epic="entry"
                @select="onEpicSelected"
              />
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showAddEpicModal" :persistent="true" max-width="1200px">
      <add-epic
        v-if="showAddEpicModal"
        :department="departmentFilter"
        @confirm="onAddEpicConfirm"
        @cancel="onAddEpicCancel"
      />
    </v-dialog>
    <v-dialog v-model="showAddTaskModal" :persistent="true" max-width="1200px">
      <add-task
        v-if="showAddTaskModal"
        @confirm="onAddTaskConfirm"
        @cancel="onAddTaskCancel"
      />
    </v-dialog>
    <v-dialog v-model="showTimesSyncedDialog" persistent max-width="290">
      <v-card>
        <v-card-text>
          Zeiten wurden erfolgreich synchronisiert
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1"
            text
            @click="showTimesSyncedDialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import DepartmentSelection from './departments/DepartmentSelection';
import AddEpic from './epics/AddEpic';
import AddTask from './epics/AddTask';
import aufgabenparkApi from '../aufgabenparkApi';
import EpicPreview from './epics/EpicPreview';
import TaskPreview from './aufgaben/TaskPreview';
import constants from '../constants';

export default {
  name: 'Dashboard',
  watch: {
    epicFilterType() {
      this.reloadData();
    },
    nameFilter() {
      this.reloadData();
    },
    filterUsers() {
      this.reloadData();
    },
  },
  components: {
    DepartmentSelection,
    AddEpic,
    AddTask,
    EpicPreview,
    TaskPreview,
  },
  mounted() {
    if(!this.$store.getters['user/visibleDepartments']('Tasks.Read').includes('Alle Abteilungen')) {
      this.departmentFilter = this.$store.state.user.department;
    }
    this.reloadData();
  },
  methods: {
    isInProgress(task) {
      return task.status === constants.status.inProgress;
    },
    async reloadData() {
      const { data } = await aufgabenparkApi.getDashboardData({
        departmentFilter: this.departmentFilter,
        nameFilter: this.nameFilter,
        epicFilterType: this.epicFilterType,
        userFilter: this.filterUsers
      });
      this.epics = data.epics;
      this.tasks = data.tasks;
    },
    addEpic() {
      this.showAddEpicModal = true;
    },
    addTask() {
      this.showAddTaskModal = true;
    },
    syncTimes() {
      this.syncingTimes = true;
      aufgabenparkApi
        .syncTimes()
        .then(() => {
          this.syncingTimes = false;
          this.showTimesSyncedDialog = true;
        });
    },
    async onAddEpicConfirm(epic) {
      this.showAddEpicModal = false;

      await aufgabenparkApi.createEpic(epic);
      await this.reloadData();
    },
    exportTasks() {
      aufgabenparkApi
        .getTasksExport()
        .then(response => {
          const fileName = response.headers["content-disposition"].match(/filename="(.*?)"/)[1];
          const blob = new Blob([response.data], { type: response.headers["Content-Type"] });
          const link = document.createElement('a');

          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();

          URL.revokeObjectURL(link.href);
        }).catch(console.error);
    },
    onAddEpicCancel() {
      this.showAddEpicModal = false;
    },
    onEpicSelected(epic) {
      this.$router.push(`/Epic/${epic.id}`);
    },
    async onAddTaskConfirm(task) {
      this.showAddTaskModal = false;

      if (task.continuingActivity) {
        await aufgabenparkApi.createContinuingActivity(task);
      } else if(task.aufgabepool) {
        await aufgabenparkApi.createPooledTask(task);
      }
      else {
        await aufgabenparkApi.createTask(task);
      }
      await this.reloadData();
    },
    onAddTaskCancel() {
      this.showAddTaskModal = false;
    },
  },
  computed: {
    userOptions() {
      return this.$store.state.departments.filter(d => !this.departmentFilter || d.name === this.departmentFilter).flatMap(x => x.users);
    },
    awaitingAuthorization() {
      const epics = this.epics.filter(x => x.status === constants.status.awaitingAuthorization);
      const tasks = this.tasks.filter(x => x.status === constants.status.awaitingAuthorization).map(x => ({...x, isTask: true}));
      return [...epics, ...tasks];
    },
    scheduled() {
      const epics = this.epics.filter(x => x.status === constants.status.scheduled);
      const tasks = this.tasks.filter(x => x.status === constants.status.scheduled).map(x => ({...x, isTask: true}));
      return [...epics, ...tasks];
    },
    inProgress() {
      const epics = this.epics.filter(x => x.status === constants.status.inProgress || x.status === constants.status.paused);
      const tasks = this.tasks.filter(x => x.status === constants.status.inProgress || x.status === constants.status.paused).map(x => ({...x, isTask: true}));
      return [...epics, ...tasks];
    },
    completed() {
      const epics = this.epics.filter(x => x.status === constants.status.completed);
      const tasks = this.tasks.filter(x => x.status === constants.status.completed).map(x => ({...x, isTask: true}));
      return [...epics, ...tasks];
    },
    ...mapState(['tasksNeedToBeAuthorized', 'allowManualTimesSyncToTimepark']),
  },
  data: () => ({
    showAddEpicModal: false,
    showAddTaskModal: false,
    showTimesSyncedDialog: false,
    syncingTimes: false,
    epics: [],
    tasks: [],
    epicFilterType: 'Alle',
    nameFilter: '',
    filterUsers: [],
    departmentFilter: null,
  }),
};
</script>

<style scoped>
.cardAuthTitle {
  background-color: #e9b139;
  color: white;
}
.cardLeftTitle {
  background-color: #485a63;
  color: white;
}
.cardMiddleTitle {
  background-color: #3396a6;
  color: white;
}
.cardRightTitle {
  background-color: #018786;
  color: white;
}
.card-content {
  padding-top: 16px;
}
</style>
