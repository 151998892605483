<template>
  <v-dialog v-model="pickerOpen" persistent width="290px">
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="displayDate"
        :label="label"
        prepend-icon="today"
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker v-model="date" v-show="showDate">
      <v-spacer />
      <v-btn text @click="cancel">Abbrechen</v-btn>
      <v-btn text @click="mode = 'time'">Weiter</v-btn>
    </v-date-picker>
    <v-time-picker v-model="time" v-show="showTime" format="24hr" ref="timepicker">
      <v-spacer />
      <v-btn text @click="cancel">Abbrechen</v-btn>
      <v-btn text @click="emitValue">Speichern</v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  name: "DateTimePicker",
  props: {
    label: String,
    value: String,
  },
  data: () => ({
    pickerOpen: false,
    mode: 'date',
    date: null,
    time: null,
  }),
  watch: {
    value: 'updateDateTime',
  },
  computed: {
    displayDate() {
      const parsed = moment(this.value);
      return parsed.isValid() ? parsed.format('DD. MMMM yyyy HH:mm') : '';
    },
    showDate() {
      return this.mode === 'date';
    },
    showTime() {
      return this.mode === 'time';
    }
  },
  methods: {
    updateDateTime(val) {
      let datetime = moment(val);
      if(!datetime.isValid()) {
        datetime = moment();
      }
      this.time = datetime.format('HH:mm');
      this.date = datetime.format('YYYY-MM-DD');
    },
    emitValue() {
      const time = moment(this.time, 'HH:mm');
      const val = moment(this.date, 'YYYY-MM-DD').set({hour: time.hour(), minute: time.minute()});
      this.$emit('input', val.toISOString());
      this.closePicker();
    },
    cancel() {
      this.updateDateTime(this.value);
      this.closePicker();
    },
    closePicker() {
      this.pickerOpen = false;
      this.mode = 'date';
      //Workaround to display hour selection when opened again
      this.$refs.timepicker.selectingHour = true;
    },
  },
  mounted() {
    this.updateDateTime(this.value);
  },
}
</script>

<style scoped>

</style>
