<template>
  <v-dialog v-model="dialogShown" persistent max-width="300">
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text>{{message}}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1"
               text
               @click="cancel">Nein</v-btn>
        <v-btn
          color="green darken-1"
          text
          @click="confirm">Ja</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {dialogEvents} from "@/dialogs/events";

export default {
  name: "ConfirmDialog",
  data: () => ({
    dialogShown: false,
    message: '',
  }),
  methods: {
    showDialog(message) {
      this.message = message;
      this.dialogShown = true;
    },
    clearDialog() {
      this.dialogShown = false;
      this.message = '';
    },
    confirm() {
      this.clearDialog();
      dialogEvents.$emit('confirmed');
    },
    cancel() {
      this.clearDialog();
      dialogEvents.$emit('cancelled');
    },
  },
  mounted() {
    dialogEvents.$on('showConfirm', this.showDialog);
  },
  destroyed() {
    dialogEvents.$off('showConfirm');
  }
}
</script>

<style scoped>

</style>
