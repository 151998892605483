import yearplanning from './year-planning';
import axios from './apiClient';

function getTimeparkProducts() {
  return axios.get('api/TimeparkApi/GetProducts/');
}

function getTasksExport() {
  return axios.get('api/tasks/report', { responseType: "blob" });
}

function getVersion() {
  return axios.get('api/AppVersion');
}

function getSystemSettings() {
  return axios.get('api/SystemSettings/GetSystemSettings/');
}

function getDashboardData(query) {
  return axios.get('api/Dashboard', {
    params: query
  });
}

function download(url) {
  return axios.get(url, { responseType: 'blob' });
}

function createEpic(epic) {
  return axios.post('api/Epics/Create/', epic);
}
function createTask(task) {
  return axios.post('api/Epics/CreateTask/', task);
}
function createPooledTask(task) {
  return axios.post(`api/DepartmentPool/${task.department}`, task);
}
function getEpicDetails(id) {
  return axios.get(`api/Epics/Details/${id}`);
}
function getHistorieEpicDetails(id) {
  return axios.get(`api/Historie/Epics/${id}`);
}
function getDepartmentDetails(departmentName) {
  return axios.get(`api/Departments/${departmentName}`);
}
function removeTaskFromPool(department, epicId) {
  return axios.put(`api/Departments/${department}/RemoveTaskFromPool/${epicId}`);
}
function loadHistorie(pagination, filters) {
  const queryParams = {
    ...pagination,
    ...filters,
  };

  return axios.get('api/Historie/GetAll',
    {
      params: {
        ...queryParams,
      },
    },
  );
}

function loadMeineAufgaben() {
  return axios.get('api/MeineAufgaben');
}

function downloadDocument(url) {
  return axios.get(url,
    {
      responseType: 'arraybuffer',
    },
  );
}

function createPendingEpicTask() {
  return axios.post('api/Epics/CreatePendingEpicTask/');
}

function isEpicTaskNumberInUse(number, contextEpicId, contextEpicTaskId) {
  return axios.get('api/Epics/IsEpicTaskNumberInUse/',
    {
      params: {
        number,
        contextEpicId,
        contextEpicTaskId,
      },
    },
  );
}

function getNextAvailableEpicTaskNumber() {
  return axios.get('api/Epics/GetNextAvailableEpicTaskNumber/');
}

function createContinuingActivity(c) {
  return axios.post('api/ContinuingActivities/Create/', c);
}

function syncTimes() {
  return axios.get('api/TimeparkApi/SyncOngoingTasks/',
    {
      params: {
      },
    },
  );
}

function retryTimeparkSync(contextId) {
  return axios.get(`api/TimeparkSync/Retry/${contextId}`);
}

function uploadFiles(uploadUrl, files) {
  const formData = new FormData();
  files.forEach(f => {
    formData.append(f.uploadName, f.file);
  });

  return axios.post(
    `${uploadUrl}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
}

export default {
  getTimeparkProducts,
  getTasksExport,
  getVersion,
  getSystemSettings,
  createEpic,
  createTask,
  createPooledTask,
  getEpicDetails,
  getHistorieEpicDetails,
  getDepartmentDetails,
  loadHistorie,
  loadMeineAufgaben,
  downloadDocument,
  createPendingEpicTask,
  removeTaskFromPool,
  isEpicTaskNumberInUse,
  getNextAvailableEpicTaskNumber,
  createContinuingActivity,
  syncTimes,
  retryTimeparkSync,
  uploadFiles,
  getDashboardData,
  download,
  yearplanning,
};
