<template>
  <div>
    <v-card height="120" width="120" @click="downloadDocument" class="hover-wrapper">
      <v-img :src="documentImage" :aspect-ratio="1">
        <div class="d-flex flex-wrap justify-end h-100">
          <v-btn v-if="attachment._links.delete" icon tile color="error" style="background: rgba(255, 255, 255, 0.25)" class="show-on-hover" @click.stop="confirmDeleteDialog = true">
            <v-icon>delete</v-icon>
          </v-btn>
          <div class="w-100 align-self-end text-truncate white--text px-1" style="background: rgba(0,0,0,.5)">
            {{ document.name }}
          </div>
        </div>
      </v-img>
    </v-card>
    <v-dialog v-model="confirmDeleteDialog" width="450">
      <v-card>
        <v-card-title>Dokument löschen</v-card-title>
        <v-card-text>
          Wollen Sie das Dokument wirklich löschen?
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="closeConfirmDialog">Nein</v-btn>
          <v-btn text @click="confirmDelete">Ja</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import download from 'downloadjs';
import aufgabenparkApi from '../../aufgabenparkApi';
import util from "@/util";
import apiClient from "@/aufgabenparkApi/apiClient";

export default {
  name: 'Documents',
  props: {
    attachment: Object,
  },
  data: () => ({
    confirmDeleteDialog: false,
  }),
  computed: {
    document() {
      return {
        id: this.attachment.id,
        name: this.attachment.name || this.attachment.id,
        url: util.getUrlWithApiKey(this.attachment._links.download.href),
        contentType: this.attachment.contentType,
      };
    },
    contentTypeExtension() {
      if (!this.document.contentType) {
        return '';
      }

      if (this.document.contentType.toLowerCase().includes('pdf')) {
        return '.pdf';
      }

      if (this.document.contentType.toLowerCase().includes('word')) {
        return '.docx';
      }
      return '';
    },
    documentImage() {
      if (!this.document.contentType) {
        return '/static/img/blank.png';
      }

      if (this.document.contentType.toLowerCase().includes('pdf')) {
        return '/static/img/pdf.png';
      }

      if (this.document.contentType.toLowerCase().includes('word')) {
        return '/static/img/doc.png';
      }
      return '/static/img/blank.png';
    },
  },
  methods: {
    async confirmDelete() {
      await apiClient.delete(this.attachment._links.delete.href);
      this.$emit('deleted', this.attachment);
      this.closeConfirmDialog();
    },
    closeConfirmDialog() {
      this.confirmDeleteDialog = false;
    },
    downloadDocument() {
      aufgabenparkApi
        .downloadDocument(this.document.url)
        .then((response) => {
          download(
            response.data,
            `${this.document.name}${this.contentTypeExtension}`,
            response.headers['content-type'],
          );
          this.loading = false;
        },
        response => window.console.error(response));
    },
  },
};
</script>
