<template>
  <v-card tile class="card-scrollable">
    <v-card-title class="teal darken-1 white--text d-flex justify-space-between">
      <span>{{value.user}}</span>
      <span class="subtitle-2">{{aufgabenCount}}</span>
    </v-card-title>
    <v-card-text>
      <v-spacer class="mt-3"/>
      <draggable
        :id="value.id"
        v-model="userTasks"
        @add="onElementAdd"
        :options="draggableOptions"
        :move="elementCanBeMoved"
      >
        <task-preview
          v-for="(element, i) in userTasks"
          :key="'in-progress-' + i"
          :task="element"
          color-by-name
          class="mb-2"
        />
      </draggable>
    </v-card-text>
  </v-card>
</template>

<script>

import draggable from 'vuedraggable';
import apiClient from "@/aufgabenparkApi/apiClient";
import TaskPreview from "@/components/aufgaben/TaskPreview";

export default {
  name: 'UserTasksOverview',
  components: {
    TaskPreview,
    draggable,
  },
  mounted() {
  },
  props: {
    value: Object,
    selectedDepartment: String,
  },
  methods: {
    async onElementAdd(event) {
      const newElement = this.userTasks[event.newIndex];
      const unscheduledLists = ['unscheduledList', 'poolList', 'activityList'];
      if(unscheduledLists.includes(event.from.id)) {
        await apiClient.put(newElement._links.assign.href, { userId: this.value.id, priority: event.newIndex });
      } else if(event.to !== event.from) {
        await apiClient.put(newElement._links.reassign.href, { userId: this.value.id, priority: event.newIndex });
      } else {
        await apiClient.put(newElement._links.changePriority.href, { priority: event.newIndex });
      }
      this.$emit('change');
    },
    elementCanBeMoved(event) {
      const links = event.draggedContext.element._links;
      return !!(links.assign || links.reassign || links.unassign || links.changePriority);
    }
  },
  computed: {
    aufgabenCount() {
      return this.value.tasks.length;
    },
    draggableOptions() {
      return {
        group: 'tasks',
      };
    },
    userTasks: {
      get() {
        return this.value.tasks;
      },
      set(value) {
        this.$emit('input', { ...this.value, tasks: value });
      },
    },
  },
};
</script>

