import constants from '../constants';
import qs from 'qs';

// For development use the local api, else use the api on the same domain
const debug = process.env.NODE_ENV !== 'production';
const apiBase = debug ? 'http://localhost:5000/' : `${window.location.protocol}//${window.location.host}/`;

function getApiUrl() {
  return apiBase;
}
function getStatusIcon(status) {
  if (status === constants.status.scheduled) {
    return 'list_alt';
  }
  if (status === constants.status.inProgress) {
    return 'build';
  }
  if (status === constants.status.paused) {
    return 'pause';
  }
  return 'check';
}

function openFileInNewTab(downloadFile) {
  const url = window.URL.createObjectURL(new Blob([downloadFile.data], { type: "text/html"}));
  window.open(url, "_blank").focus();
}

function getUrlWithApiKey(url, params) {
  const queryString = qs.stringify({
    api_key: localStorage.getItem('auth0.access_token'),
    ...params
  });
  return `${url}?${queryString}`;
}

export default {
  getApiUrl,
  getStatusIcon,
  openFileInNewTab,
  getUrlWithApiKey
};
