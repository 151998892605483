import Auth0Lock from 'auth0-lock';
import decodeJwt from 'jwt-decode';
import moment from 'moment';
import config from './auth0-config';
import apiClient from "@/aufgabenparkApi/apiClient";
import axios from "axios";

/* eslint-disable class-methods-use-this */

class Auth0Service {
  constructor() {
    this.lock = new Auth0Lock(
      config.auth0.clientId,
      config.auth0.domain,
      {
        language: 'de',
        closable: false,
        languageDictionary: {
          title: 'Anmeldung',
        },
        auth: {
          redirectUrl: config.auth0.authEndpoint,
          responseType: config.auth0.responseType,
          responseMode: 'form_post',
          params: {
            scope: config.auth0.scope,
            state: Buffer.from(`${window.location.protocol}//${window.location.host}`).toString('base64'),
          },
        },
      },
    );

    this.lock.on('authenticated', (authResult) => {
      if (authResult && authResult.accessToken) {
        this.setSession(authResult.accessToken);
        window.location.hash = '';
        window.location.reload(); // TODO
      } else {
        window.location.reload(); // TODO
        window.console.log('Error occured while signing in.');
      }
    });

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.setSession = this.setSession.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.refreshAccessTokenIfExpirationIsImminent = this.refreshAccessTokenIfExpirationIsImminent
      .bind(this);

    this.refreshInterceptor = apiClient.interceptors.request
      .use(this.refreshAccessTokenIfExpirationIsImminent);

    const currentAccessToken = localStorage.getItem('auth0.access_token');

    if (currentAccessToken) {
      apiClient.defaults.headers.common.Authorization = `Bearer ${currentAccessToken}`;
    }
  }
  getTokenExpirationTime() {
    return moment(localStorage.getItem('auth0.expires_at'), 'X');
  }
  getLoggedInName() {
    return localStorage.getItem('auth0.name');
  }
  getLoggedInEmail() {
    return localStorage.getItem('auth0.email');
  }
  getLoggedInPicture() {
    return localStorage.getItem('auth0.picture');
  }
  login() {
    this.lock.show();
  }
  logout() {
    localStorage.removeItem('auth0.access_token');
    localStorage.removeItem('auth0.expires_at');
    localStorage.removeItem('auth0.name');
    localStorage.removeItem('auth0.email');
    localStorage.removeItem('auth0.picture');

    this.lock.logout({
      returnTo: window.location.origin,
    })
  }
  setSession(accessToken) {
    const accessTokenData = decodeJwt(accessToken);

    localStorage.setItem('auth0.access_token', accessToken);
    localStorage.setItem('auth0.expires_at', accessTokenData.exp);
    localStorage.setItem('auth0.name', accessTokenData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']);
    localStorage.setItem('auth0.email', accessTokenData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress']);
    localStorage.setItem('auth0.picture', accessTokenData.picture);

    apiClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }
  isAuthenticated() {
    const expirationDate = this.getTokenExpirationTime();
    const currentDate = moment();

    return currentDate.isBefore(expirationDate);
  }
  refreshAccessTokenIfExpirationIsImminent(requestConfig) {
    if (!this.isAuthenticated()) {
      return requestConfig;
    }

    const expirationDate = this.getTokenExpirationTime();
    const currentDate = moment();
    const tokenExpiresInHours = moment.duration(expirationDate.diff(currentDate)).asHours();

    if (tokenExpiresInHours < 4) {
      window.console.log('[Refreshing token]');
      apiClient.interceptors.request.eject(this.refreshInterceptor);

      axios.post(config.auth0.refreshEndpoint)
        .then((response) => {
          this.setSession(response.data.token);
          this.refreshInterceptor = apiClient.interceptors.request
            .use(this.refreshAccessTokenIfExpirationIsImminent);
        });
    }

    return requestConfig;
  }
}

export default Auth0Service;
