import { dialogEvents } from "@/dialogs/events";
import ConfirmDialog from "@/dialogs/ConfirmDialog";

async function confirm(message) {
  dialogEvents.$emit('showConfirm', message);
  return new Promise(resolve => {
    dialogEvents.$on('confirmed', () => {
      dialogEvents.$off('confirmed');
      dialogEvents.$off('cancelled');
      resolve(true);
    });
    dialogEvents.$on('cancelled', () => {
      dialogEvents.$off('confirmed');
      dialogEvents.$off('cancelled');
      resolve(false);
    })
  })
}

const Dialogs = {
  install(Vue) {
    if(this.installed) {
      return;
    }

    const dialogs = { confirm };

    // eslint-disable-next-line no-param-reassign
    Vue.prototype['$dialogs'] = dialogs;
    // eslint-disable-next-line no-param-reassign
    Vue['dialogs'] = dialogs;

    Vue.component('confirm-dialog', ConfirmDialog);

    this.installed = true;
  },
};

export default Dialogs;
