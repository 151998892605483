export default {
  status: {
    awaitingAuthorization: 'AwaitingAuthorization',
    scheduled: 'Scheduled',
    inProgress: 'In progress',
    completed: 'Completed',
    willNotBeDone: 'WillNotBeDone',
    paused: 'Paused',
  },
};
