<template>
  <v-card flat>
    <v-card-text>
      <div>
        <strong>Erstellt von: {{aufgabe.createdBy}}</strong>
      </div>
      <v-form>
        <v-checkbox
          v-model="doNotSynchronize"
          label="Nicht Synchronisieren"
        />

        <v-text-field
          v-model="name"
          label="Titel"
          required
          :error-messages="nameErrors"
          @input="$v.name.$touch()"
          @blur="$v.name.$touch()"
        />

        <v-radio-group v-model="taskType" row>
          <v-radio label="Normales Projekt" value="normal"></v-radio>
          <v-radio label="Timepark Projekt" value="timepark"></v-radio>
        </v-radio-group>

        <project-select
          v-if="isTimeparkTask"
          v-model="project"
          ref="timeparkProject"
        />

        <v-text-field
          v-model="location"
          label="Standort"
        />

        <v-textarea
          v-model="description"
          label="Beschreibung"
        />
      </v-form>
      <v-divider v-if="hasAttachments" />
      <attachments v-if="hasAttachments" :documents="documents" :images="photos" can-delete @deleteDocument="deleteDocument" @deleteImage="deletePhoto"/>
    </v-card-text>
    <v-card-actions>
      <v-btn color="default" text @click="cancel">Abbrechen</v-btn>

      <v-btn
        v-if="hasLink('edit')"
        color="info"
        class="white--text"
        @click="save"
        :disabled="!valid"
        key="yearplanning-edit"
      >
        <v-icon left dark>save</v-icon>
        Speichern
      </v-btn>
      <confirm-button
        v-if="hasLink('delete')"
        color="error"
        class="white--text"
        @confirm="confirmDeleteEpicTask"
        key="yearplanning-delete"
      >
        <v-icon left dark>close</v-icon>
        Löschen
        <span slot="dialog-content">Wollen Sie diese Aufgabe wirklich löschen?</span>
      </confirm-button>
      <file-upload-button v-if="hasLink('upload')" :uploadUrl="filesUploadUrl" @uploaded="filesUploaded" key="yearplannig-upload-document"/>
    </v-card-actions>
  </v-card>
</template>

<script>

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import apiClient from "@/aufgabenparkApi/apiClient";
import ProjectSelect from '../common/ProjectSelect';
import FileUploadButton from "@/components/common/FileUploadButton";
import Attachments from "@/components/common/Attachments";

export default {
  mixins: [validationMixin],
  validations: {
    name: {
      required,
    },
  },
  props: {
    assignmentId: String,
    aufgabe: {
      type: Object,
      required: true,
    },
  },
  components: {
    Attachments,
    FileUploadButton,
    ProjectSelect,
  },
  data() {
    return {
      name: this.aufgabe.name,
      isTimeparkTask: this.aufgabe.isTimeparkTask,
      location: this.aufgabe.location,
      description: this.aufgabe.description,
      project: this.aufgabe.project,
      photos: this.aufgabe._embedded.photos,
      documents: this.aufgabe._embedded.documents,
      uploadFileDialog: false,
      doNotSynchronize: this.aufgabe.doNotSynchronize,
    };
  },
  watch: {
    aufgabe: {
      handler(value) {
        this.name = value.name;
        this.isTimeparkTask = value.isTimeparkTask;
        this.location = value.location;
        this.description = value.description;
        this.project = value.project;
        this.photos = value._embedded.photos;
        this.documents = value._embedded.documents;
      },
      deep: true,
    },
  },
  computed: {
    filesUploadUrl() {
      return this.aufgabe?._links.upload.href;
    },
    valid() {
      const basicValidity = !this.$v.$anyError;

      if (this.isTimeparkTask) {
        return this.$refs.timeparkProject && this.$refs.timeparkProject.isValid() && basicValidity;
      }

      return basicValidity;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) {
        return errors;
      }
      if (!this.$v.name.required) {
        errors.push('Bitte geben Sie einen Titel ein');
      }
      return errors;
    },
    taskType: {
      get() {
        return this.isTimeparkTask ? 'timepark' : 'normal';
      },
      set(value) {
        this.isTimeparkTask = value === 'timepark';
        if (!this.isTimeparkTask) {
          this.project = null;
        }
      },
    },
    hasAttachments() {
      return (this.photos && this.photos.length > 0) ||
        (this.documents && this.documents.length > 0);
    },
  },
  methods: {
    hasLink(link) {
      return this.aufgabe && this.aufgabe._links && this.aufgabe._links[link];
    },
    async deletePhoto(photo) {
      await apiClient.delete(photo._links.delete.href);
      this.photos = this.photos.filter(p => p.externalId !== photo.externalId);
    },
    filesUploaded(uploadedFiles) {
      this.documents.push(...uploadedFiles.documents);
      this.photos.push(...uploadedFiles.photos);
    },
    async deleteDocument(document) {
      await apiClient.delete(document._links.delete.href);
      this.documents = this.documents.filter(d => d.id !== document.id);
    },
    async save() {
      const updatedEpicTask = {
        department: this.department,
        name: this.name,
        location: this.location,
        description: this.description,
        project: this.project,
        isTimeparkTask: this.isTimeparkTask,
        doNotSynchronize: this.doNotSynchronize,
      };
      const {data} = await apiClient.put(this.aufgabe._links.edit.href, updatedEpicTask);

      const result = this.assignmentId ? {id: data.id, epic: data.assignedEpic} : data;
      this.$emit('updated',result);
    },
    async confirmDeleteEpicTask() {
      await apiClient.delete(this.aufgabe._links.delete.href);
      this.$emit('deleted');
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>
