<template>
  <div @mouseleave="stopHover">
    <div v-for="(_, dayIdx) in daysIterator" :key="'standby'+dayIdx" class="grid-wrapper">
      <div
          v-for="(standby, standbyIdx) in standbys"
          :key="'standby'+standbyIdx+'-'+dayIdx"
          :class="`standby${standbyIdx+1}-cell`"
          class="year-planning-cell"
           @drop="drop($event, standbyIdx, dayIdx)"
           @dragover.prevent
           @dragenter.prevent="startHover(standbyIdx, dayIdx)"
           @mouseover="startHover(standbyIdx, dayIdx)"
           @click="paste(standbyIdx, dayIdx)"
      >
        <department-user-preview
          v-if="standby[dayIdx]"
          small
          :user="standby[dayIdx].user.name"
          draggable
          :selected="isStandbySelected(standby[dayIdx])"
          @selected="select(standbyIdx, dayIdx)"
          @dragging="drag(standbyIdx, dayIdx)"
          @copy="copy(standbyIdx, dayIdx)"
          @delete="deleteStandby(standbyIdx, dayIdx)"
        />
        <department-user-preview
          v-else-if="isLoadingAssignment(standbyIdx, dayIdx)"
          small
          loading
          :user="loadingElements[standbyIdx][dayIdx].name"
        />
        <department-user-preview
          v-else-if="isStandbyHover(standbyIdx, dayIdx)"
          small
          preview
          :user="previewUser(standbyIdx, dayIdx).name"
        />
        <div v-else></div>
      </div>
    </div>
  </div>
</template>

<script>
import DepartmentUserPreview from "@/components/departments/DepartmentUserPreview";
import aufgabenparkApi from '@/aufgabenparkApi';
import moment from "moment";

const api = aufgabenparkApi.yearplanning;

export default {
  props: {
    from: Object,
    to: Object,
    dragUser: Object,
  },

  components: {
    DepartmentUserPreview
  },

  data:() => ({
    standbys: [],
    hoverElement: null,
    draggingElement: null,
    copyElement: null,
    selectedElements: [],
    loadingElements: [],
  }),
  computed: {
    totalNumberOfDays() {
      return this.to.diff(this.from, 'days') + 1;
    },
    daysIterator() {
      return new Array(this.totalNumberOfDays)
        .fill({})
        .map((_, i) => this.from.clone().add(i, 'days'));
    },
    hoverReferenceElement() {
      return this.draggingElement || this.copyElement;
    },
    hoverUsers() {
      if (this.hoverReferenceElement) {
        return this.selectedElements.length === 0 ?
          [this.hoverReferenceElement] :
          this.selectedElements;
      }
      return [];
    },
  },

  watch: {
    from: 'reloadData',
    to: 'reloadData',
  },

  methods: {
    getIsoForDayIdx(dayIdx) {
      return this.from.clone().add(dayIdx, 'days').toISOString();
    },
    getDayIdxForDate(date) {
      return moment(date).diff(this.from, 'days');
    },
    isLoadingAssignment(standbyIdx, dayIdx) {
      return this.loadingElements[standbyIdx] && this.loadingElements[standbyIdx][dayIdx];
    },
    isStandbyHover(standbyIdx, dayIdx) {
      const isStandbyHover = this.hoverElement &&
        this.hoverElement.standbyIdx === standbyIdx &&
        this.hoverElement.dayIdx === dayIdx;

      if (this.dragUser) {
        return isStandbyHover;
      }

      return (isStandbyHover && this.hoverReferenceElement) ||
        this.hoverElement &&
        this.hoverReferenceElement &&
        this.selectedElements.some(se =>
          this.hoverReferenceElement.standbyIdx - se.standbyIdx === this.hoverElement.standbyIdx - standbyIdx &&
          this.hoverReferenceElement.dayIdx - se.dayIdx === this.hoverElement.dayIdx - dayIdx
        );
    },
    previewUser(standbyIdx, dayIdx) {
      const standbyOffset = this.hoverElement.standbyIdx - standbyIdx;
      const dayOffset = this.hoverElement.dayIdx - dayIdx;
      const preview = this.hoverUsers.find(a =>
        this.hoverReferenceElement.standbyIdx - a.standbyIdx === standbyOffset &&
        this.hoverReferenceElement.dayIdx - a.dayIdx === dayOffset
      );

      return this.dragUser ? this.dragUser : preview.user;
    },
    startHover(standbyIdx, dayIdx) {
      this.hoverElement = {
        standbyIdx,
        dayIdx,
      };
    },
    stopHover() {
      this.hoverElement = null;
    },
    copy(standbyIdx, dayIdx) {
      const standby = this.standbys[standbyIdx][dayIdx];
      this.copyElement = {
        ...standby,
        standbyIdx,
        dayIdx
      };
    },
    async paste(standbyIdx, dayIdx) {
      if (!this.copyElement) {
        return;
      }
      const reassignments = this.applyUserReassignments(standbyIdx, dayIdx, true);
      this.selectedElements = [];
      this.copyElement = null;

      // TODO: Error Handling
      const { data } = await api.reassignStandby({
        reassignments,
        copy: true,
      });

      this.applyReassignmentResult(data);
    },
    moveAssignment(fromStandbyIdx, fromDayIdx, toStandbyIdx, toDayIdx) {
      if(toStandbyIdx >= 0 && toStandbyIdx < this.standbys.length) {
        this.$set(this.standbys[toStandbyIdx], toDayIdx, this.standbys[fromStandbyIdx][fromDayIdx]);
      }
      this.$set(this.standbys[fromStandbyIdx], fromDayIdx, null);
    },
    applyUserReassignments(standbyIdx, dayIdx, copy) {
      const reassignments = [
        {
          assignmentId: this.hoverReferenceElement.id,
          standby: this.hoverReferenceElement.standbyIdx + 1,
          to: {
            date: this.getIsoForDayIdx(dayIdx),
            standby: standbyIdx + 1,
          },
        },
      ];

      this.selectedElements.filter(s => s.id !== this.hoverReferenceElement.id).forEach(assignment => {
        const standbyOffset = this.hoverElement.standbyIdx + (assignment.standbyIdx - this.hoverReferenceElement.standbyIdx);
        const dayOffset = this.hoverElement.dayIdx + (assignment.dayIdx - this.hoverReferenceElement.dayIdx);

        reassignments.push(
          {
            assignmentId: this.standbys[assignment.standbyIdx][assignment.dayIdx].id,
            standby: assignment.standbyIdx + 1,
            to: {
              date: this.getIsoForDayIdx(dayOffset),
              standby: standbyOffset + 1,
            },
          },
        );

        if(!copy) {
          this.moveAssignment(assignment.standbyIdx, assignment.dayIdx, standbyOffset, dayOffset);
        } else {
          this.setAssignmentLoading(standbyOffset, dayOffset, this.standbys[assignment.standbyIdx][assignment.dayIdx].user);
        }
      });

      if(copy) {
        this.setAssignmentLoading(standbyIdx, dayIdx, this.hoverReferenceElement.user);
      } else {
        this.moveAssignment(this.hoverReferenceElement.standbyIdx, this.hoverReferenceElement.dayIdx, standbyIdx, dayIdx);
      }

      return reassignments;
    },
    convertAssignment(assignment) {
      return { id: assignment.id, user: { userId: assignment.userId, name: assignment.user }};
    },
    applyReassignmentResult(result) {
      ['standby1', 'standby2'].forEach((list, standbyIdx) => {
        for(const assignment of result[list]) {
          const dayIdx = this.getDayIdxForDate(assignment.date);
          this.setAssignmentLoadingComplete(standbyIdx, dayIdx, this.convertAssignment(assignment));
        }
      });
    },
    drag(standbyIdx, dayIdx) {
      this.draggingElement = {
        standbyIdx,
        dayIdx,
        ...this.standbys[standbyIdx][dayIdx]
      };

      if (!this.selectedElements.find(u => u.id === this.draggingElement.id)) {
        this.selectedElements = [];
      }
    },
    async drop(e, standbyIdx, dayIdx) {
      if (e.dataTransfer.getData('group') !== 'standby') {
        return;
      }

      const sourceUser = e.dataTransfer.getData('user');
      if (sourceUser) {
        const user = JSON.parse(sourceUser);
        this.setAssignmentLoading(standbyIdx, dayIdx, user);
        this.$emit('update:dragUser', null);
        // TODO: Error Handling
        const { data } = await api.assignUserToStandby(standbyIdx + 1, {
          userId: user.userId,
          user: user.name,
          date: this.getIsoForDayIdx(dayIdx),
        });
        this.setAssignmentLoadingComplete(standbyIdx, dayIdx, this.convertAssignment(data));
      } else if(!this.standbys[standbyIdx][dayIdx] || this.hoverReferenceElement.user.userId !== this.standbys[standbyIdx][dayIdx].user.id) {
        const reassignments = this.applyUserReassignments(standbyIdx, dayIdx, false);
        this.selectedElements = [];

        // TODO: Error Handling
        await api.reassignStandby({
          reassignments,
          copy: false,
        });
      }

      this.draggingElement = null;
    },
    select(standbyIdx, dayIdx) {
      const standby = this.standbys[standbyIdx][dayIdx]
      if (!this.isStandbySelected(standby)) {
        this.selectedElements.push({
          ...standby,
          standbyIdx,
          dayIdx,
        });
      } else {
        this.selectedElements = this.selectedElements.filter(u => u.id !== standby.id);
      }
    },
    isStandbySelected(standby) {
      return standby && !!this.selectedElements.find(s => s.id === standby.id);
    },

    async deleteStandby(standbyIdx, dayIdx) {
      const element = this.standbys[standbyIdx][dayIdx];

      if(!this.selectedElements.find(se => se.id === element.id)) {
        this.selectedElements.push({
          standbyIdx,
          dayIdx,
          ...element
        })
      }

      const unassignments = this.selectedElements.map(se => ({
        assignmentId: se.id,
        standby: se.standbyIdx + 1,
      }));

      //TODO: Error handling
      await api.unassignMultipleStandbys(unassignments);

      this.selectedElements.forEach((assignment) => {
        this.$set(this.standbys[assignment.standbyIdx], assignment.dayIdx, null);
      });
      this.selectedElements = [];
    },
    async reloadData() {
      if(!this.$store.getters['user/isAllowed']('YearplanningStandby.Read')) {
        return;
      }
      const { data } = await api
        .getYearPlanningStandbyData(this.from.week(), this.to.week(), moment().year());

      const standbyLists = ['standby1', 'standby2'];

      this.standbys = standbyLists.map(list =>
        this.daysIterator.map(day => {
          const standby = data[list].find(s => moment(s.date).isSame(day, 'day'));
          return standby ? this.convertAssignment(standby) : null;
        })
      );
    },
    setAssignmentLoading(standbyIdx, dayIdx, user) {
      if(standbyIdx < 0 || standbyIdx >= this.standbys.length) {
        return;
      }

      const standbyLoadingElements = this.loadingElements[standbyIdx] || [];
      standbyLoadingElements[dayIdx] = user;
      this.$set(this.loadingElements, standbyIdx, standbyLoadingElements);
    },
    setAssignmentLoadingComplete(standbyIdx, dayIdx, newElement) {
      this.$set(this.loadingElements[standbyIdx], dayIdx, null);
      if(!this.loadingElements[standbyIdx].some(l => !!l)) {
        this.$set(this.loadingElements, standbyIdx, null);
      }
      if(!this.loadingElements[0] && !this.loadingElements[1]) {
        this.loadingElements = [];
      }
      this.$set(this.standbys[standbyIdx], dayIdx, newElement);
    },
  },
  async mounted() {
    await this.reloadData();
  },
}
</script>

<style scoped>
.standby1-cell
{
  background-color: #FFE0B2;
}
.standby2-cell
{
  background-color: #FFF3E0;
}
</style>
