<template>
  <div>
    <v-card height="120" width="120" @click="clickImage" class="hover-wrapper">
      <v-img :lazy-src="image.thumbnail" :src="image.image" :aspect-ratio="1">
        <div class="d-flex flex-wrap justify-end h-100">
          <v-btn v-if="attachment._links.delete" icon tile color="error" style="background: rgba(255, 255, 255, 0.25)" class="show-on-hover" @click.stop="confirmDeleteDialog = true">
            <v-icon>delete</v-icon>
          </v-btn>
          <div class="w-100 align-self-end text-truncate white--text px-1" style="background: rgba(0,0,0,.5)">
            {{ image.name }}
          </div>
        </div>
      </v-img>
    </v-card>
    <v-dialog v-model="confirmDeleteDialog" width="450">
      <v-card>
        <v-card-title>Foto löschen</v-card-title>
        <v-card-text>
          Wollen Sie das Foto wirklich löschen?
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="closeConfirmDialog">Nein</v-btn>
          <v-btn text @click="confirmDelete">Ja</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import urljoin from "url-join";
import util from "@/util";
import apiClient from "@/aufgabenparkApi/apiClient";

const photosUrlBase = util.getApiUrl();
export default {
  name: 'ImageGallery',
  props: {
    title: {
      type: String,
      default: 'Fotos'
    },
    attachment: Object,
  },
  data: () => ({
    confirmDeleteDialog: false,
  }),
  computed: {
    image() {
      return {
        thumbnail: util.getUrlWithApiKey(urljoin(photosUrlBase, this.attachment._links.thumbnail.href)),
        image: util.getUrlWithApiKey(urljoin(photosUrlBase, this.attachment._links.download.href)),
        externalId: this.attachment.externalId,
        name: this.attachment.name,
      };
    },
  },
  methods: {
    async confirmDelete() {
      await apiClient.delete(this.attachment._links.delete.href);
      this.$emit('deleted', this.attachment);
      this.closeConfirmDialog();
    },
    closeConfirmDialog() {
      this.selectedImage = null;
      this.confirmDeleteDialog = false;
    },
    clickImage() {
      this.$emit("open");
    },
  },
};
</script>
