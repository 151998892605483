<template>
  <div>
    <div><v-icon class="ml-1" color="blue darken-3">arrow_right_alt</v-icon> {{timeparkProject.product}}</div>
    <div><v-icon class="ml-3" color="blue darken-3">arrow_right_alt</v-icon> {{timeparkProject.order}}</div>
    <div><v-icon class="ml-5" color="blue darken-3">arrow_right_alt</v-icon> {{timeparkProject.activity}}</div>
  </div>
</template>

<script>
export default {
  props: {
    project: Object,
  },
  name: "TimeparkProjectDisplay",
  computed: {
    timeparkProject() {
      if(!this.project) {
        return null;
      }
      const products = this.$store.state.timeparkProducts;
      const product = products.find(p => p.id === this.project.product);
      const order = product.orders.find(o => o.id === this.project.order);
      const activity = order.activities.find(a => a.id === this.project.activity);

      return {
        product: product.name,
        order: order.name,
        activity: activity.name,
      }
    },
  }
}
</script>

<style scoped>

</style>
