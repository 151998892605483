<template>
  <v-dialog persistent :max-width="maxDialogWidth" v-model="dialog" class="action-button-dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-on="on" v-bind="{ ...attrs, ...$attrs }">
        <slot></slot>
      </v-btn>
    </template>
    <v-card>
      <v-card-title v-if="dialogTitle">
        {{ dialogTitle }}
      </v-card-title>
      <v-card-text>
        <slot name="dialog-content"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="dialog = false">Nein</v-btn>
        <v-btn color="green darken-1" text @click="confirm">Ja</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmButton",
  props: {
    maxDialogWidth: {
      type: String,
      default: () => '290',
      required: false,
    },
    dialogTitle: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    confirm() {
      this.$emit('confirm');
      this.dialog = false;
    }
  },
}
</script>

<style scoped>

</style>
