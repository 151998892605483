<template>
  <v-chip label :small="small" :color="chipColor" :text-color="chipTextColor">{{displayText}}</v-chip>
</template>

<script>
import constants from "@/constants";

export default {
  name: "StatusLabel",
  props: {
    status: String,
    small: Boolean,
  },
  computed: {
    displayText() {
      switch (this.status)
      {
        case constants.status.awaitingAuthorization:
          return "Zur Freigabe";
        case constants.status.scheduled:
          return "Todo";
        case constants.status.inProgress:
          return "In Bearbeitung";
        case constants.status.paused:
          return "Pausiert";
        case constants.status.completed:
          return "Erledigt";
        case constants.status.willNotBeDone:
          return "Abgelehnt";
        default:
          return this.status;
      }
    },
    chipColor() {
      switch (this.status)
      {
        case constants.status.awaitingAuthorization:
          return "orange";
        case constants.status.inProgress:
          return "blue";
        case constants.status.paused:
          return "blue";
        case constants.status.completed:
          return "green";
        case constants.status.willNotBeDone:
          return "red";
        default:
          return null;
      }
    },
    chipTextColor() {
      const whiteTextStatus = [constants.status.awaitingAuthorization, constants.status.inProgress, constants.status.paused, constants.status.completed, constants.status.willNotBeDone];
      return whiteTextStatus.includes(this.status) ? "white" : "black";
    },
  }
}
</script>
