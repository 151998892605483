<template>
  <v-row no-gutters class="department-task-preview" :style="departmentStyle" v-on:click="showDetails = true">
    <v-col class="department-task-headline">
      <div v-if="hasNumber">
        <span># {{task.number}}</span>
      </div>
      <v-icon v-if="isActivity">people_outline</v-icon>
      <v-icon v-else>description</v-icon>
      {{task.name}}
      <div v-if="hasPlannedStart">
        <v-icon>alarm</v-icon> {{plannedStart}}
      </div>
    </v-col>
    <v-col cols="auto" class="pa-0 d-flex align-end">
      <v-icon v-if="task.hasAttachments">attachment</v-icon>
      <v-icon v-if="task.hasComments">comment</v-icon>
      <v-icon v-if="showStatus">{{statusIcon}}</v-icon>
    </v-col>

    <v-dialog v-model="showDetails" max-width="1200px" scrollable>
      <task-details
        v-if="showDetails"
        :url="task._links.details.href"
        @cancel="showDetails = false"
        @update="taskUpdated"
        @delete="taskDeleted"
      />
    </v-dialog>
  </v-row>
</template>

<script>

import moment from 'moment';
import ColorHash from 'color-hash';
import util from "@/util";
import TaskDetails from "@/components/aufgaben/TaskDetails";

const colorHash = new ColorHash();

export default {
  name: 'TaskPreview',
  props: {
    task: Object,
    colorByName: Boolean,
    hideStatus: Boolean,
  },
  components: {
    TaskDetails,
  },
  data: () => ({
    showDetails: false,
  }),
  methods: {
    taskUpdated() {
      this.showDetails = false;
      this.$emit('update');
    },
    taskDeleted() {
      this.showDetails = false;
      this.$emit('delete');
    }
  },
  computed: {
    showStatus() {
      return !this.hideStatus;
    },
    isActivity() {
      return this.task.type === 'Daueraufgabe';
    },
    statusIcon() {
      return util.getStatusIcon(this.task.status);
    },
    hasNumber() {
      return !!this.task.number;
    },
    hasPlannedStart() {
      return !!this.task.plannedStart;
    },
    plannedStart() {
      return moment(this.task.plannedStart).format('DD.MMMM.YYYY HH:mm');
    },
    departmentStyle() {
      const color = this.colorByName
        ? colorHash.hex(this.task.name)
        : colorHash.hex(this.task.department);
      return `border-left: 10px solid ${color}`;
    },
  },
};
</script>

<style>
  .department-task-preview {
    width: 100%;
    border: 1px solid #e1e1e1;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  .department-task-headline {
    font-size: 14px;
  }
  .task-details {
    float: right;
    padding-right: 5px;
  }
</style>
