import Vue from 'vue';
import store from "@/store";

function createCommentNode(el, vNode) {
  const comment = document.createComment(' ');

  Object.defineProperty(comment, 'setAttribute', {
    value: () => undefined,
  });

  /* eslint-disable no-param-reassign */
  vNode.text = ' ';
  vNode.elm = comment;
  vNode.isComment = true;
  vNode.context = undefined;
  vNode.tag = undefined;
  vNode.data = {};

  if (vNode.componentInstance) {
    vNode.componentInstance.$el = comment;
  }

  /* eslint-enable no-param-reassign */
  if (el.parentNode) {
    el.parentNode.replaceChild(comment, el);
  }
}

Vue.directive('isAllowed', (el, binding, vNode) => {
    const permission = typeof binding.value === 'string' ? binding.value : binding.value.permission;
    const data = typeof binding.value === 'object' ? {  ...binding.value } : null;
    if(!store.getters['user/isAllowed'](permission, data)) {
      createCommentNode(el, vNode);
    }
});

