<template>
  <div>
    <h3 class="mb-2">{{ title }} ({{ attachments.length }})</h3>
    <v-row>
      <v-col cols="auto" :key="index" v-for="(attachment, index) in attachments">
        <component :is="attachment.component" :attachment="attachment" @deleted="attachmentDeleted" @open="openOverlay(attachment)"/>
      </v-col>
    </v-row>

    <v-overlay :value="overlayActive" v-if="overlayAttachments.length > 0">
      <v-toolbar flat color="rgba(0,0,0,.5)" class="overlay-app-bar">
        <v-toolbar-title>{{overlayAttachments[selectedOverlayIndex].name}}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon @click="closeOverlay">
          <v-icon>close</v-icon>
        </v-btn>
        <v-progress-linear absolute bottom indeterminate :active="currentOverlayLoading"/>
      </v-toolbar>
      <v-carousel v-model="selectedOverlayIndex" v-if="overlayActive" hide-delimiters height="100%">
        <v-carousel-item v-for="(_, imgIdx) of overlayAttachments" :key="imgIdx">
          <v-img
            contain
            :lazy-src="getOverlayImage(imgIdx).thumbnail"
            :src="getOverlayImage(imgIdx).image"
            @loadstart="onStartLoading(imgIdx)"
            @load="onStopLoading(imgIdx)"
            @error="onImageNotFound(imgIdx)"
          />
        </v-carousel-item>
      </v-carousel>
    </v-overlay>
  </div>
</template>

<script>
import ImageAttachment from "@/components/common/ImageAttachment";
import DocumentAttachment from "@/components/common/DocumentAttachment";
import util from "@/util";
import urljoin from "url-join";
const fallbackImage = "/static/img/image-not-found.jpg";
const photosUrlBase = util.getApiUrl();
export default {
  props: {
    title: {
      type: String,
      default: "Anhänge",
    },
    documents: Array,
    images: Array,
  },
  components: {
    ImageAttachment,
    DocumentAttachment
  },
  data: () => ({
    overlayActive: false,
    selectedOverlayIndex: 0,
    loadingOverlays: [],
    failedOverlays: [],
  }),
  computed: {
    attachments() {
      const mappedDocuments = this.documents.map(x => ({...x, component: 'DocumentAttachment'}));
      const mappedImages = this.images.map(x => ({...x, component: 'ImageAttachment'}));
      return [...mappedDocuments, ...mappedImages];
    },
    overlayAttachments() {
      return this.images.map(x => ({
        thumbnail: util.getUrlWithApiKey(urljoin(photosUrlBase, x._links.thumbnail.href)),
        image: util.getUrlWithApiKey(urljoin(photosUrlBase, x._links.download.href)),
        externalId: x.externalId,
        name: x.name,
      }));
    },
    currentOverlayLoading() {
      return this.loadingOverlays.includes(this.selectedOverlayIndex);
    },
  },
  methods: {
    attachmentDeleted(attachment) {
      const eventName = attachment.component === 'DocumentAttachment' ? 'documentDeleted' : 'photoDeleted';
      this.$emit(eventName, attachment);
    },
    openOverlay(attachment) {
      this.selectedOverlayIndex = this.overlayAttachments.findIndex(x => x.externalId === attachment.externalId);
      this.overlayActive = true;
    },
    closeOverlay() {
      this.overlayActive = false;
    },
    getOverlayImage(overlayIdx) {
      return this.failedOverlays.includes(overlayIdx) ?
        {
          thumbnail: fallbackImage,
          image: fallbackImage
        }
        : this.overlayAttachments[overlayIdx];
    },
    nextImage() {
      this.selectedOverlayIndex = (this.selectedOverlayIndex + 1) % this.overlayAttachments.length;
    },
    prevImage() {
      if (this.selectedOverlayIndex === 0) {
        this.selectedOverlayIndex = (this.overlayAttachments.length - 1);
      } else {
        this.selectedOverlayIndex -= 1;
      }
    },
    onStartLoading(overlayIdx) {
      this.loadingOverlays.push(overlayIdx);
    },
    onStopLoading(overlayIdx) {
      this.loadingOverlays = this.loadingOverlays.filter(i => i !== overlayIdx);
    },
    onImageNotFound(overlayIdx) {
      this.onStopLoading(overlayIdx);
      this.failedOverlays.push(overlayIdx);
    },

    handleGlobalKeyDown(e) {
      switch (e.keyCode) {
        case 37: this.prevImage(); break;
        case 39: this.nextImage(); break;
        case 27: this.closeOverlay(); break;
        default: break;
      }
    },
  },
  created() {
    window.addEventListener('keydown', this.handleGlobalKeyDown);
  },
  destroyed() {
    window.removeEventListener('keydown', this.handleGlobalKeyDown);
  },
}
</script>

<style scoped>
.overlay-app-bar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
}
</style>
