export default {
  auth0: {
    authEndpoint: 'https://login.softwarepark.cc/login',
    refreshEndpoint: 'https://login.softwarepark.cc/refresh',
    audience: 'https://gemeindepark.eu.auth0.com/userinfo',
    clientId: 'Qn6k_GnXafrGEu2WzcWAzUATqkvW0yeD',
    domain: 'gemeindepark.eu.auth0.com',
    responseType: 'token',
    scope: 'openid profile email aufgabenpark',
  },
};
