<template>
  <v-form v-if="hasPendingEpicTask">
    <v-card>
      <v-card-title>
        <h2><v-icon>dashboard</v-icon> Neue Aufgabe: {{name}}</h2>
      </v-card-title>
      <v-card-text>
        <v-checkbox
          v-model="doNotSynchronize"
          label="Nicht Synchronisieren"
        />

        <v-text-field
          v-model="name"
          label="Titel"
          required
          :error-messages="nameErrors"
          @input="$v.name.$touch()"
          @blur="$v.name.$touch()"
        />

        <v-select
          v-if=showDepartmentSelect
          :items="departments"
          v-model="selectedDepartment"
          placeholder="Abteilung"
          hint="Abteilung"
          required
          :error-messages="departmentErrors"
          @input="$v.selectedDepartment.$touch()"
          @blur="$v.selectedDepartment.$touch()"
        />

        <v-radio-group v-model="taskType" row>
          <v-radio label="Normales Projekt" value="normal"></v-radio>
          <v-radio label="Timepark Projekt" value="timepark"></v-radio>
        </v-radio-group>

        <project-select
          v-if="isTimeparkTask"
          ref="timeparkProject"
          v-model="project"
        />

        <v-text-field
          v-model="location"
          label="Standort"
        />

        <v-textarea
          v-model="description"
          label="Beschreibung"
        />
        <v-divider v-if="hasAttachments" />
        <attachments v-if="hasAttachments" :images="pendingEpicTask.epicTask.documents" :documents="pendingEpicTask.epicTask.photos" can-delete @deleteDocument="deleteDocument" @deleteImage="deletePhoto"/>
      </v-card-text>
      <v-card-actions>
        <v-btn color="default" text @click="doCancel">Abbrechen</v-btn>
        <v-btn color="primary" @click="doConfirm" :disabled="!valid">Speichern</v-btn>
        <file-upload-button :uploadUrl="fileUploadUrl" @uploaded="filesUploaded" />
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import aufgabenparkApi from '../../aufgabenparkApi';
import ProjectSelect from '../common/ProjectSelect';
import FileUploadButton from "@/components/common/FileUploadButton";
import Attachments from "@/components/common/Attachments";


export default {
  mixins: [validationMixin],
  validations: {
    name: {
      required,
    },
    selectedDepartment: {
      required,
    },
  },
  name: 'CreateYearPlanningTask',
  props: {
    department: {
      type: String,
    },
  },
  components: {
    Attachments,
    FileUploadButton,
    ProjectSelect,
  },
  watch: {
    department(newValue) {
      this.selectedDepartment = newValue;
    },
  },
  async mounted() {
    const response = await aufgabenparkApi.createPendingEpicTask();
    this.pendingEpicTask = response.data;
  },
  methods: {
    async deleteDocument(document) {
      await aufgabenparkApi.epicTasks.deleteDocument(this.pendingResource, document.id);
      this.pendingEpicTask.epicTask.documents = this.pendingEpicTask.epicTask.documents.filter(x => x.id !== document.id);
    },
    async deletePhoto(photo) {
      await aufgabenparkApi.epicTasks.deletePhoto(this.pendingResource, photo.externalId);
      this.pendingEpicTask.photos = this.pendingEpicTask.photos.filter(p => p.externalId !== photo.externalId);
    },
    filesUploaded(uploadedFiles) {
      this.pendingEpicTask.epicTask.documents.push(...uploadedFiles.documents);
      this.pendingEpicTask.epicTask.photos.push(...uploadedFiles.photos);
    },
    doConfirm() {
      const task = {
        pendingEpicTaskId: this.pendingEpicTask.id,
        name: this.name,
        location: this.location,
        description: this.description,
        assignedUsers: [],
        project: this.project,
        isTimeparkTask: this.isTimeparkTask,
        department: this.selectedDepartment,
        doNotSynchronize: this.doNotSynchronize,
      };
      this.$emit('confirm', task);
    },
    doCancel() {
      this.$emit('cancel');
    },
  },
  computed: {
    pendingResource() {
      return {
        epic: {
          id: this.pendingEpicTask.epicId,
        },
        task: {
          id: this.pendingEpicTask.epicTaskId,
        },
      };
    },
    fileUploadUrl() {
      return `Epics/${this.pendingEpicTask.epicId}/Upload/${this.pendingEpicTask.epicTaskId}`;
    },
    valid() {
      const basicValidity = !this.$v.$invalid && this.$v.$anyDirty && !this.$v.$anyError;

      // TODO - add timepark task project validity check to regular task also.
      if (this.isTimeparkTask) {
        return this.$refs.timeparkProject && this.$refs.timeparkProject.isValid() && basicValidity;
      }

      return basicValidity;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) {
        return errors;
      }
      if (!this.$v.name.required) {
        errors.push('Bitte geben Sie einen Titel ein');
      }
      return errors;
    },
    departmentErrors() {
      const errors = [];

      if (!this.$v.selectedDepartment.$dirty) {
        return errors;
      }
      if (!this.$v.selectedDepartment.required) {
        errors.push('Bitte wählen Sie eine Abteilung aus');
      }
      return errors;
    },
    hasAttachments() {
      return this.hasPendingEpicTask &&
        (this.pendingEpicTask.epicTask.photos && this.pendingEpicTask.epicTask.photos.length > 0) ||
        (this.pendingEpicTask.epicTask.documents && this.pendingEpicTask.epicTask.documents.length > 0);
    },
    hasPendingEpicTask() {
      return !!this.pendingEpicTask;
    },
    taskType: {
      get() {
        return this.isTimeparkTask ? 'timepark' : 'normal';
      },
      set(value) {
        this.isTimeparkTask = value === 'timepark';
        if (!this.isTimeparkTask) {
          this.project = null;
        }
      },
    },
    departments() {
      return this.$store.getters['user/visibleDepartments']('YearplanningTasks.Create');
    },
  },
  data() {
    return {
      name: null,
      location: null,
      description: null,
      project: null,
      isTimeparkTask: false,
      pendingEpicTask: null,
      uploadFileDialog: false,
      selectedDepartment: this.department,
      showDepartmentSelect: !this.department,
      doNotSynchronize: false,
    };
  },
};
</script>
