<template>
  <v-dialog persistent max-width="450" v-model="dialog" class="action-button-dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-on="on" v-bind="attrs" color="warning" class="white--text" :loading="loading">
        <v-icon left dark>thumb_down</v-icon>Ablehnen
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <slot name="dialog-title">
          Aufgabe ablehnen
        </slot>
      </v-card-title>
      <v-card-subtitle><slot name="dialog-subtitle">Bitte geben Sie ein Kommentar zur Ablehnung der Aufgabe an.</slot></v-card-subtitle>
      <v-card-text>
        <v-textarea v-model="comment" label="Kommentar"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeDialog">Abbrechen</v-btn>
        <v-btn color="primary" @click="confirm">Ablehnen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AblehnenButton",
  props: {
    loading: Boolean,
  },
  data: () => ({
    dialog: false,
    comment: null,
  }),
  methods: {
    closeDialog() {
      this.comment = null;
      this.dialog = false;
    },
    confirm() {
      this.$emit('confirm', this.comment);
      this.closeDialog();
    }
  },
}
</script>

<style scoped>

</style>
