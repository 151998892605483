<template>
  <v-container fluid class="fill-main-container grow-content-wrapper">
    <v-toolbar color="white">
      <v-toolbar-title>Jahresplanung</v-toolbar-title>
      <department-selection v-model="selectedDepartment" @selected="reloadData" class="ml-4"/>
      <v-btn
        @click="processTasksForCurrentWeek()"
        :loading="processing"
        color="orange darken-4"
        dark
        small
        absolute
        right
        fab
      >
        <v-icon>history</v-icon>
      </v-btn>
    </v-toolbar>
    <v-row class="mt-3" v-if="hasData">
      <v-col class="max-fill-height">
          <v-card class="year-planning-calendar">
            <div class="year-planning-nav year-planning-header">
              <v-btn icon x-large @click="loadPrevious" class="mr-1">
                <v-icon>keyboard_arrow_left</v-icon>
              </v-btn>
              <v-btn icon x-large @click="loadNext" class="ml-1">
                <v-icon>keyboard_arrow_right</v-icon>
              </v-btn>
            </div>
            <div class="year-planning-standby-head year-planning-header">
              <div class="standby-header">
                Bereitschaft 1
              </div>
              <div class="standby-header">
                Bereitschaft 2
              </div>
            </div>
            <div class="year-planning-user-head year-planning-header" :style="userColumnStyle">
              <div v-for="u in users" :key="u.id" class="user-header">
                <div>{{u.user}}</div>
              </div>
            </div>
            <div class="year-planning-dates calendar-body" :style="gridRowStyle">
              <div v-for="d in totalNumberOfDays" :key="d">
                <div class="date-info year-planning-cell">KW{{getWeekNumberForDay(d)}}</div>
                <div class="date-info year-planning-cell">{{getDateForDay(d)}}</div>
              </div>
            </div>
            <standbys-daily-assignments
              v-if="dailyAssignmentMode"
              class="year-planning-standby-body calendar-body"
              :style="gridRowStyle"
              :from="startDate"
              :to="endDate"
              :department="selectedDepartment"
              :dragUser="dragUser"
            />
            <standbys-weekly-assignments
              v-else
              class="year-planning-standby-body calendar-body"
              :style="gridRowStyle"
              :from="startDate"
              :to="endDate"
              :department="selectedDepartment"
              :dragUser="dragUser"
            />
            <epic-assignments
              class="year-planning-users-body calendar-body"
              :style="userBodyStyle"
              :from="startDate"
              :to="endDate"
              :department="selectedDepartment"
              :dragEpicTask.sync="dragEpicTask"
            />
          </v-card>
      </v-col>
      <v-col cols="3" xl="2" class="max-fill-height grow-content-wrapper" style="max-width: 350px">
        <v-card class="mb-4">
          <v-card-text class="d-flex justify-center align-center">
            <numeric-step :min="1" label="Wochenanzahl" v-model="weekBatchCount" />
          </v-card-text>
        </v-card>
        <v-expansion-panels multiple :value="[0,1]" class="contained-expansion-panels">
          <v-expansion-panel>
            <v-expansion-panel-header color="blue-grey darken-1">
              <template v-slot:default="{ open }">
                <h3 class="white--text">Aufgaben</h3>
                <v-btn
                  v-if="open"
                  v-is-allowed="{ permission: 'YearplanningTasks.Create', ignoreDepartmentRestriction: true }"
                  color="deep-orange darken-1"
                  dark
                  small
                  absolute
                  bottom
                  right
                  fab
                  @click.stop="showNewTaskDialog = true"
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="grey lighten-5">
              <v-spacer class="mt-3"/>
              <year-planning-epic-task
                v-for="(aufgabe, idx) in yearPlanningEpicTasks"
                class="mb-2"
                :key="aufgabe.id"
                :aufgabe="aufgabe"
                @updated="updateEpicTask($event, idx)"
                @deleted="reloadData"
                draggable
                @dragging="startDragTask($event, aufgabe)"
                @dragend.native="dragEpicTask = null"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header color="blue-grey darken-1">
              <h3 class="white--text">Users</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="grey lighten-5">
              <v-spacer class="mt-3"/>
              <department-user-preview
                v-for="u in users"
                :key="u.id"
                :user="u.user"
                draggable
                @dragging="startDragUser($event, u)"
                @dragend.native="dragUser = null"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-dialog v-model="showNewTaskDialog" :persistent="true" max-width="1200px">
      <create-year-planning-task v-if="showNewTaskDialog"
        :department="selectedDepartment"
        @cancel="showNewTaskDialog=false"
        @confirm="createNewYearPlanningTask"
      />
    </v-dialog>
    <v-overlay absolute :value="operationInProgress">
      <v-progress-circular
        color="primary"
        :size="100"
        indeterminate
      />
    </v-overlay>
  </v-container>
</template>

<script>

// import _ from 'lodash';
import moment from 'moment';
import aufgabenparkApi from '../aufgabenparkApi';
import DepartmentUserPreview from './departments/DepartmentUserPreview';
import CreateYearPlanningTask from './yearPlanning/CreateYearPlanningTask';
import NumericStep from './common/NumericStep';
import DepartmentSelection from './departments/DepartmentSelection';
import StandbysDailyAssignments from "@/components/yearPlanning/StandbysDailyAssignments";
import StandbysWeeklyAssignments from "@/components/yearPlanning/StandbysWeeklyAssignments";
import EpicAssignments from "@/components/yearPlanning/EpicAssignments";
import YearPlanningEpicTask from "@/components/yearPlanning/YearPlanningEpicTask";

const api = aufgabenparkApi.yearplanning;

const InitialWeekBatchCount = 3;

const WeekCountStorageKey = 'aufgabenpark.jahresplanung.weekcount';

export default {
  name: 'YearPlanning',
  components: {
    DepartmentUserPreview,
    CreateYearPlanningTask,
    DepartmentSelection,
    NumericStep,
    StandbysWeeklyAssignments,
    StandbysDailyAssignments,
    EpicAssignments,
    YearPlanningEpicTask
  },
  watch: {
    weekBatchCount(newValue) {
      this.weekEnd = (this.weekStart + parseInt(this.weekBatchCount, 10)) - 1;
      localStorage.setItem(WeekCountStorageKey, newValue);
    },
  },
  computed: {
    dailyAssignmentMode() {
      return this.$store.state.standbyAssignmentMode === 'Daily';
    },
    totalNumberOfDays() {
      return this.weekBatchCount * 7;
    },
    hasData() {
      return !!this.yearPlanningEpicTasks && !!this.users;
    },
    startDate() {
      return moment.utc().week(this.weekStart).startOf('week');
    },
    endDate() {
      return moment.utc().week(this.weekEnd).endOf('week');
    },
    userBodyStyle() {
      return `${this.userColumnStyle} ${this.gridRowStyle}`;
    },
    userColumnStyle() {
      const width = 1.0 / this.users.length;
      return `grid-template-columns: repeat(${this.users.length}, minmax(160px, ${width * 100}%));`;
    },
    gridRowStyle() {
      return `grid-template-rows: repeat(${this.totalNumberOfDays}, auto);`;
    },
  },
  methods: {
    startDragTask(e, aufgabe) {
      e.dataTransfer.setData('panningTask', JSON.stringify(aufgabe));
      this.dragEpicTask = aufgabe;
    },
    startDragUser(e, user) {
      this.dragUser = {userId: user.id, name: user.user};
      e.dataTransfer.setData('user', JSON.stringify(this.dragUser));
    },
    loadNext() {
      this.weekStart = this.weekStart + this.weekBatchCount;
      this.weekEnd = this.weekEnd + this.weekBatchCount;
    },
    loadPrevious() {
      this.weekStart = this.weekStart - this.weekBatchCount;
      this.weekEnd = this.weekEnd - this.weekBatchCount;
    },
    getWeekNumberForDay(day) {
      const d = this.getMomentForDay(day);
      return d.week();
    },
    getMomentForDay(day) {
      const weekStart = moment.utc().week(this.weekStart).startOf('week');
      return weekStart.add(day - 1, 'days');
    },
    getDateForDay(day) {
      const m = this.getMomentForDay(day);
      return m ? m.format('DD. MM. YYYY') : '';
    },
    async reloadData() {
      if (this.operationInProgress) {
        return;
      }

      this.operationInProgress = true;

      const {data} = await api.getYearPlanningData(this.selectedDepartment);
      this.yearPlanningEpicTasks = data.yearPlanningEpicTasks;
      this.users = data.users;

      this.operationInProgress = false;
    },
    async createNewYearPlanningTask(epicTask) {
      this.showNewTaskDialog = false;

      await api.createYearPlanningEpicTask(epicTask);
      await this.reloadData();
    },
    updateEpicTask(aufgabe, idx) {
      this.$set(this.yearPlanningEpicTasks, idx, aufgabe);
    },
    async processTasksForCurrentWeek() {
      this.processing = true;
      await api.processYearPlanningTasksForCurrentWeek()
      this.processing = false;
    },
    getInitialWeekCount() {
      const fromLocalStorage = localStorage.getItem(WeekCountStorageKey);
      if (!fromLocalStorage || isNaN(parseInt(fromLocalStorage, 10))) {
        return InitialWeekBatchCount;
      }

      return parseInt(fromLocalStorage, 10);
    },
  },
  async mounted() {
    if(!this.$store.getters['user/visibleDepartments']('YearplanningTasks.Read').includes('Alle Abteilungen')) {
      this.departmentFilter = this.$store.state.user.department;
    }
    await this.reloadData();
  },
  data() {
    const initialWeekBatchCount = this.getInitialWeekCount();

    return {
      users: [],
      yearPlanningEpicTasks: null,
      selectedDepartment: null,
      dragEpicTask: null,
      dragUser: null,
      weekStart: moment().week(),
      weekEnd: (moment().week() + initialWeekBatchCount) - 1,
      showNewTaskDialog: false,
      weekBatchCount: initialWeekBatchCount,
      processing: false,
      operationInProgress: false,
    };
  },
};
</script>
<style lang="scss">
.year-planning-calendar {
  display: grid;
  grid-template-areas: "nav standby-head users-head"
  "dates standby-body users-body";
  grid-template-columns: max-content 80px minmax(0, 1fr);
  grid-template-rows: 140px max-content;
  width: 100%;
  max-height: 100%;
  overflow: auto;

  .year-planning-cell {
    border: 1px solid #cbcbcb;
    border-top: none;
    border-left: none;
    height: 40px;
    box-sizing: content-box;
    * {
      box-sizing: border-box;
    }
  }
  .year-planning-cell:first-child {
    border-left: 1px !important;
  }
  .calendar-body div:nth-of-type(7n) > .year-planning-cell {
    border-bottom: 2px solid #ababab;
  }

  .year-planning-week-cell {
    border: 1px none #cbcbcb;
    border-right-style: solid;
    border-bottom: 2px solid #ababab;
    height: calc(41px * 6 + 40px);
    box-sizing: content-box;
    * {
      box-sizing: border-box;
    }
  }

  .year-planning-header {
    font-weight: bold;
    position: sticky;
    text-align: center;
    top: 0;
    border-bottom: 1px solid #cbcbcb;
    z-index: 1;
  }

  .year-planning-nav {
    background: white;
    grid-area: nav;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .year-planning-standby-head {
    grid-area: standby-head;
    display: grid;
    grid-template-columns: 40px 40px;
    background: white;
    white-space: nowrap;
    font-size: 18px;

    .standby-header:first-child {
      background-color: #F57C00;
    }

    .standby-header:nth-child(2) {
      background-color: #FB8C00;
    }

    .standby-header {
      color: white;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      transform: rotate(180deg);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;
    }
  }

  .year-planning-user-head {
    grid-area: users-head;
    display: grid;
    color: white;

    .user-header {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #556e79;

      div {
        transform: rotate(315deg); /* 45 is really 360 - 45 */
        padding: 5px 10px;
      }
    }
  }

  .year-planning-dates {
    grid-area: dates;
    background-color: #E0F2F1;
    text-align: center;

    div {
      display: flex;
    }

    .date-info {
      padding-left: 1.5em;
      padding-right: 1.5em;
      align-items: center;
    }
  }

  .year-planning-standby-body {
    grid-area: standby-body;
    display: grid;
    grid-template-columns: 40px 40px;
  }

  .year-planning-users-body {
    grid-area: users-body;
    display: grid;
  }

  .grid-wrapper {
    display: contents;
  }
}
</style>
