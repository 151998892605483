<template>
  <fragment>
    <div class="caption">{{label}}</div>
    <v-btn text color="primary" @click="onDecrease">
      <v-icon>remove</v-icon>
    </v-btn>
    {{value}}
    <v-btn text color="primary" @click="onIncrease">
      <v-icon>add</v-icon>
    </v-btn>
  </fragment>
</template>

<script>

export default {
  name: 'NumericStep',
  props: {
    label: {
      type: String,
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    value: {
      type: Number,
      mandatory: true,
    },
  },
  methods: {
    onDecrease() {
      if (this.min && this.value <= this.min) {
        return;
      }

      this.$emit('input', this.value - 1);
    },
    onIncrease() {
      if (this.max && this.value >= this.max) {
        return;
      }

      this.$emit('input', this.value + 1);
    },
  },
  data: () => ({
  }),
};
</script>
