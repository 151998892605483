<template>
  <div v-if="hasComments">
    <h3 class="mb-2">Kommentare</h3>
    <div v-for="c in comments" :key="c.date" class="d-flex">
      <v-badge overlap class="mr-4">
        <v-icon large>account_circle</v-icon>
        <v-icon slot="badge">chat_bubble</v-icon>
      </v-badge>
      <v-hover v-slot="{ hover }">
        <v-card min-width="250">
          <v-list-item dense class="pr-0">
            <v-list-item-content>
              <v-list-item-title v-text="c.commentBy"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="ma-0 align-self-start">
              <v-btn v-if="canDelete" v-show="hover" icon color="error" @click="deleteComment(c)">
                <v-icon>delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-card-text class="text--primary pt-0 pb-2 pr-2 text-pre-wrap">
            {{c.comment}}
            <div class="text-subtitle-2 font-weight-regular text--secondary text-right">
              {{c.date | timestamp}}
            </div>
          </v-card-text>
        </v-card>
      </v-hover>
    </div>
    <v-dialog v-model="confirmDeleteDialog" width="450">
      <v-card>
        <v-card-title>
          Kommentar Löschen
        </v-card-title>
        <v-card-text>
          Wollen Sie den Kommentar wirklich löschen?
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="closeConfirmDialog">Nein</v-btn>
          <v-btn text @click="confirmDelete">Ja</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Comments",
  props: {
    comments: Array,
    canDelete: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    confirmDeleteDialog: false,
    selectedComment: null,
  }),
  computed: {
    hasComments() {
      return this.comments && this.comments.length > 0;
    }
  },
  methods: {
    deleteComment(comment) {
      this.selectedComment = comment;
      this.confirmDeleteDialog = true;
    },
    confirmDelete() {
      this.$emit('delete', this.selectedComment);
      this.closeConfirmDialog();
    },
    closeConfirmDialog() {
      this.selectedComment = null;
      this.confirmDeleteDialog = false;
    }
  },
}
</script>
