<template>
  <div
    v-if="isAssigned"
    :class="`department-user-small-preview
      ${preview ? ' department-user-small-preview-transparent': ''}
      ${selected ? ' selected' : ' unselected'}`"
    @click.shift.prevent="$emit('selected')"
    @click.ctrl.prevent="$emit('selected')"
    :draggable="draggable"
    @dragstart="startDrag"
    @contextmenu.prevent="openMenu"
  >
    <div v-if="small" class="department-user-small-preview-text">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on">{{initials}}</span>
        </template>
        <span>{{user}}</span>
      </v-tooltip>
    </div>
    <div v-else class="department-user-week-text">
      {{user}}
    </div>
    <v-menu
      v-model="showMenu"
      :position-x="menuX"
      :position-y="menuY"
      absolute
      offset-y>
      <v-list>
        <v-list-item @click="$emit('copy')">
          <v-icon>content_copy</v-icon>
          <v-list-item-title>Kopieren</v-list-item-title>
        </v-list-item>
        <v-list-item @click="$emit('delete')">
          <v-icon>delete</v-icon>
          <v-list-item-title>Löschen</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-overlay absolute :value="loading">
      <v-progress-circular indeterminate />
    </v-overlay>
  </div>
  <div v-else :class="`department-user-preview ${selected ? 'selected' : ''}`"
       @click.shift="$emit('selected')"
    :style="userStyle"
       :draggable="draggable"
       @dragstart="startDrag">
    <div class="department-user-headline">
      {{user}}
    </div>

    <v-overlay absolute :value="loading">
      <v-progress-circular indeterminate />
    </v-overlay>
  </div>
</template>

<script>

import ColorHash from 'color-hash';

const colorHash = new ColorHash();

export default {
  name: 'DepartmentUserPreview',
  props: {
    user: String,
    small: Boolean,
    week: Boolean,
    preview: Boolean,
    selected: Boolean,
    draggable: Boolean,
    loading: Boolean,
  },
  data: () => ({
    showMenu: false,
    menuX: 0,
    menuY: 0,
  }),
  methods: {
    openMenu(e) {
      this.menuX = e.clientX;
      this.menuY = e.clientY;
      this.showMenu = true;
    },
    startDrag(e) {
      // eslint-disable-next-line no-param-reassign
      e.dataTransfer.dropEffect = 'move';
      // eslint-disable-next-line no-param-reassign
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('group', 'standby');
      this.$emit('dragging', e);
    },
  },
  computed: {
    initials() {
      const initials = this.user.match(/\b\w/g) || [];
      return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    },
    userStyle() {
      const color = colorHash.hex(this.user);
      return `border-left: 10px solid ${color}`;
    },
    isAssigned() {
      return this.small || this.week;
    }
  },
};
</script>

<style>
  .department-user-preview {
    width: 100%;
    border: 1px solid #e1e1e1;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
  }
  .department-user-headline {
    font-size: 14px;
  }
  .department-user-small-preview {
    overflow: hidden;
    height: 100%;
    width: 100%;
    text-align: center;
    vertical-align: center;
    cursor: pointer;
    position: relative;
  }
   .department-user-small-preview-transparent {
    opacity: 0.4;
  }
  .selected {
    background-color: #2ac977;
  }
  .unselected {
    background-color: #F1F8E9;
  }
  .department-user-week-text {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    height: 100%;
  }
</style>
