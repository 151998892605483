<template>
  <div @mouseleave="stopHover">
    <div v-for="(_, weekIdx) in weeksIterator" :key="'standby'+weekIdx" class="grid-wrapper">
      <div
          v-for="(standby, standbyIdx) in standbys"
          :key="'standby'+standbyIdx+'-'+weekIdx"
          :class="`standby${standbyIdx+1}-cell`"
          class="year-planning-week-cell"
           @drop="drop($event, standbyIdx, weekIdx)"
           @dragover.prevent
           @dragenter.prevent="startHover(standbyIdx, weekIdx)"
           @mouseover="startHover(standbyIdx, weekIdx)"
           @click="paste(standbyIdx, weekIdx)"
      >
        <department-user-preview
          v-if="standby[weekIdx]"
          week
          :user="standby[weekIdx].user.name"
          draggable
          :selected="isStandbySelected(standby[weekIdx])"
          @selected="select(standbyIdx, weekIdx)"
          @dragging="drag(standbyIdx, weekIdx)"
          @copy="copy(standbyIdx, weekIdx)"
          @delete="deleteStandby(standbyIdx, weekIdx)"
        />
        <department-user-preview
          v-else-if="isLoadingAssignment(standbyIdx, weekIdx)"
          week
          loading
          :user="loadingElements[standbyIdx][weekIdx].name"
        />
        <department-user-preview
          v-else-if="isStandbyHover(standbyIdx, weekIdx)"
          week
          preview
          :user="previewUser(standbyIdx, weekIdx).name"
        />
        <div v-else></div>
      </div>
    </div>
  </div>
</template>

<script>
import DepartmentUserPreview from "@/components/departments/DepartmentUserPreview";
import aufgabenparkApi from '@/aufgabenparkApi';
import moment from "moment";

const api = aufgabenparkApi.yearplanning;

export default {
  props: {
    from: Object,
    to: Object,
    dragUser: Object,
  },

  components: {
    DepartmentUserPreview
  },

  data:() => ({
    standbys: [],
    hoverElement: null,
    draggingElement: null,
    copyElement: null,
    selectedElements: [],
    loadingElements: [],
  }),
  computed: {
    totalNumberOfWeeks() {
      return this.to.diff(this.from, 'weeks') + 1;
    },
    weeksIterator() {
      return new Array(this.totalNumberOfWeeks)
        .fill({})
        .map((_, i) => this.from.clone().add(i, 'weeks'));
    },
    hoverReferenceElement() {
      return this.draggingElement || this.copyElement;
    },
    hoverUsers() {
      if (this.hoverReferenceElement) {
        return this.selectedElements.length === 0 ?
          [this.hoverReferenceElement] :
          this.selectedElements;
      }
      return [];
    },
  },

  watch: {
    from: 'reloadData',
    to: 'reloadData',
  },

  methods: {
    getIsoForWeekIdx(weekIdx) {
      return this.from.clone().add(weekIdx, 'weeks').toISOString();
    },
    getWeekIdxForDate(date) {
      return moment(date).diff(this.from, 'weeks');
    },
    isLoadingAssignment(standbyIdx, weekIdx) {
      return this.loadingElements[standbyIdx] && this.loadingElements[standbyIdx][weekIdx];
    },
    isStandbyHover(standbyIdx, weekIdx) {
      const isStandbyHover = this.hoverElement &&
        this.hoverElement.standbyIdx === standbyIdx &&
        this.hoverElement.weekIdx === weekIdx;

      if (this.dragUser) {
        return isStandbyHover;
      }

      return (isStandbyHover && this.hoverReferenceElement) ||
        this.hoverElement &&
        this.hoverReferenceElement &&
        this.selectedElements.some(se =>
          this.hoverReferenceElement.standbyIdx - se.standbyIdx === this.hoverElement.standbyIdx - standbyIdx &&
          this.hoverReferenceElement.weekIdx - se.weekIdx === this.hoverElement.weekIdx - weekIdx
        );
    },
    previewUser(standbyIdx, weekIdx) {
      const standbyOffset = this.hoverElement.standbyIdx - standbyIdx;
      const weekOffset = this.hoverElement.weekIdx - weekIdx;
      const preview = this.hoverUsers.find(a =>
        this.hoverReferenceElement.standbyIdx - a.standbyIdx === standbyOffset &&
        this.hoverReferenceElement.weekIdx - a.weekIdx === weekOffset
      );

      return this.dragUser ? this.dragUser : preview.user;
    },
    startHover(standbyIdx, weekIdx) {
      this.hoverElement = {
        standbyIdx,
        weekIdx,
      };
    },
    stopHover() {
      this.hoverElement = null;
    },
    copy(standbyIdx, weekIdx) {
      const standby = this.standbys[standbyIdx][weekIdx];
      this.copyElement = {
        ...standby,
        standbyIdx,
        weekIdx
      };
    },
    async paste(standbyIdx, weekIdx) {
      if (!this.copyElement) {
        return;
      }
      const reassignments = this.applyUserReassignments(standbyIdx, weekIdx, true);
      this.selectedElements = [];
      this.copyElement = null;

      // TODO: Error Handling
      const { data } = await api.reassignStandby({
        reassignments,
        copy: true,
      });

      this.applyReassignmentResult(data);
    },
    moveAssignment(fromStandbyIdx, fromWeekIdx, toStandbyIdx, toWeekIdx) {
      if(toStandbyIdx >= 0 && toStandbyIdx < this.standbys.length) {
        this.$set(this.standbys[toStandbyIdx], toWeekIdx, this.standbys[fromStandbyIdx][fromWeekIdx]);
      }
      this.$set(this.standbys[fromStandbyIdx], fromWeekIdx, null);
    },
    applyUserReassignments(standbyIdx, weekIdx, copy) {
      const reassignments = [
        {
          assignmentId: this.hoverReferenceElement.id,
          standby: this.hoverReferenceElement.standbyIdx + 1,
          to: {
            date: this.getIsoForWeekIdx(weekIdx),
            standby: standbyIdx + 1,
          },
        },
      ];

      this.selectedElements.filter(s => s.id !== this.hoverReferenceElement.id).forEach(assignment => {
        const standbyOffset = this.hoverElement.standbyIdx + (assignment.standbyIdx - this.hoverReferenceElement.standbyIdx);
        const weekOffset = this.hoverElement.weekIdx + (assignment.weekIdx - this.hoverReferenceElement.weekIdx);

        reassignments.push(
          {
            assignmentId: this.standbys[assignment.standbyIdx][assignment.weekIdx].id,
            standby: assignment.standbyIdx + 1,
            to: {
              date: this.getIsoForWeekIdx(weekOffset),
              standby: standbyOffset + 1,
            },
          },
        );

        if(!copy) {
          this.moveAssignment(assignment.standbyIdx, assignment.weekIdx, standbyOffset, weekOffset);
        } else {
          this.setAssignmentLoading(standbyOffset, weekOffset, this.standbys[assignment.standbyIdx][assignment.weekIdx].user);
        }
      });

      if(copy) {
        this.setAssignmentLoading(standbyIdx, weekIdx, this.hoverReferenceElement.user);
      } else {
        this.moveAssignment(this.hoverReferenceElement.standbyIdx, this.hoverReferenceElement.weekIdx, standbyIdx, weekIdx);
      }

      return reassignments;
    },
    convertAssignment(assignment) {
      return { id: assignment.id, user: { userId: assignment.userId, name: assignment.user }};
    },
    applyReassignmentResult(result) {
      ['standby1', 'standby2'].forEach((list, standbyIdx) => {
        for(const assignment of result[list]) {
          const weekIdx = this.getWeekIdxForDate(assignment.date);
          this.setAssignmentLoadingComplete(standbyIdx, weekIdx, this.convertAssignment(assignment));
        }
      });
    },
    drag(standbyIdx, weekIdx) {
      this.draggingElement = {
        standbyIdx,
        weekIdx,
        ...this.standbys[standbyIdx][weekIdx]
      };

      if (!this.selectedElements.find(u => u.id === this.draggingElement.id)) {
        this.selectedElements = [];
      }
    },
    async drop(e, standbyIdx, weekIdx) {
      if (e.dataTransfer.getData('group') !== 'standby') {
        return;
      }

      const sourceUser = e.dataTransfer.getData('user');
      if (sourceUser) {
        const user = JSON.parse(sourceUser);
        this.setAssignmentLoading(standbyIdx, weekIdx, user);
        this.$emit('update:dragUser', null);
        // TODO: Error Handling
        const { data } = await api.assignUserToStandby(standbyIdx + 1, {
          userId: user.userId,
          user: user.name,
          date: this.getIsoForWeekIdx(weekIdx),
        });
        this.setAssignmentLoadingComplete(standbyIdx, weekIdx, this.convertAssignment(data));
      } else if(!this.standbys[standbyIdx][weekIdx] || this.hoverReferenceElement.user.userId !== this.standbys[standbyIdx][weekIdx].user.id) {
        const reassignments = this.applyUserReassignments(standbyIdx, weekIdx, false);
        this.selectedElements = [];

        // TODO: Error Handling
        await api.reassignStandby({
          reassignments,
          copy: false,
        });
      }

      this.draggingElement = null;
    },
    select(standbyIdx, weekIdx) {
      const standby = this.standbys[standbyIdx][weekIdx]
      if (!this.isStandbySelected(standby)) {
        this.selectedElements.push({
          ...standby,
          standbyIdx,
          weekIdx,
        });
      } else {
        this.selectedElements = this.selectedElements.filter(u => u.id !== standby.id);
      }
    },
    isStandbySelected(standby) {
      return standby && !!this.selectedElements.find(s => s.id === standby.id);
    },

    async deleteStandby(standbyIdx, weekIdx) {
      const element = this.standbys[standbyIdx][weekIdx];

      if(!this.selectedElements.find(se => se.id === element.id)) {
        this.selectedElements.push({
          standbyIdx,
          weekIdx,
          ...element
        })
      }

      const unassignments = this.selectedElements.map(se => ({
        assignmentId: se.id,
        standby: se.standbyIdx + 1,
      }));

      //TODO: Error handling
      await api.unassignMultipleStandbys(unassignments);

      this.selectedElements.forEach((assignment) => {
        this.$set(this.standbys[assignment.standbyIdx], assignment.weekIdx, null);
      });
      this.selectedElements = [];
    },
    async reloadData() {
      if(!this.$store.getters['user/isAllowed']('YearplanningStandby.Read')) {
        return;
      }
      const { data } = await api
        .getYearPlanningStandbyData(this.from.week(), this.to.week(), moment().year());

      const standbyLists = ['standby1', 'standby2'];

      this.standbys = standbyLists.map(list =>
        this.weeksIterator.map(day => {
          const standby = data[list].find(s => moment(s.date).isSame(day, 'day'));
          return standby ? this.convertAssignment(standby) : null;
        })
      );
    },
    setAssignmentLoading(standbyIdx, weekIdx, user) {
      if(standbyIdx < 0 || standbyIdx >= this.standbys.length) {
        return;
      }

      const standbyLoadingElements = this.loadingElements[standbyIdx] || [];
      standbyLoadingElements[weekIdx] = user;
      this.$set(this.loadingElements, standbyIdx, standbyLoadingElements);
    },
    setAssignmentLoadingComplete(standbyIdx, weekIdx, newElement) {
      this.$set(this.loadingElements[standbyIdx], weekIdx, null);
      if(!this.loadingElements[standbyIdx].some(l => !!l)) {
        this.$set(this.loadingElements, standbyIdx, null);
      }
      if(!this.loadingElements[0] && !this.loadingElements[1]) {
        this.loadingElements = [];
      }
      this.$set(this.standbys[standbyIdx], weekIdx, newElement);
    },
  },
  async mounted() {
    await this.reloadData();
  },
}
</script>

<style scoped>
.standby1-cell
{
  background-color: #FFE0B2;
}
.standby2-cell
{
  background-color: #FFF3E0;
}
</style>
