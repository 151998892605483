<template>
  <div id="app">
    <router-view :auth="auth" />
  </div>
</template>

<script>
import AuthService from './security/AuthService';

const auth = new AuthService();

export default {
  name: 'App',
  data() {
    return {
      auth,
    };
  },
};
</script>
