<template>
  <v-form>
    <v-text-field
      v-model="name"
      label="Titel"
      required
      :error-messages="nameErrors"
      @input="$v.name.$touch()"
      @blur="$v.name.$touch()"
    />
    <v-text-field
      v-model="number"
      label="Nummer"
      type="number"
      min="0"
      :error-messages="numberErrors"
      @input="$v.number.$touch()"
      @blur="$v.number.$touch()"
    />
    <bearbeiter-selection
      v-if="value._links.assign"
      :department="department"
      v-model="editableUserAssignment"
    />
    <span v-else><strong>Bearbeiter:</strong> {{assignedUserList}}</span>

    <v-radio-group v-model="isTimeparkTask" row>
      <v-radio label="Normales Projekt" :value="false"></v-radio>
      <v-radio label="Timepark Projekt" :value="true"></v-radio>
    </v-radio-group>

    <project-select
      v-if="isTimeparkTask"
      v-model="project"
    />

    <v-text-field
      v-model="location"
      label="Standort"
    />

    <datetime
      type="datetime"
      v-model="plannedStart"
      label="Fälligkeitsdatum"
    />

    <v-textarea
      v-model="description"
      label="Beschreibung"
    />
  </v-form>
</template>

<script>

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import aufgabenparkApi from '../../aufgabenparkApi';

export default {
  mixins: [validationMixin],
  validations: {
    number: {
      isUnique(value) {
        return aufgabenparkApi
          .isEpicTaskNumberInUse(value, this.epicId, this.value.id)
          .then(r => !r.data);
      },
    },
    name: {
      required,
    },
  },
  name: 'EditTaskForm',
  props: {
    value: Object,
    userAssignment: Object,
    department: String,
  },
  methods: {
    emitUpdate(update) {
      this.$emit('input', {...this.value, ...update});
    },
  },
  computed: {
    name: {
      get(){
        return this.value.name;
      },
      set(name) {
        this.emitUpdate({ name });
      },
    },
    number: {
      get() {
        return this.value.number;
      },
      set(number) {
        this.emitUpdate({ number });
      },
    },
    editableUserAssignment: {
      get() {
        return this.userAssignment;
      },
      set(value) {
        this.$emit('update:userAssignment', value);
      },
    },
    isTimeparkTask: {
      get() {
        return this.value.isTimeparkTask;
      },
      set(isTimeparkTask) {
        this.emitUpdate({ isTimeparkTask });
      },
    },
    project: {
      get() {
        return this.value.project;
      },
      set (project) {
        this.emitUpdate({ project });
      },
    },
    location: {
      get() {
        return this.value.location;
      },
      set(location) {
        this.emitUpdate({ location });
      },
    },
    plannedStart: {
      get() {
        return this.value.plannedStart;
      },
      set(plannedStart) {
        this.emitUpdate({ plannedStart });
      },
    },
    description: {
      get() {
        return this.value.description;
      },
      set(description) {
        this.emitUpdate({ description });
      },
    },
    numberErrors() {
      const errors = [];
      if (!this.$v.number.$dirty) {
        return errors;
      }
      if (!this.$v.number.isUnique) {
        errors.push('Diese Nummer wurde bereits vergeben');
      }
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) {
        return errors;
      }
      if (!this.$v.name.required) {
        errors.push('Bitte geben Sie einen Titel ein');
      }
      return errors;
    },
    assignedUserList() {
      return this.value.isAssignedToAllUsers ? 'Alle' : this.value.assignedUsers.map(u => u.name).join(', ');
    },
  },
};
</script>
