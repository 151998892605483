<template>
  <div class="d-flex flex-column">
    <label class="mb-2">Bearbeiter zuweisen</label>
    <v-btn-toggle v-model="allSelected" mandatory>
      <v-btn :value="true">Alle</v-btn>
      <v-btn :value="false">Individuell</v-btn>
    </v-btn-toggle>
    <v-select
      v-if="!allSelected"
      :items="userOptions"
      v-model="selectedUsers"
      item-value="id"
      item-text="name"
      label="Bearbeiter"
      hint="Bearbeiter direkt zuweisen"
      multiple
    />
  </div>
</template>

<script>
export default {
  name: "BearbeiterSelection",
  props: ['department', 'value'],
  watch: {
    department: 'filterSelectionWithDepartment'
  },
  computed: {
    selectedUsers: {
      get() {
        if(!this.value || !this.value.users) {
          return [];
        }
        return this.value.users;
      },
      set(v) {
        this.$emit('input', { allUsers: false, users: v})
      }
    },
    allSelected: {
      get() {
        return this.value ? this.value.allUsers : false;
      },
      set(v) {
        const users = this.value && this.value.users ? this.value.users : [];
        this.$emit('input', { allUsers: v, users })
      }
    },
    userOptions() {
      if (!this.department) {
        return this.$store.state.departments
          .map(d => d.users)
          .reduce((x, y) => x.concat(y), []);
      }

      const department =
        this.$store.state.departments
          .find(x => x.name === this.department);

      if (department) {
        return department.users;
      }

      return [];
    },
  },
  methods: {
    filterSelectionWithDepartment() {
      const userOptionIds = this.userOptions.map(u => u.id);
      this.selectedUsers = this.selectedUsers.filter(u => userOptionIds.includes(u));
    }
  }
}
</script>

<style scoped>

</style>
