export default {
  namespaced: true,
  state: {
    isInitialized: false,
    userId: '',
    name: '',
    email: '',
    department: '',
    roles: [],
    permissions: [],
  },
  getters: {
    isAllowed: (state) => (permission, data) => {
      const matched = state.permissions[permission];
      if(!matched) {
        return false;
      }
      if (matched.type === 'All') {
        return true;
      }
      if (!data) {
        return false;
      }

      const { department, userAssignment, createdBy } = data;
      switch (matched.type) {
        case 'Department':
          return data.ignoreDepartmentRestriction || matched.departments.includes(department) || (matched.departments.length > 0 && department === 'Alle Abteilungen');
        case 'Assigned':
          return userAssignment && (userAssignment.users.includes(state.userId) || (userAssignment.allUsers && (department === state.department || department === 'Alle Abteilungen')));
        case 'Author':
          return createdBy === state.name;
      }
      return false;
    },
    visibleDepartments: (state, getters, rootState) => (permission) => {
      const matched = state.permissions[permission];
      if(!matched) {
        return [];
      }
      switch (matched.type) {
        case 'All':
          return rootState.departments.map(x => x.name);
        case 'Department':
          return [...matched.departments];
      }

      return [state.department];
    },
    canVisit: (state) => (permission, department) => {
      const matched = state.permissions[permission];
      if(!matched) {
        return false;
      }

      if(matched.type === 'Department' && department) {
        return matched.departments.includes(department);
      }

      return true;
    },
  },
  mutations: {
    initialize(state, data) {
      state.userId = data.currentUser.userId;
      state.name = data.currentUser.name;
      state.email = data.currentUser.email;
      state.department = data.currentUser.department;
      state.permissions = data.currentUser.permissions;
      state.roles = data.currentUserRoles;
      state.isInitialized = true;
    },
  },
};
