<template>
  <v-app v-if="isAuthenticated">
    <v-navigation-drawer
      fixed
      v-model="drawer"
      app
      :clipped="$vuetify.breakpoint.mdAndUp"
    >
      <v-toolbar flat class="blue-grey darken-3">
        <v-list class="pa-0">
          <v-list-item>
            <v-list-item-avatar>
              <img :src="loggedInPicture" >
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span class="white--text">{{loggedInName}}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-toolbar>
      <div class="blue-grey darken-4" style="padding: 5px 0px 5px 15px">
        <span class="caption blue--text">{{department}}: {{roles}}</span>
      </div>
      <v-list>
        <v-list-item v-for="item in menuItems" :key="item.text" @click="navigate(item)">
          <v-list-item-action>
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      color="gray lighten-4"
      fixed
      app
      :clipped-left="$vuetify.breakpoint.mdAndUp"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title>
        <router-link to="/">
          <img src="/static/img/aufgabenpark-logo.png" width="180" />
        </router-link>
      </v-app-bar-title>
      <v-spacer />
      <v-progress-linear indeterminate :active="!initialized" absolute bottom/>
      <v-btn icon @click="logout">
        <v-icon>lock</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <confirm-dialog />
      <router-view v-if="initialized"/>
    </v-main>
    <v-footer id="gemeindepark-footer" app class="pa-3">
      <span class="subtitle-2">
        <strong>Version:</strong> {{ appversion }}
      </span>
      <v-spacer></v-spacer>
      <a target="_blank" class="copyright" href="http://www.softwarepark.cc">Softwarepark Services GmbH © {{ new Date().getFullYear() }}</a>
    </v-footer>
  </v-app>
</template>

<script>

import aufgabenparkApi from '../aufgabenparkApi';
export default {
  props: ['auth'],
  async mounted() {
    if (!this.auth.isAuthenticated()) {
      this.auth.login();
      return;
    }

    const { data: version } = await aufgabenparkApi.getVersion();
    this.appversion = version;

    const { data } = await aufgabenparkApi.getSystemSettings();
    this.$store.commit('initialize', data);
    this.$store.commit('user/initialize', data);

    if(this.$store.state.navigationRouteAfterInitialize) {
      await this.$router.push(this.$store.state.navigationRouteAfterInitialize);
      this.$store.commit('clearNavigateAfterInitialize');
    }
    this.initialized = true;

    const { data: products } = await aufgabenparkApi.getTimeparkProducts();
    this.$store.commit('setTimeparkProducts', products);
  },
  methods: {
    logout() {
      this.auth.logout();
      this.isAuthenticated = false;
      this.auth.login();
    },
    navigate(item) {
      this.$router.push({
        path: item.link,
        query: item.params,
      });
    },
  },
  computed: {
    menuItems() {
      const menuItems = [
        { icon: 'trending_up', text: 'Home', link: '/' },
        { icon: 'assignment_ind', text: 'Meine Aufgaben', link: '/MeineAufgaben' },
      ];

      if (this.$store.getters['user/canVisit']('History.Read')){
        menuItems.push({ icon: 'history', text: 'Historie', link: '/Historie' });
      }
      if (this.$store.getters['user/canVisit']('YearplanningTasks.Read')) {
          menuItems.push({ icon: 'shuffle', text: 'Jahresplanung', link: '/Jahresplanung' });
      }

      const departments = this.$store.getters['user/visibleDepartments']('Tasks.Read');
      return menuItems.concat(departments.map(x => ({
        icon: 'person',
        text: x,
        link: `/DepartmentDetails/${x}`
      })));
    },
    department() {
      return this.$store.state.user.department;
    },
    roles() {
      return this.$store.state.user.roles.join(', ');
    },
  },
  data() {
    return {
      isAuthenticated: this.auth.isAuthenticated(),
      loggedInName: this.auth.getLoggedInName(),
      loggedInPicture: this.auth.getLoggedInPicture(),
      drawer: false,
      initialized: false,
      appversion: '',
    };
  },
};
</script>

