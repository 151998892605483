import Vue from 'vue';
import Vuetify from 'vuetify';

import AufgabenparkIcon from '@/icons/aufgabenpark-icon';
import TimeparkIcon from '@/icons/timepark-icon';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
    values: {
      aufgabenpark: {
        component: AufgabenparkIcon,
      },
      timepark: {
        component: TimeparkIcon,
      }
    },
  },
});
