<script>
import moment from 'moment';
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  name: 'UserWorkingTimesChart',
  props: {
    chartData: {
      type: Array,
    },
  },
  watch: {
    chartData() {
      this.setupChart();
    },
  },
  mounted() {
    this.setupChart();
  },
  methods: {
    setupChart() {
      const labels = this.chartData.map(x => x.user);
      const values = this.chartData.map(t => moment.duration(t.totalDuration).asHours());
      const valueLabels = this.chartData.map(t => moment.duration(t.totalDuration).format('hh:mm', {trim: false}));

      const colors = this.chartData.map(() => 'rgba(54, 162, 235, 0.2)');
      const borderColors = this.chartData.map(() => 'rgba(54, 162, 235, 1)');

      const formatNumber = n => (`0${n}`).slice(-2);

      this.renderChart({
        labels,
        minValue: 0,
        datasets: [
          {
            label: 'Working times',
            labels: valueLabels,
            data: values,
            backgroundColor: colors,
            borderColor: borderColors,
            borderWidth: 1,
          },
        ],
      }, {
        scales: {
          yAxes: [{
            ticks: {
              min: 0,
              callback: (value) => {
                const h = formatNumber(parseInt((value * 60) / 60, 10));
                const m = formatNumber(parseInt((value * 60) % 60, 10));

                return `${h}:${m}`;
              },
            },
          }],
        },
        tooltips: {
          callbacks: {
            label: tooltipItems => moment.duration(this.chartData[tooltipItems.index].totalDuration).format('hh:mm', {trim: false}),
          },
        },
      });
    },
  },
};
</script>
