<template>
  <v-form v-if="hasPendingEpicTask">
    <v-card>
      <v-card-title>
        <h2><v-icon>dashboard</v-icon> Neue Aufgabe: {{name}}</h2>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="name"
          label="Titel"
          required
          :error-messages="nameErrors"
          @input="$v.name.$touch()"
          @blur="$v.name.$touch()"
        />
        <v-text-field
          v-model="number"
          label="Nummer"
          type="number"
          min="0"
          :error-messages="numberErrors"
          @input="$v.number.$touch()"
          @blur="$v.number.$touch()"
        />
        <bearbeiter-selection
          v-is-allowed="permissionFor('Tasks.Assign')"
          :department="department"
          v-model="userAssignment"
        />

        <v-radio-group v-model="taskType" row>
          <v-radio label="Normales Projekt" value="normal"></v-radio>
          <v-radio label="Timepark Projekt" value="timepark"></v-radio>
        </v-radio-group>

        <project-select
          v-if="isTimeparkTask"
          v-model="project"
        />

        <v-text-field
          v-model="location"
          label="Standort"
        />

        <label v-if="!!plannedStart">Fälligkeitsdatum:</label>
        <datetime
          type="datetime"
          v-model="plannedStart"
          label="Fälligkeitsdatum"
        />

        <v-textarea
          v-model="description"
          label="Beschreibung"
        />

        <v-divider v-if="hasAttachments"/>
        <attachments v-if="hasAttachments" class="mt-4" :documents="pendingEpicTask.epicTask.documents" :images="pendingEpicTask.epicTask.photos" @documentDeleted="deleteDocument" @photoDeleted="deletePhoto" can-delete/>
      </v-card-text>
      <v-card-actions>
        <v-btn color="default" text @click="doCancel">Abbrechen</v-btn>
        <v-btn
          color="primary"
          @click="doConfirm"
          :disabled="!valid"
        >
          Speichern
        </v-btn>
        <file-upload-button :uploadUrl="fileUploadUrl" @uploaded="filesUploaded"/>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import aufgabenparkApi from '../../aufgabenparkApi';
import ProjectSelect from '../common/ProjectSelect';
import BearbeiterSelection from "@/components/common/BearbeiterSelection";
import FileUploadButton from "@/components/common/FileUploadButton";
import Attachments from "@/components/common/Attachments";


export default {
  mixins: [validationMixin],
  validations: {
    number: {
      isUnique(value) {
        return aufgabenparkApi
          .isEpicTaskNumberInUse(value)
          .then(r => !r.data);
      },
    },
    name: {
      required,
    },
  },
  name: 'AddEpicTask',
  props: [
    'epicId',
    'userOptions',
    'department',
    'isTimeparkEpic',
    'epicProject',
  ],
  mounted() {
    aufgabenparkApi.createPendingEpicTask()
      .then((r) => {
        this.pendingEpicTask = r.data;
      });
    aufgabenparkApi.getNextAvailableEpicTaskNumber()
      .then((r) => {
        this.number = r.data;
      });
  },
  components: {
    Attachments,
    FileUploadButton,
    BearbeiterSelection,
    ProjectSelect,
  },
  methods: {
    async deleteDocument(document) {
      this.pendingEpicTask.epicTask.documents = this.pendingEpicTask.epicTask.documents.filter(x => x.id !== document.id);
    },
    async deletePhoto(photo) {
      this.pendingEpicTask.epicTask.photos = this.pendingEpicTask.epicTask.photos.filter(p => p.externalId !== photo.externalId);
    },
    filesUploaded(uploadedFiles) {
      this.pendingEpicTask.epicTask.documents.push(...uploadedFiles.documents);
      this.pendingEpicTask.epicTask.photos.push(...uploadedFiles.photos);
    },
    doConfirm() {
      const epicTask = {
        pendingEpicTaskId: this.pendingEpicTask.id,
        epicId: this.epicId,
        name: this.name,
        number: this.number,
        location: this.location,
        description: this.description,
        plannedStart: this.plannedStart,
        plannedEnd: this.plannedEnd,
        userAssignment: this.userAssignment,
        project: this.project,
        isTimeparkTask: this.isTimeparkTask,
      };
      this.$emit('confirm', epicTask);
    },
    doCancel() {
      this.$emit('cancel');
    },
    formatDate(date) {
      if (!date) {
        return null;
      }

      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    permissionFor(permission) {
      return { permission: permission, department: this.department, createdBy: this.$store.state.user.name };
    },
  },
  computed: {
    pendingResource() {
      return {
        epic: {
          id: this.pendingEpicTask.epicId,
        },
        task: {
          id: this.pendingEpicTask.epicTaskId,
        },
      };
    },
    hasPendingEpicTask() {
      return !!this.pendingEpicTask;
    },
    fileUploadUrl() {
      return `api/Epics/${this.pendingEpicTask.epicId}/PendingTask/${this.pendingEpicTask.epicTaskId}/Upload`;
    },
    projectSelectionCompleted() {
      if (!this.isTimeparkTask) {
        return true;
      }

      return this.project &&
        this.project.product &&
        this.project.order &&
        this.project.activity;
    },
    valid() {
      return !this.$v.$invalid && this.projectSelectionCompleted;
    },
    numberErrors() {
      const errors = [];
      if (!this.$v.number.$dirty) {
        return errors;
      }
      if (!this.$v.number.isUnique) {
        errors.push('Diese Nummer wurde bereits vergeben');
      }
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) {
        return errors;
      }
      if (!this.$v.name.required) {
        errors.push('Bitte geben Sie einen Titel ein');
      }
      return errors;
    },
    hasAttachments() {
      return this.hasPendingEpicTask &&
        (this.pendingEpicTask.epicTask.photos && this.pendingEpicTask.epicTask.photos.length > 0) ||
        (this.pendingEpicTask.epicTask.documents && this.pendingEpicTask.epicTask.documents.length > 0);
    },
    taskType: {
      get() {
        return this.isTimeparkTask ? 'timepark' : 'normal';
      },
      set(value) {
        this.isTimeparkTask = value === 'timepark';
        if (!this.isTimeparkTask) {
          this.project = null;
        }
      },
    },
  },
  data() {
    return {
      number: null,
      name: null,
      location: null,
      description: null,
      plannedStart: null,
      plannedEnd: null,
      userAssignment: null,
      project: this.epicProject,
      isTimeparkTask: this.isTimeparkEpic,
      pendingEpicTask: null,
      uploadFileDialog: false,
    };
  },
};
</script>
