<template>
  <v-container fluid class="fill-main-container d-flex">

    <v-row v-if="hasBeenFound">
      <v-col class="d-flex flex-column overflow-auto max-fill-height">
          <v-autocomplete
            v-model="userFilter"
            label="Benutzer"
            chips
            deletable-chips
            auto-select-first
            multiple
            @change="userSearch = ''"
            clearable
            :items="userNames"
            :search-input.sync="userSearch"
          />
        <div class="users-grid flex-grow-1">
          <user-tasks-overview
            class="user-task-list"
            v-for="(user, idx) in filteredUsers"
            v-model="filteredUsers[idx]"
            :selectedDepartment="departmentName"
            :key="user.id"
            @change="loadData"
          />
        </div>
      </v-col>
      <v-col cols="3" xl="2" class="max-fill-height">
        <v-expansion-panels multiple :value="[0,1,2]" class="contained-expansion-panels">
          <v-expansion-panel>
            <v-expansion-panel-header color="blue-grey darken-1">
              <h3 class="white--text">Offene Aufgaben</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="fill-panel-content-height">
              <v-spacer class="mt-3 flex-grow-0"/>
              <draggable
                id="unscheduledList"
                class="flex-grow-1"
                v-model="departmentDetails.unassignedScheduledTasks"
                @add="onElementAdd($event, 'unassignedScheduledTasks')"
                :options="draggableOptions"
                :move="canMoveTask"
              >
                <task-preview
                  v-for="(item, i) in departmentDetails.unassignedScheduledTasks"
                  :key="i"
                  :task="item"
                  color-by-name
                  class="mb-2"
                />
              </draggable>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header color="blue-grey darken-1">
              <h3 class="white--text">Aufgabenpool</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="fill-panel-content-height">
              <v-spacer class="mt-3 flex-grow-0"/>
              <draggable
                id="poolList"
                v-model="departmentDetails.pool"
                class="flex-grow-1"
                @add="onPoolElementAdd"
                :options="draggableOptions"
                :move="canMoveTask"
              >
                <task-preview
                  v-for="(item, i) in departmentDetails.pool"
                  :key="i"
                  :task="item"
                  color-by-name
                  class="mb-2"
                />
              </draggable>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header color="blue-grey darken-1">
              <h3 class="white--text">Daueraufgaben</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="fill-panel-content-height">
              <v-spacer class="mt-3 flex-grow-0"/>
              <draggable
                id="activityList"
                v-model="departmentDetails.activities"
                class="flex-grow-1"
                @add="onElementAdd($event, 'activities')"
                :options="draggableActivitiesOptions"
                :move="canMoveTask"
              >
                <v-spacer class="mt-3"/>
                <task-preview
                  v-for="(item, i) in departmentDetails.activities"
                  :key="i"
                  :task="item"
                  class="mb-2"
                  is-draggable
                />
              </draggable>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import draggable from 'vuedraggable';

import aufgabenparkApi from '../../aufgabenparkApi';
import apiClient from "@/aufgabenparkApi/apiClient";
import UserTasksOverview from './UserTasksOverview';
import TaskPreview from '@/components/aufgaben/TaskPreview';

export default {
  name: 'DepartmentDetails',
  watch: {
    departmentName: 'loadData'
  },
  components: {
    UserTasksOverview,
    draggable,
    TaskPreview
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      const { data } = await aufgabenparkApi.getDepartmentDetails(this.departmentName);
      this.departmentDetails = data;
    },
    async onPoolElementAdd(event) {
      const newElement = this.departmentDetails.pool[event.newIndex];
      if (newElement._links.moveToPool) {
        await apiClient.put(newElement._links.moveToPool.href, JSON.stringify(this.departmentName), { headers: { 'Content-Type': 'application/json' }});
        await this.loadData();
      }
    },
    async onElementAdd(event, list) {
      const newElement = this.departmentDetails[list][event.newIndex];

      if (event.from.id === 'poolList' && newElement._links.removeTaskFromPool) {
        await apiClient.put(newElement._links.removeTaskFromPool.href);
      } else if(newElement._links.unassign) {
        await apiClient.put(newElement._links.unassign.href);
      }
      await this.loadData();
    },
    canMoveTask(event) {
      const links = event.draggedContext.element._links;
      return !!(links.assign || links.removeTaskFromPool || links.moveToPool);
    }
  },
  computed: {
    userNames() {
      return this.departmentDetails && this.departmentDetails.users
        ? this.departmentDetails.users.map(u => u.user)
        : [];
    },
    draggableActivitiesOptions() {
      return {
        group: 'activities',
      };
    },
    draggableOptions() {
      return {
        group: 'tasks',
      };
    },
    filteredUsers() {
      if (!this.departmentDetails || !this.departmentDetails.users) {
        return [];
      }

      return this.userFilter.length > 0
        ? this.departmentDetails.users.filter(x => this.userFilter.includes(x.user))
        : this.departmentDetails.users;
    },
    departmentName() {
      return this.$route.params.department;
    },
    hasBeenFound() {
      return this.departmentDetails !== null;
    },
  },
  data: () => ({
    userFilter: [],
    userSearch: null,
    departmentDetails: null,
  }),
};
</script>
<style lang="scss">
.users-grid{
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-rows: max-content;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  .user-task-list {
    min-height: 250px;
    max-height: 30vh;
  }
}
.fill-panel-content-height > .v-expansion-panel-content__wrap {
  display: flex;
  flex-direction: column;
}
</style>
