// Needs to be the first thing we load
import 'babel-polyfill';
import { Settings } from 'luxon';
import { Plugin } from 'vue-fragment';
// The Vue build version to load with the `import` command
import Chart from 'chart.js';
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import VueProgressiveImage from 'vue-progressive-image';
import moment from 'moment';
import setupMomentDurationFormat from 'moment-duration-format';
import draggable from 'vuedraggable';
import App from './App';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import dialogs from '@/dialogs'
import DateTimePicker from '@/components/common/DateTimePicker';

import './styles/index.scss'
import '@/filters';
import '@/config';
import '@/directives/is-allowed-directive';

setupMomentDurationFormat(moment);

Chart.defaults.global.defaultFontSize = 18;

Settings.defaultLocale = 'de';
moment.locale('de');
Vue.use(Plugin);
Vue.use(dialogs);

Vue.config.productionTip = false;

Vue.use(VueProgressiveImage);

Vue.component('draggable', draggable);
Vue.component('datetime', DateTimePicker);

/* eslint-disable no-new */

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')

