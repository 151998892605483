import Vue from 'vue';

import AufgabeDetails from "@/components/aufgaben/AufgabeDetails";
import BearbeiterSelection from "@/components/common/BearbeiterSelection";
import Comments from "@/components/common/Comments";
import FileUploadButton from "@/components/common/FileUploadButton";
import ProjectSelect from '@/components/common/ProjectSelect';
import TaskWorkingTimes from "@/components/common/TaskWorkingTimes";
import ConfirmButton from "@/components/common/ConfirmButton";

Vue.component('aufgabe-details', AufgabeDetails);
Vue.component('bearbeiter-selection', BearbeiterSelection);
Vue.component('comments', Comments);
Vue.component('confirm-button', ConfirmButton);
Vue.component('file-upload-button', FileUploadButton);
Vue.component('project-select', ProjectSelect);
Vue.component('task-working-times', TaskWorkingTimes);
