<template>
  <div>
    <div
      class="d-flex justify-space-between align-center"
      :class="baseClass"
      :style="taskStyle"
      @click.shift.prevent="select"
      @click.ctrl.prevent="select"
      @click.exact="showEdit"
      :draggable="canDrag"
      @dragstart="startDrag"
      @dragend="endDrag"
      @contextmenu.prevent="openMenu"
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
        <span v-on="on" class="text-truncate">
          {{epicName}}
        </span>
        </template>
        <span>{{epicName}}</span>
      </v-tooltip>

      <span class="assigned-epic-task-icons">
        <v-icon v-if="hasAttachment">attachment</v-icon>
        <span v-if="hasStatusIcon">
          <v-icon :color="statusIconColor">{{statusIcon}}</v-icon>
        </span>
      </span>
      <v-overlay absolute :value="loading">
        <v-progress-circular indeterminate />
      </v-overlay>
    </div>
  <v-menu
    v-model="showMenu"
    :position-x="menuX"
    :position-y="menuY"
    absolute
    offset-y>
    <v-list>
      <v-list-item v-if="isAssigned && canBeAssigned" @click="$emit('copy')">
        <v-icon>content_copy</v-icon>
        <v-list-item-title>Kopieren</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="hasLink('delete')" @click="$emit('delete', false)">
        <v-icon>delete</v-icon>
        <v-list-item-title>Löschen</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
  <v-dialog v-model="showEditDialog" :persistent="true" max-width="850px">
    <edit-year-planning-task
      :assignmentId="assignmentId"
      :aufgabe="aufgabe"
      @cancel="showEditDialog=false"
      @updated="saveAssignedUserTask"
      @deleted="onDeleted"
    />
  </v-dialog>
  </div>
</template>

<script>

import ColorHash from 'color-hash';
import moment from 'moment';

import util from '@/util';

import EditYearPlanningTask from './EditYearPlanningTask';
const colorHash = new ColorHash();

export default {
  components: {
    EditYearPlanningTask,
  },
  props: {
    aufgabe: Object,
    assignmentId: String,
    assignmentDate: Object,
    preview: Boolean,
    draggable: Boolean,
    selected: Boolean,
    loading: Boolean,
    disableDrop: Boolean,
  },
  data() {
    return {
      showMenu: false,
      menuX: 0,
      menuY: 0,
      showEditDialog: false,
      isDragging: false,
    };
  },
  computed: {
    canDrag() {
      return this.draggable && this.canBeAssigned;
    },
    canBeAssigned() {
      return this.hasLink('assign');
    },
    hasAttachment() {
      return (this.aufgabe.photos && this.aufgabe.photos.length > 0) ||
        (this.aufgabe.documents && this.aufgabe.documents.length > 0);
    },
    taskStyle() {
      const color = this.aufgabe ? colorHash.hex(this.aufgabe.name) : 'black';
      return `border-left: 10px solid ${color};`;
    },
    baseClass() {
      let cssClass = 'assigned-epic-task-base';
      if (this.selected) {
        cssClass += ' assigned-epic-task-selected';
      }
      if (this.aufgabe.doNotSynchronize) {
        cssClass += ' assigned-epic-task-no-sync';
      }
      if (this.preview) {
        cssClass += ' assigned-epic-task-preview';
      }
      if(!this.canDrag) {
        cssClass += ' assigned-epic-task-disabled';
      }
      if(this.preview && this.disableDrop) {
        cssClass += ' assigned-epic-task-no-drop';
      }
      if (this.isDragging && this.disableDrop) {
        cssClass += ' cursor-no-drop';
      }

      return cssClass;
    },
    epicName() {
      return this.aufgabe.name;
    },
    isAssigned() {
      return this.assignmentId && this.assignmentDate;
    },
    hasStatusIcon() {
      return !this.preview && !this.loading && (!this.aufgabe.doNotSynchronize || !this.isAssigned) ;
    },
    statusIcon() {
      if(!this.isAssigned) {
        return util.getStatusIcon(this.aufgabe.status)
      }
      if(this.isPending) {
        return 'hourglass_empty';
      }
      if(this.isPassed) {
        return 'block';
      }
      return this.isScheduled ? 'history' : '';
    },
    statusIconColor() {
      if(!this.isAssigned) {
        return null;
      }
      if(this.isPending) {
        return '#EF6C00';
      }
      if(this.isPassed) {
        return '#BF360C';
      }
      return this.isScheduled ? '#43A047' : null;
    },
    isPassed() {
      const startOfCurrentWeek = moment().startOf('week');
      return startOfCurrentWeek.isAfter(this.assignmentDate);
    },
    isScheduled() {
      const startOfNextWeek = moment().startOf('week').add(1, 'w');
      return this.assignmentDate.isAfter(startOfNextWeek);
    },
    isPending() {
      return !this.isPassed && !this.isScheduled;
    },
  },
  methods: {
    hasLink(link) {
      return this.aufgabe && this.aufgabe._links && this.aufgabe._links[link];
    },
    openMenu(e) {
      const canDelete = this.hasLink('delete');
      if(this.preview || this.loading || (!this.canBeAssigned && !canDelete)) {
        return;
      }
      this.menuX = e.clientX;
      this.menuY = e.clientY;
      this.showMenu = true;
    },
    startDrag(e) {
      // eslint-disable-next-line no-param-reassign
      e.dataTransfer.dropEffect = 'move';
      // eslint-disable-next-line no-param-reassign
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('group', 'taskAssignment');
      this.isDragging = true;
      this.$emit('dragging', e);
    },
    endDrag() {
      this.isDragging = false;
    },
    async saveAssignedUserTask(assignment) {
      this.showEditDialog = false;
      this.$emit('updated', assignment);
    },
    showEdit() {
      if (!this.preview && !this.loading) {
        this.showEditDialog = true;
      }
    },
    select() {
      if (this.hasLink('assign')) {
        this.$emit('selected');
      }
    },
    onDeleted() {
      this.showEditDialog = false;
      this.$emit('deleted');
    },
  },
};
</script>

<style lang="scss">
  @import "src/styles/variables";
  .assigned-epic-task-base {
    border: 1px solid #dddcdc;
    border-radius: 5px;
    height: 100%;
    padding: 0 5%;
    font-weight: 500;
    cursor: pointer;
    min-height: 40px;
    position: relative;
    background-color: #ffffff;
  }
  .assigned-epic-task-selected {
    background-color: #dbf5ff !important;
  }
  .assigned-epic-task-no-sync {
    background-color: #ffe09c;
  }
  .assigned-epic-task-preview {
    opacity: 0.4;
  }
  .assigned-epic-task-disabled {
    background-color: map-get($grey, 'lighten-4');
  }
  .assigned-epic-task-no-drop {
    cursor: no-drop;
    background-color: map-get($red, 'lighten-4');
  }
  .assigned-epic-task-icons {
    margin-left: 0.5em;
    flex: 1 0 auto;
    display: flex;
    justify-content: flex-end;
  }
  .cursor-no-drop {
    cursor: no-drop;
  }
</style>
