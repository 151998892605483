<template>
  <v-form v-if="hasPendingEpicTask">
    <v-card>
      <v-card-title>
        <h2><v-icon>dashboard</v-icon> Neue Aufgabe: {{name}}</h2>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="name"
          label="Titel"
          required
          :error-messages="nameErrors"
          @input="$v.name.$touch()"
          @blur="$v.name.$touch()"
        />
        <v-text-field
          v-model="number"
          label="Nummer"
          type="number"
          min="0"
          :error-messages="numberErrors"
          @input="$v.number.$touch()"
          @blur="$v.number.$touch()"
        />
        <v-btn-toggle v-model="taskTypeIndex" mandatory>
          <v-btn text :value="0">
            Normale Aufgabe
          </v-btn>
          <v-btn text :value="1">
            Aufgabepool
          </v-btn>
          <v-btn text :value="2">
            Daueraufgabe
          </v-btn>
        </v-btn-toggle>
        <v-select
          v-if="!continuingActivity"
          :items="departments"
          v-model="selectedDepartment"
          placeholder="Abteilung"
          hint="Abteilung"
          :required="!!aufgabepool"
          :error-messages="selectedDepartmentErrors"
          @change="$v.selectedDepartment.$touch()"
          @blur="$v.selectedDepartment.$touch()"
        />
        <bearbeiter-selection
          v-if="isNormalTask"
          v-is-allowed="permissionFor('Tasks.Assign')"
          :department="bearbeiterDepartmentFilter"
          v-model="userAssignment"
        />
        <v-radio-group v-model="taskType" row>
          <v-radio label="Normales Projekt" value="normal"></v-radio>
          <v-radio label="Timepark Projekt" value="timepark"></v-radio>
        </v-radio-group>

        <project-select
          v-if="isTimeparkTask"
          v-model="project"
        />

        <v-text-field
          v-model="location"
          label="Standort"
        />

        <label v-if="!!plannedStart">Fälligkeitsdatum:</label>
        <datetime
          type="datetime"
          v-model="plannedStart"
          label="Fälligkeitsdatum"
        />

        <v-textarea
          v-model="description"
          label="Beschreibung"
        />

        <v-divider v-if="hasAttachments"/>
        <attachments v-if="hasAttachments" class="mt-4" :documents="pendingEpicTask.epicTask.documents" :images="pendingEpicTask.epicTask.photos" @documentDeleted="deleteDocument" @photoDeleted="deletePhoto" can-delete/>
      </v-card-text>
      <v-card-actions>
        <v-btn color="default" text @click="doCancel">Abbrechen</v-btn>
        <v-btn color="primary" @click="doConfirm" :disabled="!valid">Speichern</v-btn>
        <file-upload-button :uploadUrl="fileUploadUrl"  @uploaded="filesUploaded"/>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>

import { validationMixin } from 'vuelidate';
import {required, requiredIf} from 'vuelidate/lib/validators';
import aufgabenparkApi from '../../aufgabenparkApi';
import ProjectSelect from '../common/ProjectSelect';
import BearbeiterSelection from "@/components/common/BearbeiterSelection";
import FileUploadButton from "@/components/common/FileUploadButton";
import Attachments from "@/components/common/Attachments";


export default {
  mixins: [validationMixin],
  validations: {
    number: {
      isUnique(value) {
        return aufgabenparkApi
          .isEpicTaskNumberInUse(value)
          .then(r => !r.data);
      },
    },
    name: {
      required,
    },
    selectedDepartment: {
      required: requiredIf(function () {
        return this.aufgabepool || (this.isNormalTask && this.isAssignedAllUsers);
      }),
    },
  },
  name: 'AddTask',
  components: {
    Attachments,
    FileUploadButton,
    BearbeiterSelection,
    ProjectSelect,
  },
  props: {
    department: String,
  },
  watch: {
    department(val) {
      this.selectedDepartment = val;
    },
  },
  mounted() {
    aufgabenparkApi.createPendingEpicTask()
      .then((r) => {
        this.pendingEpicTask = r.data;
      });
    aufgabenparkApi.getNextAvailableEpicTaskNumber()
      .then((r) => {
        this.number = r.data;
      });

    // GEMEINDE-455: Anlagemitarbeiter should be the default selection for Tenant "Abwasserverband".
    // Since no other tenants are using that department name this is the quick-and-dirty hack
    // until we have a proper concept for tenant-specific changes
    const anlagemitarbeiterDepartment = this.$store.state.departments.filter(d => d.name === 'Anlage-Mitarbeiter');

    if (anlagemitarbeiterDepartment.length === 1) {
      this.selectedDepartment = anlagemitarbeiterDepartment[0].name;
      return;
    }

    this.selectedDepartment = this.department || 'Alle Abteilungen';
  },
  methods: {
    async deleteDocument(document) {
      this.pendingEpicTask.epicTask.documents = this.pendingEpicTask.epicTask.documents.filter(x => x.id !== document.id);
    },
    async deletePhoto(photo) {
      this.pendingEpicTask.epicTask.photos = this.pendingEpicTask.epicTask.photos.filter(p => p.externalId !== photo.externalId);
    },
    filesUploaded(uploadedFiles) {
      this.pendingEpicTask.epicTask.documents.push(...uploadedFiles.documents);
      this.pendingEpicTask.epicTask.photos.push(...uploadedFiles.photos);
    },
    doConfirm() {
      const task = {
        pendingEpicTaskId: this.pendingEpicTask.id,
        name: this.name,
        number: this.number,
        location: this.location,
        description: this.description,
        plannedStart: this.plannedStart,
        plannedEnd: this.plannedEnd,
        department: this.selectedDepartment,
        project: this.project,
        isTimeparkTask: this.isTimeparkTask,
        aufgabepool: this.aufgabepool,
        continuingActivity: this.continuingActivity,
        pendingActivityTaskId: this.pendingEpicTask.epicTaskId,
      };
      if(!this.continuingActivity) {
        task.userAssignment = this.userAssignment;
      }

      this.$emit('confirm', task);
    },
    doCancel() {
      this.$emit('cancel');
    },
    formatDate(date) {
      if (!date) {
        return null;
      }

      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    permissionFor(permission) {
      return { permission: permission, department: this.department, createdBy: this.$store.state.user.name };
    },
  },
  computed: {
    pendingResource() {
      return {
        epic: {
          id: this.pendingEpicTask.epicId,
        },
        task: {
          id: this.pendingEpicTask.epicTaskId,
        },
      };
    },
    fileUploadUrl() {
      return `api/Epics/${this.pendingEpicTask.epicId}/PendingTask/${this.pendingEpicTask.epicTaskId}/Upload`;
    },
    isAssignedAllUsers() {
      return this.userAssignment ? this.userAssignment.allUsers : false;
    },
    isNormalTask() {
      return this.taskTypeIndex === 0;
    },
    aufgabepool() {
      return this.taskTypeIndex === 1;
    },
    continuingActivity() {
      return this.taskTypeIndex === 2;
    },
    projectSelectionCompleted() {
      if (!this.isTimeparkTask) {
        return true;
      }

      return this.project &&
        this.project.product &&
        this.project.order &&
        this.project.activity;
    },
    valid() {
      return !this.$v.$invalid && this.projectSelectionCompleted;
    },
    numberErrors() {
      const errors = [];
      if (!this.$v.number.$dirty) {
        return errors;
      }
      if (!this.$v.number.isUnique) {
        errors.push('Diese Nummer wurde bereits vergeben');
      }
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) {
        return errors;
      }
      if (!this.$v.name.required) {
        errors.push('Bitte geben Sie einen Titel ein');
      }
      return errors;
    },
    selectedDepartmentErrors() {
      const errors = [];
      if (!this.$v.selectedDepartment.$dirty) {
        return errors;
      }
      if (!this.$v.selectedDepartment.required) {
        errors.push('Bitte wählen Sie eine Abteilung aus');
      }
      return errors;
    },
    hasAttachments() {
      return this.hasPendingEpicTask &&
        (this.pendingEpicTask.epicTask.photos && this.pendingEpicTask.epicTask.photos.length > 0) ||
        (this.pendingEpicTask.epicTask.documents && this.pendingEpicTask.epicTask.documents.length > 0);
    },
    hasPendingEpicTask() {
      return !!this.pendingEpicTask;
    },
    taskType: {
      get() {
        return this.isTimeparkTask ? 'timepark' : 'normal';
      },
      set(value) {
        this.isTimeparkTask = value === 'timepark';
        if (!this.isTimeparkTask) {
          this.project = null;
        }
      },
    },
    bearbeiterDepartmentFilter() {
      if (this.continuingActivity) {
        return null;
      }

      return this.selectedDepartment;
    },
    departments() {
      return this.$store.getters['user/visibleDepartments']('Tasks.Create');
    }
  },
  data() {
    return {
      number: null,
      selectedDepartment: this.department|| 'Alle Abteilungen',
      name: null,
      location: null,
      description: null,
      plannedStart: null,
      plannedEnd: null,
      userAssignment: null,
      project: null,
      isTimeparkTask: false,
      pendingEpicTask: null,
      uploadFileDialog: false,
      taskTypeIndex: 0,
    };
  },
};
</script>
