<template>
  <div @mouseleave="stopHover">
    <div v-for="(day, dayIdx) in daysIterator" :key="'assignments'+dayIdx" class="grid-wrapper">
      <div
        v-for="(user, userIdx) in users"
        :key="user.id + '-'+dayIdx"
        class="year-planning-cell"
        :style="getRowStyleForDay(day)"
        @drop="drop($event, user, dayIdx)"
        @dragenter.prevent="startHover(userIdx, dayIdx, $event)"
        @dragover.prevent="updateDropEvent(userIdx, $event)"
        @mouseover="startHover(userIdx, dayIdx)"
        @click.prevent="paste(user, dayIdx)"
      >
        <year-planning-epic-task
          v-if="user.assignments[dayIdx]"
          :aufgabe="user.assignments[dayIdx].epic"
          :assignmentId="user.assignments[dayIdx].id"
          :assignmentDate="day"
          :selected="isTaskAssignmentSelected(user.assignments[dayIdx])"
          draggable
          @dragging="drag(userIdx, dayIdx)"
          @dragend.native="stopDrag"
          @selected="select(userIdx, dayIdx)"
          @copy="copy(userIdx, dayIdx)"
          @updated="updated($event, user, dayIdx)"
          @delete="deleteAssignment(user, dayIdx, $event)"
          :disable-drop="!canDropCurrent"
        />
        <year-planning-epic-task
          v-else-if="isLoadingAssignment(user, dayIdx)"
          :aufgabe="loadingElements[user.id][dayIdx]"
          loading
        />
        <year-planning-epic-task
          v-else-if="isAssignmentHover(userIdx, dayIdx)"
          :aufgabe="previewAssignment(userIdx, dayIdx)"
          preview
          :disable-drop="!canDrop(userIdx)"
        />
        <div v-else></div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import YearPlanningEpicTask from "@/components/yearPlanning/YearPlanningEpicTask";
import aufgabenparkApi from '@/aufgabenparkApi';

const api = aufgabenparkApi.yearplanning;

export default {
  props: {
    department: String,
    from: Object,
    to: Object,
    dragEpicTask: Object,
  },
  components: {
    YearPlanningEpicTask,
  },
  data:() => ({
    users: [],
    holidays: [],
    hoverElement: null,
    draggingElement: null,
    copyElement: null,
    selectedElements: [],
    loadingElements: {},
  }),

  computed: {
    totalNumberOfDays() {
      return this.to.diff(this.from, 'days') + 1;
    },
    daysIterator() {
      return new Array(this.totalNumberOfDays)
        .fill({})
        .map((_, i) => this.from.clone().add(i, 'days'));
    },
    hoverReferenceElement() {
      return this.draggingElement || this.copyElement;
    },
    hoverAssignments() {
      if (this.hoverReferenceElement) {
        return this.selectedElements.length === 0 ?
          [this.hoverReferenceElement] :
          this.selectedElements;
      }
      return [];
    },
    canDropCurrent() {
      if (!this.hoverElement) {
        return false;
      }
      return this.canDrop(this.hoverElement.userIdx);
    },
  },

  watch: {
    department: 'reloadData',
    from: 'reloadData',
    to: 'reloadData',
  },

  methods: {
    canDrop(userIdx) {
      const permission = this.$store.state.user.permissions['YearplanningTasks.Assign'];
      return permission.type !== 'Department' || permission.departments.includes(this.users[userIdx].department);
    },
    getRowStyleForDay(day) {
      if (!day) {
        return 'background-color: white';
      }

      if (this.holidays.find(h => moment(h).isSame(day, 'day'))) {
        return 'background-color: #f1f1f1';
      }

      const saturday = 6;
      const sunday = 0;
      const isWeekend = day.day() === saturday || day.day() === sunday;
      return `background-color: ${(isWeekend ? '#f1f1f1' : 'white')}`;
    },
    getIsoForDayIdx(dayIdx) {
      return this.from.clone().add(dayIdx, 'days').toISOString();
    },
    getDayIdxForDate(date) {
      return moment(date).diff(this.from, 'days');
    },
    updated(epicAssignment, user, dayIdx) {
      this.$set(user.assignments, dayIdx, epicAssignment);
    },
    async deleteAssignment(user, dayIdx, ignoreSelection) {
      this.selectedElements = this.selectedElements
        .filter(a => a.id !== user.assignments[dayIdx].id);

      const unassignments = [user.assignments[dayIdx].id];

      this.$set(user.assignments, dayIdx, null);

      if (!ignoreSelection) {
        this.selectedElements.forEach((assignment) => {
          this.$set(assignment.user.assignments, assignment.dayIdx, null);
          unassignments.push(assignment.id);
        });
        this.selectedElements = [];
      }

      // TODO: Error Handling
      await api.unassignMultipleUserYearPlanningAssignments(unassignments);
    },
    updateDropEvent(userIdx, event) {
      if (this.canDrop(userIdx)) {
        // eslint-disable-next-line no-param-reassign
        event.dataTransfer.dropEffect = "move";
      } else {
        // eslint-disable-next-line no-param-reassign
        event.dataTransfer.dropEffect = "none";
      }
    },
    startHover(userIdx, dayIdx, event) {
      if(event) {
        this.updateDropEvent(userIdx, event);
      }
      this.hoverElement = {
        userIdx,
        dayIdx
      };
    },
    stopHover() {
      this.hoverElement = null;
    },
    isLoadingAssignment(user, dayIdx) {
      return this.loadingElements[user.id] && this.loadingElements[user.id][dayIdx];
    },
    isAssignmentHover(userIdx, dayIdx) {
      const isUserHover = this.hoverElement &&
        this.hoverElement.userIdx === userIdx &&
        this.hoverElement.dayIdx === dayIdx;

      if (this.dragEpicTask) {
        return isUserHover;
      }
      return (isUserHover && this.hoverReferenceElement) ||
        (this.hoverElement &&
          this.hoverReferenceElement &&
          this.selectedElements.some(se =>
            this.hoverReferenceElement.userIdx - se.userIdx === this.hoverElement.userIdx - userIdx &&
            this.hoverReferenceElement.dayIdx - se.dayIdx === this.hoverElement.dayIdx - dayIdx
          ));
    },
    previewAssignment(userIdx, dayIdx) {
      const userOffset = this.hoverElement.userIdx - userIdx;
      const dayOffset = this.hoverElement.dayIdx - dayIdx;
      const preview = this.hoverAssignments.find(a =>
        this.hoverReferenceElement.userIdx - a.userIdx === userOffset &&
        this.hoverReferenceElement.dayIdx - a.dayIdx === dayOffset
      );

      return this.dragEpicTask ? this.dragEpicTask : preview.epic;
    },
    moveAssignment(assignment, toUser, toDayIdx){
      if(toUser) {
        this.$set(toUser.assignments, toDayIdx, { id: assignment.id, epic: assignment.epic });
      }
      this.$set(assignment.user.assignments, assignment.dayIdx, null);
    },
    applyEpicReassignments(user, dayIdx, copy) {
      const reassignments = [
        {
          assignmentId: this.hoverReferenceElement.id,
          to: {
            userId: user.id,
            date: this.getIsoForDayIdx(dayIdx),
          },
        },
      ];

      this.selectedElements.filter(s => s.id !== this.hoverReferenceElement.id)
        .forEach(assignment => {
          const userOffset = this.hoverElement.userIdx + (assignment.userIdx - this.hoverReferenceElement.userIdx);
          const dayOffset = this.hoverElement.dayIdx + (assignment.dayIdx - this.hoverReferenceElement.dayIdx);
          const assignmentUser = this.users[userOffset];

          reassignments.push({
            assignmentId: assignment.id,
            to: {
              userId: assignmentUser ? assignmentUser.id : undefined,
              date: this.getIsoForDayIdx(dayOffset),
            },
          });

          if(copy) {
            this.setAssignmentLoading(assignmentUser, dayOffset, assignment.epic);
          } else {
            this.moveAssignment(assignment, assignmentUser, dayOffset);
          }
      });

      if(copy) {
        this.setAssignmentLoading(user, dayIdx, this.hoverReferenceElement.epic);
      } else {
        this.moveAssignment(this.hoverReferenceElement, user, dayIdx);
      }

      return reassignments;
    },
    copy(userIdx, dayIdx) {
      const assignment = this.users[userIdx].assignments[dayIdx];
      this.copyElement = {
        ...assignment,
        userIdx,
        dayIdx,
        user: this.users[userIdx],
      };
    },
    async paste(user, dayIdx) {
      if (!this.copyElement) {
        return;
      }

      const reassignments = this.applyEpicReassignments(user, dayIdx, true);

      this.selectedElements = [];
      this.copyElement = null;

      // TODO: Error Handling
      const { data } = await api.reassignMultipleUserEpicTasks({
        reassignments,
        copy: true,
      });

      for(const result of data) {
        const assignmentUser = this.users.find(u => u.id === result.id);
        for(const assignment of result.assignments) {
          const assignmentDayIdx = this.getDayIdxForDate(assignment.date);
          this.setAssignmentLoadingComplete(assignmentUser, assignmentDayIdx, { id: assignment.id, epic: assignment.assignedEpic });
        }
      }
    },
    drag(userIdx, dayIdx) {
      this.draggingElement = {
        userIdx,
        dayIdx,
        ...this.users[userIdx].assignments[dayIdx],
        user: this.users[userIdx],
      };

      if (!this.selectedElements.find(a => a.id === this.draggingElement.id)) {
        this.selectedElements = [];
      }
    },
    stopDrag() {
      this.draggingElement = null;
      this.stopHover();
    },
    async drop(e, user, dayIdx) {
      if (e.dataTransfer.getData('group') !== 'taskAssignment') {
        return;
      }

      const planningTask = e.dataTransfer.getData('panningTask');
      if (planningTask) {
        const epic = JSON.parse(planningTask);
        this.$emit('update:dragEpicTask', null);
        this.setAssignmentLoading(user, dayIdx, epic);

        // TODO: Error Handling
        const { data } = await api.assignYearPlanningEpicTaskToUser({
          userId: user.id,
          epicId: epic.id,
          department: epic.department,
          date: this.getIsoForDayIdx(dayIdx),
        });

        this.setAssignmentLoadingComplete(user, dayIdx, { id: data.id, epic: data.assignedEpic });
      } else if(this.users[this.draggingElement.userIdx].id !== user.id || this.draggingElement.dayIdx !== dayIdx) {
        const reassignments = this.applyEpicReassignments(user, dayIdx, false);

        this.selectedElements = [];

        // TODO: Error Handling
        await api.reassignMultipleUserEpicTasks({
          reassignments,
          copy: false,
        });
      }
      this.draggingElement = null;
    },
    select(userIdx, dayIdx) {
      const user = this.users[userIdx];
      const assignment = user.assignments[dayIdx];
      if(this.isLoadingAssignment(user, dayIdx)) {
        return;
      }
      if (!this.isTaskAssignmentSelected(assignment)) {
        this.selectedElements.push({
          ...assignment,
          userIdx,
          dayIdx,
          user,
        });
      } else {
        this.selectedElements = this.selectedElements
          .filter(se => se.id !== assignment.id);
      }
    },
    isTaskAssignmentSelected(assignment) {
      return assignment && !!this.selectedElements.find(a => a.id === assignment.id);
    },
    async reloadData() {
      const { data } = await api
        .getYearPlanningEpicAssigmentData(this.department,
          this.from.week(), this.to.week(), moment().year());

      this.holidays = data.holidays;
      this.users = data.users.map((u, idx) =>
        ({
          ...u,
          idx,
          assignments: this.daysIterator
            .map(day => {
              const assignment = u.assignments.find(ua => moment(ua.date).isSame(day, 'day'));
              return assignment ? { id: assignment.id, epic: assignment.assignedEpic } : null;
            }),
        }),
      );
    },
    setAssignmentLoading(user, dayIdx, epic) {
      if(!user) {
        return;
      }
      const userLoadingElements = this.loadingElements[user.id] || [];
      userLoadingElements[dayIdx] = epic;
      this.$set(this.loadingElements, user.id, userLoadingElements);
    },
    setAssignmentLoadingComplete(user, dayIdx, newElement) {
      this.$set(this.loadingElements[user.id], dayIdx, null);
      if(!this.loadingElements[user.id].some(l => !!l)) {
        this.$delete(this.loadingElements, user.id);
      }

      this.$set(user.assignments, dayIdx, newElement);
    },
  },
  async mounted() {
    await this.reloadData();
  },
}
</script>
