<template>
  <div>
    <h4>{{user}}</h4>
    <v-data-table
      :headers="headers"
      :items="numberedWorkingTimes"
      hide-default-footer
      item-key="index"
    >
      <template v-slot:header="props">
        <tr>
          <th
            v-for="header in props.headers"
            :key="header.text"
            class="column"
            :width="header.width"
            >
              {{ header.text }}
          </th>
        </tr>
      </template>
      <template v-slot:item="props">
        <tr>
          <td class="text-xs-center">{{ formatDateWithTime(props.item.start) }}</td>
          <td class="text-xs-center">{{ formatDateWithTime(props.item.end) }}</td>
          <td class="text-xs-center">
            <span v-if="props.item.project">{{props.item.project.product}} &rarr; {{props.item.project.order}} &rarr; {{props.item.project.activity}}</span>
          </td>
          <td class="text-xs-center">{{getMachines(props.item)}}</td>
          <td><v-icon :color="getSyncStatusColor(props.item)">{{getSyncStatusIcon(props.item)}}</v-icon></td>
        </tr>
      </template>
      <template v-slot:footer>
        <tr>
          <td>
            <strong>Total:</strong>
          </td>
          <td class="text-xs-center">
            {{totalTimeSpan}}
          </td>
          <td colspan="3" />
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import moment from 'moment';

export default {
  name: 'UserWorkingTimesTable',
  props: {
    userWorkingTimes: {
      type: Object,
    },
  },
  computed: {
    user() {
      return this.userWorkingTimes ? this.userWorkingTimes.user : '';
    },
    workingTimes() {
      return this.userWorkingTimes ? this.userWorkingTimes.workingTimes : [];
    },
    totalTimeSpan() {
      return this.userWorkingTimes ? moment.duration(this.userWorkingTimes.totalDuration).format('hh:mm', {trim: false}) : '';
    },
    numberedWorkingTimes() {
      return this.workingTimes.map((w, i) => ({
        index: i,
        ...w,
      }));
    },
  },
  methods: {
    getMachines(workingTime) {
      const machines = workingTime.machines;

      if (!machines) {
        return '';
      }

      return machines.join(', ');
    },
    formatDateWithTime(date) {
      if (!date) {
        return '';
      }

      return moment(date).format('MMMM Do YYYY HH:mm');
    },
    getSyncStatusIcon(workingTime) {
      switch (workingTime.syncStatus) {
        case 'InAufgabenpark':
          return '$aufgabenpark';
        case 'InTimepark':
          return '$timepark';
        case 'Error':
          return 'sync_problem'
      }
    },
    getSyncStatusColor(workingTime){
      return workingTime.syncStatus === 'Error' ? 'red' : 'blue';
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Start',
          value: 'start',
          filterable: false,
          sortable: false,
        },
        {
          text: 'End',
          value: 'end',
          filterable: false,
          sortable: false,
        },
        {
          text: 'Projekt',
          value: 'projekt',
          filterable: false,
          sortable: false,
        },
        {
          text: 'Machines',
          value: 'machines',
          filterable: false,
          sortable: false,
        },
        {
          text: 'Status',
          value: 'syncStatus',
          filterable: false,
          sortable: false,
        }
      ],
    };
  },
};
</script>
