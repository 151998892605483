import axios from './apiClient';

export default {
  getYearPlanningData(department) {
    const departmentPath = department ? `/${department}` : '';
    return axios.get(`api/YearPlanning/GetDepartmentData${departmentPath}`);
  },
  getYearPlanningEpicAssigmentData(department, weekStart, weekEnd, year) {
    const departmentPath = department ? `/${department}` : '';
    return axios.get(`api/YearPlanning/AssignedEpics/Department${departmentPath}`,
      {
        params: {
          weekStart,
          weekEnd,
          year,
        },
      },
    );
  },
  getYearPlanningStandbyData(weekStart, weekEnd, year) {
    return axios.get('api/YearPlanning/Standbys',
      {
        params: {
          weekStart,
          weekEnd,
          year,
        },
      },
    );
  },
  createYearPlanningEpicTask(task) {
    return axios.post('api/YearPlanning/CreateYearPlanningEpicTask/', task);
  },
  editYearPlanningEpicTask(task) {
    return axios.put(`api/YearPlanning/DepartmentEpics/${task.department}/${task.epicId}`, task);
  },
 deleteYearPlanningEpicTask(epicId, department) {
    return axios.delete(`api/YearPlanning/DepartmentEpics/${department}/${epicId}`);
  },
 deleteUploadedDocumentForYearPlanningTask(department, epicId, documentId) {
    return axios.delete(`api/YearPlanning/DepartmentEpics/${department}/${epicId}/Documents/${documentId}`);
  },
  deletePhotoForYearPlanningTask(department, epicId, externalId) {
  return axios.delete(`api/YearPlanning/DepartmentEpics/${department}/${epicId}/Photos/${externalId}`);
},
 processYearPlanningTasksForCurrentWeek() {
    return axios.post('api/YearPlanning/ProcessTasksForCurrentWeek/');
  },
  editUserAssignedEpicTask(assignmentId, assignment) {
    return axios.put(`api/YearPlanning/AssignedEpics/${assignmentId}`, assignment);
  },
  deleteUploadedDocumentForUserAssignedYearPlanningTask(assignmentId, documentId) {
    return axios.delete(`api/YearPlanning/AssignedEpics/${assignmentId}/Documents/${documentId}`);
  },
  deletePhotoForUserAssignedYearPlanningTask(assignmentId, externalId) {
    return axios.delete(`api/YearPlanning/AssignedEpics/${assignmentId}/Photos/${externalId}`);
  },
  assignYearPlanningEpicTaskToUser(assignment) {
    return axios.post(`api/YearPlanning/DepartmentEpics/${assignment.department}/${assignment.epicId}`, assignment);
  },
  reassignMultipleUserEpicTasks(reassignment) {
    return axios.put('api/YearPlanning/AssignedEpics/ReassignMultiple', reassignment);
  },
  unassignMultipleUserYearPlanningAssignments(unassignments) {
    return axios.put('api/YearPlanning/AssignedEpics/UnassignMultiple', unassignments);
  },
  assignUserToStandby(standby, assignment) {
    return axios.post(`api/YearPlanning/Standby/${standby}/Assign`, assignment);
  },
  reassignStandby(reasignment) {
    return axios.post('api/YearPlanning/Standby/ReassignMultiple', reasignment);
  },
  unassignMultipleStandbys(unassignments) {
    return axios.put('api/YearPlanning/Standby/UnassignMultiple', unassignments);
  },
};
