<template>
  <div>
    <v-select
      :items="products"
      v-model="selectedProduct"
      item-text="name"
      item-value="id"
      placeholder="Produkt"
      hint="Produkt"
    />
    <v-select
      :items="orders"
      v-model="selectedOrder"
      item-text="name"
      item-value="id"
      placeholder="Auftrag"
      hint="Auftrag"
    />
    <v-select
      :items="activities"
      v-model="selectedActivity"
      item-text="name"
      item-value="id"
      placeholder="Tätigkeit"
      hint="Tätigkeit"
      required
      :rules="projectRules"
    />
  </div>
</template>

<script>

export default {
  name: 'ProjectSelect',
  props: {
    value: Object
  },
  computed: {
    products() {
      return this.$store.state.timeparkProducts.filter(x => x.isActive);
    },
    orders() {
      if (!this.selectedProduct) {
        return [];
      }

      const selectedProduct = this.products.find(x => x.id === this.selectedProduct);

      if (selectedProduct) {
        return selectedProduct.orders.filter(x => x.isActive);
      }

      return [];
    },
    activities() {
      if (!this.selectedProduct || !this.selectedOrder) {
        return [];
      }

      const selectedOrder = this.orders.find(x => x.id === this.selectedOrder);
      if (selectedOrder) {
        return selectedOrder.activities.filter(x => x.isActive);
      }

      return [];
    },
    selectedProduct: {
      get() {
        return this.value?.product;
      },
      set(value) {
        this.$emit(
          'input',
          {
            product: value,
            order: null,
            activity: null,
          },
        );
      },
    },
    selectedOrder: {
      get() {
        return this.value?.order;
      },
      set(value) {
        this.$emit(
          'input',
          {
            product: this.selectedProduct,
            order: value,
            activity: null,
          },
        );
      },
    },
    selectedActivity: {
      get() {
        return this.value?.activity;
      },
      set(value) {
        this.$emit(
          'input',
          {
            product: this.selectedProduct,
            order: this.selectedOrder,
            activity: value,
          },
        );
      },
    }
  },
  data() {
    return {
      projectRules: [
        v => !!v || 'Bitte wählen Sie ein Projekt, Auftrag und Tätigkeit aus',
      ],
    };
  },
};
</script>
