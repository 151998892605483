<template>
  <v-container fluid>
      <v-toolbar>
        <h2 class="headline ml-5"> Meine Aufgaben</h2>
      </v-toolbar>
      <v-row class="mb-4">
        <v-col>
          <v-data-table
            :headers="headers"
            :items="tasks"
            :loading="loading"
            hide-default-footer
            item-key="taskId"
          >
            <template v-slot:header="props">
              <tr>
                <th
                  v-for="header in props.headers"
                  :key="header.text"
                  class="column"
                  >
                    {{ header.text }}
                </th>
              </tr>
            </template>
            <template v-slot:item="{ item }">
              <tr @click="selectItemRow(item)">
                <td class="text-xs-center">
                  <v-icon>
                    {{ getStatusIcon(item.status) }}
                  </v-icon>
                </td>
                <td class="text-xs-center">{{ item.name }}</td>
                <td class="text-xs-center">{{ formatDate(item.createdAt) }}</td>
                <td class="text-xs-center">
                  {{ formatDateWithTime(item.plannedStart) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-toolbar>
        <h2 class="headline ml-5"> Aufgabepool</h2>
      </v-toolbar>
      <v-row class="mb-4">
        <v-col>
          <v-data-table
            :headers="headers"
            :items="pool"
            :loading="loading"
            hide-default-footer
            item-key="taskId"
          >
            <template v-slot:header="props">
              <tr>
                <th
                  v-for="header in props.headers"
                  :key="header.text"
                  class="column"
                  >
                    {{ header.text }}
                </th>
              </tr>
            </template>
            <template v-slot:item="{ item }">
              <tr @click="selectItemRow(item)">
                <td class="text-xs-center">
                  <v-icon>
                    {{ getStatusIcon(item.status) }}
                  </v-icon>
                </td>
                <td class="text-xs-center">{{ item.name }}</td>
                <td class="text-xs-center">{{ formatDate(item.createdAt) }}</td>
                <td class="text-xs-center">
                  {{ formatDateWithTime(item.plannedStart) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-toolbar>
        <h2 class="headline ml-5"> Daueraufgaben</h2>
      </v-toolbar>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="activities"
            :loading="loading"
            hide-default-footer
            item-key="taskId"
          >
            <template v-slot:header="props">
              <tr>
                <th
                  v-for="header in props.headers"
                  :key="header.text"
                  class="column"
                  >
                    {{ header.text }}
                </th>
              </tr>
            </template>
            <template v-slot:item="{ item }">
              <tr @click="selectItemRow(item)">
                <td class="text-xs-center">
                  <v-icon>
                    {{ getStatusIcon(item.status) }}
                  </v-icon>
                </td>
                <td class="text-xs-center">{{ item.name }}</td>
                <td class="text-xs-center">{{ formatDate(item.createdAt) }}</td>
                <td class="text-xs-center">
                  {{ formatDateWithTime(item.plannedStart) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-dialog v-model="showEditTaskModal" max-width="1200px" scrollable>
        <task-details
          v-if="showEditTaskModal"
          :url="selectedTask._links.details.href"
          @cancel="closeEditTaskModal"
          @update="userDataChanged"
        />
      </v-dialog>
    </v-container>
</template>

<script>

import moment from 'moment';
import aufgabenparkApi from '../aufgabenparkApi';
import util from '../util';
import TaskDetails from "@/components/aufgaben/TaskDetails";

export default {
  name: 'CurrentUserTasks',
  components: {
    TaskDetails,
  },
  mounted() {
    this.reloadData();
  },
  computed: {
    tasks() {
      return this.data ? this.data.aufgaben : [];
    },
    pool() {
      return this.data ? this.data.aufgabenPool : [];
    },
    activities() {
      return this.data ? this.data.daueraufgaben : [];
    },
    showEditTaskModal: {
      get() {
        return !!this.selectedTask;
      },
      set(value) {
        if (!value) {
          this.selectedTask = null;
        }
      },
    },
  },
  methods: {
    getStatusIcon(status) {
      return util.getStatusIcon(status);
    },
    formatDate(date) {
      if (!date) {
        return '';
      }

      return moment(date).format('MMMM Do YYYY');
    },
    formatDateWithTime(date) {
      if (!date) {
        return '';
      }

      return moment(date).format('MMMM Do YYYY HH:mm');
    },
    async userDataChanged() {
      await this.reloadData();
      this.closeEditTaskModal();
    },
    async reloadData() {
      this.loading = true;
      const { data } = await aufgabenparkApi.loadMeineAufgaben();
      this.data = data;
      this.loading = false;
    },
    selectItemRow(task) {
      this.selectedTask = task;
    },
    closeEditTaskModal() {
      this.selectedTask = null;
    },
  },
  data() {
    return {
      data: null,
      selectedTask: null,
      loading: false,
      headers: [
        {
          text: 'Status',
          value: 'status',
          sortable: false,
          filterable: false,
          width: '10%',
        },
        {
          text: 'Name',
          value: 'name',
          filterable: false,
          sortable: false,
        },
        {
          text: 'Datum',
          value: 'createdAt',
          filterable: false,
        },
        {
          text: 'Fälligkeitsdatum',
          value: 'plannedStart',
          filterable: false,
        },
      ],
    };
  },
};
</script>
