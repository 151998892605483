<template>
  <div class="department-task-preview" :style="departmentStyle" v-on:click="select()">
    <div class="department-task-headline">
      <v-icon>style</v-icon>
      {{epic.name}}
      <div class="task-details">Aufgaben: {{epic.completedTaskCount}} / {{epic.taskCount}}</div>
    </div>
  </div>
</template>

<script>

import ColorHash from 'color-hash';
const colorHash = new ColorHash();

export default {
  name: 'EpicPreview',
  props: ['epic'],
  methods: {
    select() {
      this.$emit('select', this.epic);
    },
  },
  computed: {
    departmentStyle() {
      return `border-left: 10px solid ${colorHash.hex(this.epic.department)}`;
    },
  },
};
</script>

<style>
  .department-task-preview {
    width: 100%;
    border: 1px solid #e1e1e1;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  .department-task-headline {
    font-size: 14px;
  }
  .task-details {
    float: right;
    padding-right: 5px;
  }
</style>
